import React, {Component} from 'react';
import { Input, Tooltip } from 'antd';

import './TextInput.scss';

class TextInput extends Component {
  handleChange = (e) => {
    let {value} = e.target;

    if (typeof this.props.onTextChange === 'function') {
      this.props.onTextChange(value, this.props.name);
    }
  }

  render() {
    let {
      value,
      fieldLabel,
      name,
      placeholder,
      type,
      className,
      noLabel,
      size,
    } = this.props;

    const finalProps = {};
    const compProps = {};

    if (placeholder) {
      finalProps.placeholder = placeholder;
    }

    /*if (value) {
      finalProps.value = value;
    }*/

    if (name) {
      finalProps.name = name;
    }

    if (type) {
      finalProps.type = type;
    }
    else {
      finalProps.type = 'text';
    }

    let classNames = ['input-field'];
    if (className) {
      classNames.push(className)
    }

    if (size) {
      classNames.push('input-size-small')
    }
    if (noLabel) {
      classNames.push('input-no-label')
    }

    return (
          <div className={classNames.join(' ')}>
            {!noLabel ? <div className="field-label">{fieldLabel || name}</div> : null}
            <input
                {...finalProps}
                value={value}
                onChange={this.handleChange}/>
          </div>
    );
  }
}

export default TextInput;