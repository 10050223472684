import {
  USER_LOGOUT,
  MOBILE_MENU_OPENER,
  MOBILE_USER_POINTS_OPENER,
  GET_SECTIONS,
  GET_PROFILE,
  GET_PROFILE_PHOTO,
  GET_PROFILE_VIEWED,
  GET_PROFILE_VIEWED_PHOTO,
} from './types';
import {
  getSections,
  getProfile,
  getProfilePhoto,
  getAuthProfile,
  getAuthProfilePhoto,
} from '../services/apiRequests';

/*
 export function getSectionsAction(sections){
  return {
    type: GET_SECTIONS,
    payload: sections
  }
}*/

export function profileLogoutAction () {
  return {
    type: USER_LOGOUT
  }
}

export function getSectionsAction(){
  return async (dispatch) => {
    const resp = await getSections();
    if (resp.status === 200) {
      dispatch({
        type: GET_SECTIONS,
        payload: resp.data.sections
      })
    }
  }
}

export function getProfileAction(id){
  return async (dispatch) => {
    const resp = await getAuthProfile(id);
    if (resp.status === 200) {
      dispatch({
        type: GET_PROFILE,
        payload: resp.data
      })
    }
  }
}

export function getProfilePhotoAction(id){
  return async (dispatch) => {
    const resp = await getAuthProfilePhoto(id).catch((resp)=>{
      dispatch({
        type: GET_PROFILE_PHOTO,
        payload: null
      })

      return resp;
    });

    if (resp.status === 200) {
      const base64 = btoa(
          new Uint8Array(resp.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
          ),
      );
      dispatch({
        type: GET_PROFILE_PHOTO,
        payload: 'data:;base64,' + base64
      })
    } else {
      dispatch({
        type: GET_PROFILE_PHOTO,
        payload: null
      })
    }
  }
}

export function getViewedProfileAction(id){
  return async (dispatch) => {
    const resp = await getProfile(id);
    if (resp.status === 200) {
      dispatch({
        type: GET_PROFILE_VIEWED,
        payload: resp.data
      })
    }
  }
}

export function getViewedProfilePhotoAction(id){
  return async (dispatch) => {
    const resp = await getProfilePhoto(id);
    if (resp.status === 200) {
      const base64 = btoa(
          new Uint8Array(resp.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
          ),
      );

      dispatch({
        type: GET_PROFILE_VIEWED_PHOTO,
        payload: 'data:;base64,' + base64
      })
    }
  }
}

export function toggleMobileMenuAction(){
  return {
    type: MOBILE_MENU_OPENER
  }
}

export function toggleMobileUserPointsAction(){
  return {
    type: MOBILE_USER_POINTS_OPENER
  }
}