import CryptoJS from 'crypto-js';

import {createOauthToken, revokeOauthToken} from './apiRequests';
import {CRYPTO_SALT} from '../modules/constants';

/*If I change salt tokens was broken and it will
be 401 error, after that will be auto-logout*/
const salt = CRYPTO_SALT;

const encryptData = (data, salt) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
}

const decryptData = (ciphertext, salt) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  catch(err){
    return null;
  }
}

export const authService = {
  login: (respData, cb) => {
    authService.setUserToken(respData.access_token);
    authService.setUserTimestamp(Date.now());
    localStorage.setItem('loggedIn', true);
    if (typeof cb === 'function') {
      cb()
    }
  },

  logout: (cb) => {
    if (localStorage.getItem('loggedIn')) {
      localStorage.removeItem('loggedIn');
    }

    /*uat = User Access Type or Role*/
    if (localStorage.getItem('uat')) {
      localStorage.removeItem('uat')
    }

    if (localStorage.getItem('t')) {
      const token = authService.getUserToken();

      revokeOauthToken({
        'token_type_hint': 'access_token',
        'token': token,
      }).then((resp)=>{

      }).finally((resp) => {
        if (typeof cb === 'function') {
          cb(resp);
        }
        localStorage.removeItem('t');
      })
    } else {
      if (typeof cb === 'function') {
        cb();
      }
    }
  },

  isAuthenticated: () => {
    if (!localStorage.getItem('loggedIn')) {
      return false;
    } else {
      return true;
    }
  },

  setUserToken: (t) => {
    authService.setCryptoStorageItem('t', t);

    return t;
  },

  getUserToken: () => {
    let t = null;
    if (localStorage.getItem('t')) {
      t = authService.getCryptoStorageItem('t');
    }

    return t;
  },

  setUserTimestamp: (timestampOfLogin = Date.now()) => {
    localStorage.setItem('ts', timestampOfLogin);

    return timestampOfLogin;
  },

  getUserTimestamp: () => {
    let timestampOfLogin = '';
    if (localStorage.getItem('t')) {
      timestampOfLogin = localStorage.getItem('ts');
    }

    return timestampOfLogin;
  },

  getUserRole: () => {
    let uat = 'student';

    if (localStorage.getItem('uat')) {
      uat = authService.getCryptoStorageItem('uat');
    }

    return uat;
  },

  // this method exists because user role is different format from response
  // sso oauth users has user object with role like {role: student/manager} from response
  // init test users like test.employee@tpu.ru - have only scope from response
  detectAndSetUserRole: (userData) => {
    let uat = 'student';

    //manager scope: "viewStudentsProfiles createStudentProfile viewStudentProfile updateStudentProfile deleteStudentProfile changeStudentProfileVisibility"
    //student scope: "createStudentProfile viewOwnStudentProfile updateOwnStudentProfile deleteOwnStudentProfile changeOwnStudentProfileVisibility"
    if (!userData['user'] && userData['scope']) {
      if (userData['scope'].indexOf('viewStudentProfile') !== -1) {
        uat = 'manager';
      }
    }

    /*'employee' - it's name of manager role in tpu*/
    if (userData['user'] && userData['user']['role'] &&
        userData['user']['role'] === 'employee') {
      uat = 'manager';
    }

    authService.setUserRole(uat);
  },

  setUserRole: (uat) => {
    return authService.setCryptoStorageItem('uat', uat);
  },

  getCryptoStorageItem: (name) => {
    return decryptData(localStorage.getItem(name), salt)
  },

  setCryptoStorageItem: (name, value) => {
    return localStorage.setItem(name, encryptData(value, salt));
  },
}

