import React from 'react';
import Chart from 'chart.js';

Chart.defaults.global.defaultFontFamily = 'Rubik, Helvetica, Arial, sans-serif';
Chart.defaults.global.elements.point.radius = 2;
Chart.defaults.global.elements.point.borderWidth = 4;
Chart.defaults.global.elements.point.hoverRadius = 4;
Chart.defaults.global.elements.point.hoverBorderWidth = 6;

import './LineGraph.scss';

class LineGraph extends React.Component {
  constructor(props) {
    super(props);

    this.lineGraphRef = React.createRef();

    this.state = {
      chartInstance: null,
      optionsDef: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            padding: 10
          },
        },

        scales: {
          yAxes: [
            {
              /*stacked: true,*/
              ticks: {
                labelOffset: 5,
              },
            }],
          xAxes: [
            {
              ticks: {
              },
            },
          ],
        },
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (this.chartInstance) {
      this.chartInstance.data = this.props.data;
      this.chartInstance.options = {
        ...this.state.optionsDef,
        ...this.props.options
      };
      this.chartInstance.update();
    }

    /*if ((!Object.is(this.props.data, prevProps.data))) {
      console.log('prevProps', prevProps);
    }*/
  }

  componentDidMount() {
    const chartRef = this.lineGraphRef.current.getContext('2d');
    const {data, options} = this.props;
    const {optionsDef} = this.state;

    this.chartInstance = new Chart(chartRef, {
      type: 'line',
      data: data || {},
      /*width: width || {},
       height: height || {},*/
      options: {
        ...optionsDef,
        options
      },
    });
  }

  render() {
    const {id} = this.props;
    return (
        <div className="line-graph-wrapper">
          <canvas
              height="200"
              id={id}
              ref={this.lineGraphRef}
          />
        </div>
    );
  }
}

export default LineGraph;
