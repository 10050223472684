import React, {Component} from 'react';
import {
  Switch,
} from 'react-router-dom';
import {connect} from 'react-redux';

import {
  getViewedProfileAction,
  getViewedProfilePhotoAction,
} from '../../redux/actions';
import {
  getProfileRatingSection,
  getProfileRatingAll,
} from '../../services/apiRequests';
import {authService} from '../../services/auth';
import {getQueryParameter} from '../../modules/helpers';

import RouteWithSubRoutes
  from '../../components/RouteWithSubRoutes/RouteWithSubRoutes';

class ManagerViewProfileStudentPageWrapper extends Component {
  constructor(props) {
    super(props);

    let viewedProfileId = getQueryParameter('pid');

    if (authService.isAuthenticated() && viewedProfileId) {
      props.getViewedProfileAction(viewedProfileId);
      props.getViewedProfilePhotoAction(viewedProfileId);
    }

    this.state = {
      ratingPersonal: null,
      ratingScience0: null,
      ratingStudy1: null,
      ratingSport2: null,
      ratingCreative3: null,

      viewedProfileId: viewedProfileId || null
    };
  }

  componentDidMount() {
    const {viewedProfileId} = this.state;

    if (viewedProfileId) {
      getProfileRatingAll(viewedProfileId).then((resp) => {
        if (resp.status === 200) {
          this.setState({ratingPersonal: resp.data.place});
        }
      });

      getProfileRatingSection(viewedProfileId, 0).then((resp) => {
        if (resp.status === 200) {
          this.setState({ratingScience0: resp.data.place});
        }
      });

      getProfileRatingSection(viewedProfileId, 1).then((resp) => {
        if (resp.status === 200) {
          this.setState({ratingStudy1: resp.data.place});
        }
      });

      getProfileRatingSection(viewedProfileId, 2).then((resp) => {
        if (resp.status === 200) {
          this.setState({ratingSport2: resp.data.place});
        }
      });

      getProfileRatingSection(viewedProfileId, 3).then((resp) => {
        if (resp.status === 200) {
          this.setState({ratingCreative3: resp.data.place});
        }
      });
    }
  }

  render() {
    const {routes, profileViewed, profileViewedPhotoBase64} = this.props;
    const {ratingPersonal, ratingScience0, ratingStudy1, ratingSport2, ratingCreative3} = this.state;

    return (
        <React.Fragment>
        <Switch>
          {routes.map((route, i) => (
              <RouteWithSubRoutes
                  profile={profileViewed}
                  profilePhotoBase64={profileViewedPhotoBase64}
                  viewType="manager"
                  ratings={{
                    ratingPersonal,
                    ratingScience0,
                    ratingStudy1,
                    ratingSport2,
                    ratingCreative3
                  }}
                  key={i}
                  {...route} />
          ))}
        </Switch>
        </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    profileViewed: state.profileViewed.profileViewed,
    profileViewedPhotoBase64: state.profileViewedPhotoBase64.profileViewedPhotoBase64
  };
}

const mapDispatchToProps = {
  getViewedProfileAction: getViewedProfileAction,
  getViewedProfilePhotoAction: getViewedProfilePhotoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerViewProfileStudentPageWrapper);
