import React from 'react';

const PageHeadline = (props) => {
  return (
      <div className="page-header">
        <h4 className="headline-4">{props.children}</h4>
      </div>
  );
};

export default PageHeadline;
