import React, {Component} from 'react';

import './Tags.scss';

class Tags extends Component {
  state = {
    selectedOption: this.props.defaultValue ? this.props.defaultValue : null
  };

  handleClickTag = (e) => {
    let selectedTagVal = e.target.value;
    let tagName = e.target.name;
    let oldSelectedVal = this.state[tagName];

    if (typeof this.props.onTagChange === 'function') {
      this.props.onTagChange(selectedTagVal, tagName);
    }

    this.setState({
      selectedOption: selectedTagVal,
    });
  };

  render() {
    let {items, groupName, defaultValue} = this.props;
    let {state} = this;

    let finalInputProps = {};

    if (groupName) {
      finalInputProps.name = groupName;
    }

    let rows = [];
    if (items && items.length > 0) {
      rows = items.map((item, key) => {
        return (
            <label className={`tag ${state['selectedOption'] === item.value  ? 'active' :''}`}
                   key={key}>
              <input type="radio"
                     onChange={this.handleClickTag}
                     value={item.value}
                     checked={state['selectedOption'] === item.value}
                     {...finalInputProps}/>
              {item.label}
            </label>);
      });
    }

    return (
        <div className="tags">
          {rows}
        </div>
    );
  }
}

export default Tags;
