import StartPage from '../containers/pages/StartPage';

/*Manager pages*/
import ManagerPersonalPage from '../containers/pages/ManagerPersonalPage';
import ManagerFiltersPage from '../containers/pages/ManagerFiltersPage';
import ManagerRatingPage from '../containers/pages/ManagerRatingPage';
import ManagerDashboardPage from '../containers/pages/ManagerDashboardPage';
import ManagerFiltersCreatePage from '../containers/pages/ManagerFiltersCreatePage';
import ManagerFiltersEditPage from '../containers/pages/ManagerFiltersEditPage';

/*Personal pages*/
import StudentPersonalPage from '../containers/pages/StudentPersonalPage';
import StudentRecordBookPage from '../containers/pages/StudentRecordBookPage';
import StudentProjectsPage from '../containers/pages/StudentProjectsPage';

/*Activities top pages*/
import StudentStudyPage from '../containers/pages/StudentStudyPage';
import StudentWorkPage from '../containers/pages/StudentWorkPage';
import StudentSciencePage from '../containers/pages/StudentSciencePage';
import StudentSocialPage from '../containers/pages/StudentSocialPage';
import StudentCreativePage from '../containers/pages/StudentCreativePage';
import StudentSportPage from '../containers/pages/StudentSportPage';

/*Activities subpages pages*/
import StudentWorkHistoryPage from '../containers/pages/StudentWorkHistoryPage';
import StudentStudyEducationPage
  from '../containers/pages/StudentStudyEducationPage';
import StudentSocialSelfGovernmentPage
  from '../containers/pages/StudentSocialSelfGovernmentPage';
import StudentActivityCommonPage
  from '../containers/pages/StudentActivityCommonPage';
import StudentPageWrapper
  from '../containers/pages/StudentPageWrapper';
import ManagerViewProfileStudentPageWrapper
  from '../containers/pages/ManagerViewProfileStudentPageWrapper';
import GuestViewProfileStudentPageWrapper
  from '../containers/pages/GuestViewProfileStudentPageWrapper';

import StudentQrCodePage from '../containers/pages/StudentQrCodePage';
import LoginPage from '../containers/pages/LoginPage';
import SsoLogoutPage from '../containers/pages/SsoLogoutPage';
import SsoSuccessPage from '../containers/pages/SsoSuccessPage';
import NotFound404Page from '../containers/pages/NotFound404Page';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';

import {pageTitles} from './constants';

const routes = [
  {
    path: '/',
    exact: true,
    component: StartPage,
    privateRoute: false,
    title: pageTitles.startPage,
  },
  {
    path: '/login',
    exact: true,
    component: LoginPage,
    privateRoute: false,
    title: pageTitles.startPage,
  },
  {
    path: '/sso/logout',
    exact: true,
    component: SsoLogoutPage,
    privateRoute: false,
    title: pageTitles.startPage,
  },
  {
    path: '/sso/success',
    exact: true,
    component: SsoSuccessPage,
    privateRoute: false,
    title: pageTitles.startPage,
  },
  /*{
    path: '/logout',
    exact: true,
    component: LoginPage,
    privateRoute: false,
    title: pageTitles.startPage,
  },*/
  {
    path: '/manager/personal',
    exact: true,
    component: ManagerPersonalPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/manager/rating',
    exact: true,
    component: ManagerRatingPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/manager/view-profile',
    exact: false,
    component: ManagerViewProfileStudentPageWrapper,
    privateRoute: true,
    title: pageTitles.startPage,
    routes: [
      {
        path: '/manager/view-profile/personal',
        exact: true,
        component: StudentPersonalPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/study',
        exact: true,
        component: StudentStudyPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/study/education',
        exact: true,
        component: StudentStudyEducationPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/projects',
        exact: true,
        component: StudentProjectsPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: ['/manager/view-profile/grade-book', '/manager/view-profile/record-book'],
        exact: true,
        component: StudentRecordBookPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/science',
        exact: true,
        component: StudentSciencePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/creative',
        exact: true,
        component: StudentCreativePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/sport',
        exact: true,
        component: StudentSportPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/social',
        exact: true,
        component: StudentSocialPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/:sectionName/:subsectionId',
        exact: true,
        component: StudentActivityCommonPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/manager/view-profile/qr',
        exact: true,
        component: StudentQrCodePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
    ],
  },
  /*{
    path: '/manager/dashboard',
    exact: true,
    component: ManagerDashboardPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },*/
  {
    path: '/manager/filters',
    exact: true,
    component: ManagerFiltersPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/manager/filters/create',
    exact: true,
    component: ManagerFiltersCreatePage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/manager/filters/:filterId/edit',
    exact: true,
    component: ManagerFiltersEditPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/student',
    exact: false,
    component: GuestViewProfileStudentPageWrapper,
    privateRoute: false,
    title: pageTitles.startPage,
    routes: [
      {
        path: '/student/personal',
        exact: true,
        component: StudentPersonalPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/study',
        exact: true,
        component: StudentStudyPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/study/education',
        exact: true,
        component: StudentStudyEducationPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/projects',
        exact: true,
        component: StudentProjectsPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: ['/student/grade-book', '/student/record-book'],
        exact: true,
        component: StudentRecordBookPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/science',
        exact: true,
        component: StudentSciencePage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/creative',
        exact: true,
        component: StudentCreativePage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/sport',
        exact: true,
        component: StudentSportPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/social',
        exact: true,
        component: StudentSocialPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/:sectionName/:subsectionId',
        exact: true,
        component: StudentActivityCommonPage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
      {
        path: '/student/qr',
        exact: true,
        component: StudentQrCodePage,
        privateRoute: false,
        title: pageTitles.startPage,
      },
    ],
  },
  {
    path: '/my',
    exact: false,
    component: StudentPageWrapper,
    privateRoute: true,
    title: pageTitles.startPage,
    routes: [
      {
        path: '/my/personal',
        exact: true,
        component: StudentPersonalPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/study',
        exact: true,
        component: StudentStudyPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/study/education',
        exact: true,
        component: StudentStudyEducationPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/projects',
        exact: true,
        component: StudentProjectsPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: ['/my/grade-book', '/my/record-book'],
        exact: true,
        component: StudentRecordBookPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/science',
        exact: true,
        component: StudentSciencePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/creative',
        exact: true,
        component: StudentCreativePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/sport',
        exact: true,
        component: StudentSportPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/social',
        exact: true,
        component: StudentSocialPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/:sectionName/:subsectionId',
        exact: true,
        component: StudentActivityCommonPage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
      {
        path: '/my/qr',
        exact: true,
        component: StudentQrCodePage,
        privateRoute: true,
        title: pageTitles.startPage,
      },
    ],
  },
  /*{
    path: '/my/work',
    exact: true,
    component: StudentWorkPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/my/work/work-history',
    exact: true,
    component: StudentWorkHistoryPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/my/social',
    exact: true,
    component: StudentSocialPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },
  {
    path: '/my/social/self-government',
    exact: true,
    component: StudentSocialSelfGovernmentPage,
    privateRoute: true,
    title: pageTitles.startPage,
  },*/
];

export default routes;
