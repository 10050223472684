import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';

import './LayoutMain.scss';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class LayoutMain extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {disableHeader, hideMobileMenu, hideTopMenu, transparentHeader, profileViewed, mobileMenu, viewType} = this.props;

    let isManagerView = viewType === 'manager';

    return (
        <Fragment>
          {disableHeader ?
              null :
              <Header transparentHeader={transparentHeader}
                      hideMobileMenu={hideMobileMenu}
                      hideTopMenu={hideTopMenu}/>}

          <main className={'main' + (mobileMenu.isMenuOpened ? ' is-menu-opened' : '') }>
            <div className="container">
              {isManagerView ? <div className="actions-manager-view">
                <a href="/manager"
                   className="manager-view-action manager-view-link">
                  Профиль менеджера
                </a>
                {profileViewed ?
                    <div className="manager-view-action manager-view-text">
                      {`${profileViewed.education.education_level.full_name} ${profileViewed.personal.first_name} ${profileViewed.personal.last_name}`}
                    </div>
                : <Skeleton.Input style={{ minWidth: 150 }} active/>}

              </div> : null}
              {this.props.children}
            </div>
          </main>
          <Footer/>
        </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileMenu: state.mobileMenu,
    profileViewed: state.profileViewed.profileViewed
  };
}

export default connect(mapStateToProps, null)(withRouter(LayoutMain));
