import CardCols, {CardCol} from '../Card/CardCols';
import {Skeleton} from 'antd';
import Card from '../Card/Card';

const buildFullNameStringView = (personal) => {
  let lastName = '';
  let firstName = '';
  let middleName = '';

  if (personal.last_name) {
    lastName = personal.last_name + ' ';
  }

  if (personal.first_name) {
    firstName = personal.first_name + ' ';
  }
  if (personal.middle_name && personal.middle_name.length > 1) {
    middleName = personal.middle_name + ' ';
  }

  return lastName + firstName + middleName;
};

const buildLocationStringView = (contacts) => {
  let country = '';
  let region = '';
  let city = '';
  let hasLocation = false;

  if (contacts.country) {
    country = contacts.country.name + ' ';
    hasLocation = true;
  }

  if (contacts.region) {
    region = contacts.region.name + ' ';
    hasLocation = true;
  }

  if (contacts.city) {
    city = contacts.city.name + ' ';
    hasLocation = true;
  }

  return hasLocation ? (country + region + city) : '-' ;
};

const buildPhoneLinkHtmlView = (contacts) => {
  return contacts.phones.length ?
      <a href={`tel:${contacts.phones[0].country_code}${contacts.phones[0].number}`}>
        {`+${contacts.phones[0].country_code}${contacts.phones[0].number}`}
      </ a>
      : '-'
};

const PersonalDataCard = (props) => {
    const {profile} = props;

    return (
        <Card hasHover={false}>
          <div className="card-inner-header">
            Контактные данные
          </div>
          {profile ?
              <CardCols>
                <CardCol>
                  <div className="card-read-field">
                    <div className="field-label">Ф.И.О.</div>
                    <div className="field-value">
                      {buildFullNameStringView(profile.personal)}
                    </div>
                  </div>
                  <div className="card-read-field">
                    <div className="field-label">Дата рождения</div>
                    <div className="field-value">
                      {profile.personal.birth_date ?
                          profile.personal.birth_date.replace(/-/g, '.') : '-'}
                    </div>
                  </div>
                  <div className="card-read-field">
                    <div className="field-label">Пол</div>
                    <div className="field-value">
                      {profile.personal.sex.full_name ?
                          profile.personal.sex.full_name : '-'}
                    </div>
                  </div>
                </CardCol>
                <CardCol>
                  <div className="card-read-field">
                    <div className="field-label">Местонахождение</div>
                    <div className="field-value">
                      {buildLocationStringView(profile.contacts)}
                    </div>
                  </div>
                  <div className="card-read-field">
                    <div className="field-label">Гражданство</div>
                    <div className="field-value">
                      {profile.personal.citizenship.length > 0 ?
                          profile.personal.citizenship.map((item) => (
                              item.name + ' '
                          )) : '-'
                      }
                    </div>
                  </div>
                  <div className="card-read-field">
                    <div className="field-label">E-mail</div>
                    <div className="field-value">
                      {profile.contacts.email ?
                          <a href={`mailto:${profile.contacts.email}`}>{profile.contacts.email}</a>
                          : '-'
                      }
                    </div>
                  </div>

                  <div className="card-read-field">
                    <div className="field-label">Телефон</div>
                    <div className="field-value">
                      {buildPhoneLinkHtmlView(profile.contacts)}
                    </div>
                  </div>
                  {/*<div className="card-read-field">
                   <div className="field-label">Соцсети</div>
                   <div className="field-actions">
                   <a href="https://fb.com" className="field-btn btn-square size-md">
                   <GetIcon name="icon-logo-fb"/>
                   </a>
                   <a href="https://vk.com" className="field-btn btn-square size-md">
                   <GetIcon name="icon-logo-vk"/>
                   </a>
                   </div>
                   </div>*/}
                </CardCol>
              </CardCols>
              : <Skeleton active/> }

          {/*<CardInnerHeader>
           Дополнительно
           </CardInnerHeader>
           <CardInnerText>
           {faker.lorem.words(50)}
           </CardInnerText>
           <CardInnerHeader>
           Профиль компетенций
           </CardInnerHeader>
           <CardInnerText>
           <ul className="card-unordered-list">
           <li className="unordered-item">{faker.lorem.words(3)}</li>
           <li className="unordered-item">{faker.lorem.words(3)}</li>
           </ul>
           </CardInnerText>*/}
        </Card>
    );
}

PersonalDataCard.defaultProps = {
  profile: null,
}

export default PersonalDataCard;