import React, {Component} from 'react';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';
import QRCodeBox from '../../components/QRCodeBox/QRCodeBox';
import Card from '../../components/Card/Card';

class StudentQrCodePage extends Component {
  constructor() {
    super();
    this.state = {};

    this.qrCanvasRef = React.createRef();
  }

  handleDownloadQrClick = (e) => {
    e.preventDefault();
    const qrCanvasRef = this.qrCanvasRef;
    const canvas = qrCanvasRef.current.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').
        replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'student-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  render() {
    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent>
              {profile ?
                  <div className="qr-container">
                    <div className="share-qr" ref={this.qrCanvasRef}>
                      <QRCodeBox size={266} text={'https://portfolio.tpu.ru/manager/view-profile/personal?pid=' +
                      profile.id}/>
                    </div>
                    <div className="share-actions">
                      <a className="action-share btn btn-green btn-sm"
                         href={'https://vk.com/share.php?url=https://portfolio.tpu.ru/manager/view-profile/personal?pid=' +
                         profile.id}
                         target="_blank">Поделиться</a>
                      <button onClick={this.handleDownloadQrClick} className="btn-link action-save">
                        Сохранить
                      </button>
                    </div>
                  </div>
                  : null}
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}

export default StudentQrCodePage;