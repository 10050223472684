import React, {Component} from 'react';
import {NavLink, Link, withRouter} from 'react-router-dom';

import './LeftPanel.scss';

class LeftPanel extends Component {
  state = {};

  componentDidMount() {
  }

  render() {
    return (
        <div className="left-panel">
          {this.props.children}
        </div>
    );
  }
}

export default withRouter(LeftPanel);