import React, {Component, Fragment} from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {connect} from 'react-redux';

import './App.scss';

import LoginPage from '../containers/pages/LoginPage';
import NotFound404Page from '../containers/pages/NotFound404Page';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import RouteWithSubRoutes
  from '../components/RouteWithSubRoutes/RouteWithSubRoutes';
import routes from '../modules/routes';
import {refreshPage} from '../modules/helpers';
import {searchProfile} from '../services/apiRequests';
import {authService} from '../services/auth';
import {
  getSectionsAction,
  getProfileAction,
  getProfilePhotoAction,
  profileLogoutAction,
} from '../redux/actions';
import alertNotice from '../components/alertNotice/alertNotice';


class App extends Component {
  constructor(props) {
    super(props);

    props.getSectionsAction();

    if (authService.isAuthenticated()) {
      /*FIX IT hack for checking Unauthorized error*/
      props.getProfileAction().catch((err)=>{
        if (err.response) {
          alertNotice.error(err.response.status + ' '
              + (err.response.data && err.response.data.message ? err.response.data.message : (err.response.statusText || err.message)));
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        }
        else if (err.request) {
          alertNotice.error(err.request);
          console.log(err.request);
        }
        else {
          alertNotice.error('Error' + ' ' + err.message);
          console.log('Error', err.message);
        }

        if (err.response.status === 401) {
          authService.logout();
          this.props.profileLogoutAction();
          refreshPage();
        }

        console.log(err.config);
      });
      props.getProfilePhotoAction();
    }

    this.state = {};
  }

  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/login"
                   render={(props) => <LoginPage {...props}/>}/>
            <Redirect exact from='/manager' to='/manager/personal'/>
            <Redirect exact from='/my' to='/my/personal'/>
            {routes.map(({
                           path,
                           exact,
                           privateRoute,
                           component: Component,
                           ...rest
                         }) => (
                privateRoute ?
                    <ProtectedRoute
                        {...rest}
                        key={path}
                        path={path}
                        exact={exact}
                        redirectTo="/login"
                        component={Component}
                    /> :
                    <Route key={path}
                           path={path}
                           exact={exact}
                           render={(props) =>
                               (<Component {...props} {...rest} />)
                           }
                    />
            ))}
            <Route component={NotFound404Page}/>
          </Switch>
        </BrowserRouter>
    );
  }
}

const mapDispatchToProps = {
  getSectionsAction: getSectionsAction,
  getProfileAction: getProfileAction,
  profileLogoutAction: profileLogoutAction,
  getProfilePhotoAction: getProfilePhotoAction,
};

export default connect(null, mapDispatchToProps)(App);