import React from 'react';
import {Popconfirm as PopconfirmAntd} from 'antd';

import GetIcon from '../GetIcon/GetIcon';

const Popconfirm = (props) => {
  return (
      <PopconfirmAntd placement="bottomRight"
                  icon={<GetIcon name="icon-info"/>}
                  okText="Да"
                  cancelText="Нет"
                  {...props}>
        {props.children}
      </PopconfirmAntd>
  );
};

export default Popconfirm;