import React, {Fragment} from 'react';
import {Skeleton} from 'antd';

import './Modal.scss';

import GetIcon from '../GetIcon/GetIcon';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false
    };
  }


  handleBackgroundClick = (e) => {
    e.stopPropagation();


    if (typeof this.props.onClose === 'function') {
      this.props.onClose(false);
    }

    this.setState({
      isModalOpened: false,
    });
  }

  handleCloseClick = (e) => {
    e.stopPropagation();


    if (typeof this.props.onClose === 'function') {
      this.props.onClose(false);
    }

    this.setState({
      isModalOpened: false,
    });
  }

  componentDidMount() {
     this.setState({
       isModalOpened: true
     })
  }

  render() {
    const {isModalOpened} = this.state;
    const {isOpened, header, className} = this.props;

    let openedState = isModalOpened;
    if (isOpened !== undefined) {
      openedState = isOpened;
    }

    const classes = ['modal-container'];

    if (openedState) {
      classes.push('opened');
    }

    if (className) {
      classes.push(className)
    }

    return (
        <div className={classes.join(' ')}>
          <div className="modal-clickable-background"
               onClick={this.handleBackgroundClick}/>

          <div className="modal">
            <div className="modal-top">
              <div className="modal-top-header">
                {header}
              </div>
              <div className="modal-top-close"
                   onClick={this.handleCloseClick}>
                <GetIcon name="icon-close"/>
              </div>
            </div>

            {this.props.children}
          </div>

        </div>
    );
  }
}


export default Modal;
