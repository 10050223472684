let appConfig;
try {
  appConfig = require('../config/client');
}
catch (e) {
  throw new Error(
      'Please define src/config/client.js file. Exception: ' + e.message);
}

if (appConfig.env === 'development') {
  console.log('Connected to: ' + appConfig.apiHostUrl);
}

export const API_HOST_URL = appConfig.apiHostUrl;

export const SITE_HOST_URL = appConfig.siteHostUrl;

export const IS_DEVELOPMENT = appConfig.env === 'development';

export const CRYPTO_SALT = appConfig.storageCryptoSalt;

const pageTitlePrefix = 'Профиль студента ТПУ';
const pageTitleDelimiter = ' | ';
export const pageTitles = {
  startPage: pageTitlePrefix,
  notFound404Page: 'Ошибка 404: страница не найдена' + pageTitleDelimiter + pageTitlePrefix,
};

export const courses = [1,2,3,4,5,6];

export const termsRelated = {
  1: [1,2],
  2: [3,4],
  3: [5,6],
  4: [7,8],
  5: [9, 10],
  6: [11, 12],
};

export const terms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const coursesWithTerms = [
  '1 курс 1 семестр',
  '1 курс 2 семестр',
  '2 курс 3 семестр',
  '2 курс 4 семестр',
  '3 курс 5 семестр',
  '3 курс 6 семестр',
  '4 курс 7 семестр',
  '4 курс 8 семестр',
  '5 курс 9 семестр',
  '5 курс 10 семестр',
  '6 курс 11 семестр',
  '6 курс 12 семестр',
];

const sectionNames = [
  {
    id: 0,
    slug: 'science',
    name: 'Научная деятельность'
  },
  {
    id: 1,
    slug: 'study',
    name: 'Учебная деятельность'
  },
  {
    id: 2,
    slug: 'sport',
    name: 'Спортивная деятельность'
  },
  {
    id: 3,
    slug: 'creative',
    name: 'Культурная деятельность'
  },
  {
    id: 4,
    slug: 'social',
    name: 'Общественная деятельность'
  },
  {
    id: 5,
    slug: 'work',
    name: 'Трудовая и профессиональная деятельность'
  },
];

const scoreLiteralsEng = {
  A: 5,
  B: 4,
  C: 3,
  D: 2,
  F: 2,
}

const cardsSizesOrder = [
    'lg', 'md', 'sm', 'xl', 'md', 'lg', 'lg', 'md', 'xl', 'sm'
]

export const idToName = {
  getSectionById(sectionId) {
    if (sectionNames[sectionId]) {
      return sectionNames[sectionId]
    }

    return sectionNames[sectionNames.length-1];
  },

  getSectionIdBySlug(sectionSlug) {
    let section = sectionNames[sectionNames.length-1];
    sectionNames.map((item)=>{
      if (item.slug === sectionSlug) {
        section = item;
      }
    })

    return section;
  },

  get5GradeByLetter(letter){
    if (scoreLiteralsEng[letter]) {
      return scoreLiteralsEng[letter];
    }

    return scoreLiteralsEng['A'];
  },

  getCardSizeByIndex(index) {
    if (index <=9) {
      return cardsSizesOrder[index]
    } else {
      return cardsSizesOrder[index-9]
    }
  }
}