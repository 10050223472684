import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Checkbox} from 'antd';
const CheckboxGroup = Checkbox.Group;

import {
  updateFilter,
  getFilters,
  deleteFilter,
} from '../../services/apiRequests';

import GetIcon from '../../components/GetIcon/GetIcon';
import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import Popconfirm from '../../components/Popconfirm/Popconfirm';
import TextInput from '../../components/TextInput/TextInput';
import NumericInput from '../../components/NumericInput/NumericInput';
import LeftPanelManager
  from '../../components/LeftPanelManager/LeftPanelManager';
import RightPanelManager
  from '../../components/RightPanelManager/RightPanelManager';

class ManagerFiltersCreatePage extends Component {

  constructor(props) {
    super();
    const {match: {params}} = props;

    this.state = {
      filterName: '',
      showFilterError: false,

      selectedFilter: null,
      filters: null,
      filterId: parseInt(params.filterId, 10) ?? null,

      selectedCategoryId: null,

      checkedListAccomplishment: [],
      checkedStateAccomplishment: [],
      checkAll: false,
      indeterminateChecked: false,

      editedListAccomplishmentPoints: [],
      editedStateAccomplishmentPoints: [],
      editPointsActiveName: null,
    };
  }

  componentDidMount() {
    this.getFiltersEvent();
  }

  handleInputChange = (value, name) => {
    this.setState({
      [name]: value
    });
  };

  handlePointsInputChange = (value, name) => {
    let {editedListAccomplishmentPoints} = this.state;
    editedListAccomplishmentPoints[name] = value;

    this.setState({
      editedListAccomplishmentPoints
    });
  };

  handleSaveFilterClick = (e) => {
    const {filterId ,filterName, checkedListAccomplishment, editedListAccomplishmentPoints} = this.state;

    let expressions = checkedListAccomplishment.map((item, key) => {
      let splitName = item.split('_');
      return {
        'section_id': parseInt(splitName[0], 10),
        'subsection_id': parseInt(splitName[1], 10),
        'accomplishment_id': parseInt(splitName[2], 10),
        'points': parseFloat(editedListAccomplishmentPoints[item]) ?? null,
      };
    });

    if (filterName.length > 0) {
      updateFilter(filterId, {
        'id': filterId,
        'title': filterName,
        'expressions': expressions
      }).then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          this.setState({filterName: ''});
          this.props.history.push('/manager/filters')
        }
      })
    } else {
      this.setState({showError: true});
    }
  };

  handleGoBackActionClick = () => {
    this.setState({
      selectedCategoryId: null
    });
  }

  handleAddCategoryClick = (e) => {
    this.props.history.push({
      search: '?selectedCategoryId='+parseInt(e.target.name, 10)
    })

    this.setState({
      selectedCategoryId: parseInt(e.target.name, 10)
    });
  }

  handleEditPointsCategoryClick = (e) => {
    e.preventDefault();
    let editedName = e.currentTarget.dataset['name'];

    let {editedStateAccomplishmentPoints} = this.state;

    editedStateAccomplishmentPoints[editedName] = !editedStateAccomplishmentPoints[editedName];

    this.setState({
      editedStateAccomplishmentPoints,
      editPointsActiveName: editedStateAccomplishmentPoints[editedName] ? editedName : null,
    });
  }

  handleAccomplishmentCheckboxClick = (e) => {
    let clickedName = e.target.name || e.target.dataset['name'];

    let {checkedListAccomplishment, checkedStateAccomplishment} = this.state;

    if (checkedListAccomplishment.indexOf(clickedName) > -1) {
      checkedStateAccomplishment[clickedName] = false;
      //delete from array
      checkedListAccomplishment.splice(checkedListAccomplishment.indexOf(clickedName), 1);
    }
    else {
      checkedStateAccomplishment[clickedName] = true;
      checkedListAccomplishment.push(clickedName);
    }

    this.setState({
      checkedStateAccomplishment,
      checkedListAccomplishment,
    });
  };

  handleDeleteFilterClick = () => {
    deleteFilter(this.state.filterId).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.props.history.push('/manager/filters')
      }
    });
  };

  getFiltersEvent = () =>{
    return getFilters().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        const filters = resp.data;

        let selectedFilter = null;
        if (filters && filters.length) {
          filters.map((item, key) => {
            if (item.id === this.state.filterId) {
              selectedFilter = item;
            }
          })
        }

        let checkedStateAccomplishment = [];
        let checkedListAccomplishment = [];
        let editedListAccomplishmentPoints = [];

        if (selectedFilter && selectedFilter.expressions.length > 0) {
          selectedFilter.expressions.map((item, index) => {
            let expressionName = item.section_id + '_' + item.subsection_id + '_' +
                item.accomplishment_id;

            checkedStateAccomplishment[expressionName] = true;
            editedListAccomplishmentPoints[expressionName] = item.points;
            checkedListAccomplishment.push(expressionName);
          });
        }

        this.setState({
          filters,
          selectedFilter,
          checkedStateAccomplishment,
          editedListAccomplishmentPoints,
          checkedListAccomplishment,
          filterName: selectedFilter['title']
        });
      }

      return resp;
    })
  }

  render() {
    const {
      selectedCategoryId,
      checkedStateAccomplishment,
      checkedListAccomplishment,
      editedStateAccomplishmentPoints,
      editedListAccomplishmentPoints,
      editPointsActiveName,
    } = this.state;
    const {sections} = this.props;

    let sectionsItems = [];
    let subSectionsItemsById = [];
    let subSectionsSelectedCount = {};

    if (sections) {
      checkedListAccomplishment.map((item, key) => {
        let splitName = item.split('_');
        if (subSectionsSelectedCount[splitName[0]]) {
          subSectionsSelectedCount[splitName[0]] = subSectionsSelectedCount[splitName[0]] + 1;
        } else {
          subSectionsSelectedCount[splitName[0]] = 1;
        }
      });

      sectionsItems = sections.map((item, key) => {
        return <Card key={item.id}
                     hasHover={false}
                     isCollapsible={true}
                     header={item.name}
                     minHeight={false}>
          <div className="card-inner-text color-text-grey mb24">
            {`Выбрано: ${subSectionsSelectedCount[item.id] || 0}  категорий`}
          </div>
          <div className="card-inner-text color-text-grey">
            <button className="btn-link"
                    name={item.id}
                    onClick={this.handleAddCategoryClick}>+ Редактировать категории</button>
          </div>
        </Card>;
      });

      subSectionsItemsById = sections.map((itemSect, indexSect) => {
        return Object.keys(itemSect['subsections']).
            map((keySubsect, index) => {
              let subsect = itemSect['subsections'][keySubsect];
              let accomplishments = subsect['accomplishments'];

              let rows = Object.keys(accomplishments).map(
                  (keyAccompl, indexAccmopl) => {
                    let accompl = accomplishments[keyAccompl];
                    let checkboxName = itemSect.id + '_' + subsect.id + '_' +
                        accompl.id;

                    return <li key={accompl.id}
                               className={'unordered-item centered' + (editPointsActiveName === checkboxName ? ' excluded-from-disable' : '')}>
                      <div className="list-actions-left">
                        <Checkbox
                            checked={checkedStateAccomplishment[checkboxName]}
                            data-name={checkboxName}
                            data-sect={itemSect.id}
                            data-subsect={subsect.id}
                            data-accmopl={accompl.id}
                            data-points={accompl.points}
                            name={checkboxName}
                            onChange={this.handleAccomplishmentCheckboxClick}/>
                      </div>
                      <div className="list-content"
                           style={{cursor: 'pointer'}}
                           data-name={checkboxName}
                           data-sect={itemSect.id}
                           data-subsect={subsect.id}
                           data-accmopl={accompl.id}
                           data-points={accompl.points}
                           onClick={this.handleAccomplishmentCheckboxClick}>
                        {accompl.name}
                      </div>
                      <div className="list-actions-right">
                        <div className="list-actions-notice">
                          {
                            editedStateAccomplishmentPoints[checkboxName] ?
                                <NumericInput
                                    noLabel={true}
                                    size="small"
                                    name={checkboxName}
                                    min={1}
                                    max={1000}
                                    value={
                                      editedListAccomplishmentPoints[checkboxName] ?? accompl.points
                                    }
                                    onChange={this.handlePointsInputChange}/> :
                                <div className="notice-points">{editedListAccomplishmentPoints[checkboxName] ?? accompl.points} б.</div>
                          }
                        </div>
                        <div className="list-actions-elements">
                          <Link to="#"
                                onClick={this.handleEditPointsCategoryClick}
                                disabled={editPointsActiveName && editPointsActiveName !== checkboxName}
                                data-name={checkboxName}
                                className={'btn-circle size-md' + (editPointsActiveName && editPointsActiveName !== checkboxName ? ' no-background disabled' : '')}>
                            {!editedStateAccomplishmentPoints[checkboxName] ?
                                <GetIcon name="icon-pencil"/> :
                                <GetIcon name="icon-check"/>}
                          </Link>
                        </div>
                      </div>
                    </li>;
                  });

              return <Card size="auto"
                           isCollapsible={true}
                           disabled={!!editPointsActiveName}
                           hasHover={false}
                           key={subsect.id}
                           header={subsect.name}>
                {/*<Checkbox
                 indeterminate={indeterminate}
                 onChange={onCheckAllChange}
                 checked={checkAll}>
                 Выбрать все
                 </Checkbox>*/}
                {/*<CheckboxGroup>*/}
                <ul className={'card-unordered-list' + (!!editPointsActiveName ? ' disabled' : '')}>
                  {rows}
                </ul>
                {/*</CheckboxGroup>*/}
              </Card>;
            });
      });
    }

    return (
        <LayoutMain>
          <div className="page-content">
            <LeftPanelManager/>

            <RightPanelManager headerActions={
                  <Popconfirm title="Удалить фильтр?"
                              onConfirm={this.handleDeleteFilterClick}>
                    <button key="export"
                            title="Удалить фильтр"
                            className="edit-filter-delete btn-link btn-link-red">
                      <GetIcon name="icon-trash"/><span> Удалить правило фильтрации</span>
                    </button>
                  </Popconfirm>
                }
                goBackAction={selectedCategoryId !== null}
                goBackActionCb={this.handleGoBackActionClick}
                header="Редактирование фильтра">
              <Card hasHover={false}
                    minHeight={false}>
                <TextInput
                    fieldLabel="Название фильтра*"
                    name="filterName"
                    value={this.state.filterName}
                    onTextChange={this.handleInputChange}
                    placeholder="Введите название"/>
              </Card>

              {selectedCategoryId !== null ?
                  subSectionsItemsById[selectedCategoryId]:
                  sectionsItems
              }
              <div className="card-inner-bottom-actions">
                <button onClick={this.handleSaveFilterClick}
                        disabled={!!editPointsActiveName}
                        className={'btn btn-green btn-sm action-save' + (!!editPointsActiveName ? ' disabled' : '')}>
                  Сохранить
                </button>
                <Link to="/manager/filters"
                      className="btn-link btn-link-grey action-cancel">
                  Отмена
                </Link>
              </div>
            </RightPanelManager>
          </div>
        </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sections: state.sectionLeftPanel.sections,
    profile: state.profile.profile
  };
}

export default connect(mapStateToProps, null)(ManagerFiltersCreatePage);
