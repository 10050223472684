import {MOBILE_MENU_OPENER, MOBILE_USER_POINTS_OPENER} from './types';

const initialStateMobileMenu = {
  isMenuOpened: false,
  isPointsOpened: false,
};

export const appMobileMenuReducer = (state = initialStateMobileMenu, action) => {
  switch (action.type) {
    case MOBILE_MENU_OPENER:
      //return {...state, sections: state.sections.concat(action.payload)}
      return {...state, isMenuOpened: !state.isMenuOpened}
    case MOBILE_USER_POINTS_OPENER:
      //return {...state, sections: state.sections.concat(action.payload)}
      return {...state, isPointsOpened: !state.isPointsOpened}
    default: return state
  }
};