import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import './Header.scss';
import GetIcon from '../GetIcon/GetIcon';
import {authService} from '../../services/auth';
import {user} from '../../modules/constants';
import {
  toggleMobileMenuAction,
  profileLogoutAction,
} from '../../redux/actions';

class Header extends Component {
  state = {
    redirect: false,
    redirectUrl: '/login',

    isMenuOpened: false
  };

  handleClickLogout = (e) => {
    e.preventDefault();
    authService.logout();
    this.props.profileLogoutAction();

    this.setState({
      redirect: true,
    });
  };

  handleClickMenuToggle = (e) => {
    this.props.toggleMobileMenuAction();
  };

  componentDidMount() {}

  render() {
    let {transparentHeader, hideMobileMenu, hideTopMenu, mobileMenu, profile} = this.props;

    let transparentHeaderClass = '';

    if (transparentHeader === true) {
      transparentHeaderClass = 'transparent';
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl}/>;
    }

    return (
        <header className={`header ${transparentHeaderClass} ${mobileMenu.isMenuOpened ? 'is-menu-opened' : ''}`}>
          <div className="container">
            {!hideMobileMenu ? <div className="header-menu-toggle">
              <button className="btn-link" onClick={this.handleClickMenuToggle}>
                {mobileMenu.isMenuOpened ?
                    <GetIcon name="icon-close"/> :
                    <GetIcon name="icon-menu"/>}
              </button>
            </div> : null}
            <div className="header-logo-container">
              <Link className="header-logo-link" to="/">
                <div className="header-logo-icon">
                  <GetIcon name="icon-logo-profile-colored"/>
                </div>
                <div className="header-logo-text">
                  <div className="header-logo-title">Цифровое портфолио</div>
                  <div className="header-logo-subtitle">Томский политехнический университет</div>
                </div>
              </Link>
            </div>
            {/*<nav className="navigation">
             <div className="nav-wrapper">
             </div>
             </nav>*/}

            {!hideTopMenu ?
                <div className="header-top-menu">
                  {/*<div className="top-menu-average-points">
                   <Link to="/my/personal/"
                   className="link-off">
                   <div className="points-text">
                   Средний балл:
                   </div>
                   <div className="points-notice">
                   <div className="notice">
                   4 б.
                   </div>
                   </div>
                   </Link>
                   </div>*/}
                  <div className="top-menu-profile">
                    <Link to="/my/personal"
                          className="btn-square size-md">
                      <GetIcon name="icon-user"/>
                    </Link>
                    <div className="menu-profile-dropdown">
                      <ul className="dropdown-list">
                        <li className="dropdown-item">
                          <Link to="/my/personal">Моя страница</Link>
                        </li>
                        {
                          authService.getUserRole() === 'manager' ? <li className="dropdown-item">
                            <a href="/manager">Управление</a>
                          </li> : null

                        }
                        <li className="dropdown-item">
                          <Link onClick={this.handleClickLogout} to="/sso/logout">Выйти</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                : null}
          </div>
        </header>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileMenu: state.mobileMenu,
    profile: state.profile.profile
  };
}

const mapDispatchToProps = {
  toggleMobileMenuAction: toggleMobileMenuAction,
  profileLogoutAction: profileLogoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);