import React, {Component} from 'react';
import { Input, Tooltip } from 'antd';

import './NumericInput.scss';

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}


class NumericInput extends Component {
  onChange = e => {
    let {value} = e.target;
    const {min, max} = this.props;

    if (min && value && parseInt(value) < min) {
      value = min;
    }

    if (max && value && parseInt(value) > max) {
      value = max;
    }

    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value, this.props.name);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    /*const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    console.log(value);
    if (!isNaN(value) && value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }*/
  };

  render() {
    const {value, fieldLabel, min, max, className, size, noLabel} = this.props;
    /*const title = value ? (
        <span className="numeric-input-title">{value !== '-' ?
            formatNumber(value) :
            '-'}</span>
    ) : (
        'Введите число от 1 до 5'
    );*/

    let classNames = ['input-field', 'input-numeric-field'];
    if (className) {
      classNames.push(className)
    }

    if (size) {
      classNames.push('input-size-small')
    }
    if (noLabel) {
      classNames.push('input-no-label')
    }

    return (
        /*<Tooltip
            trigger={['focus']}
            title={title}
            placement="bottomLeft"
            overlayClassName="numeric-input"
        >*/
          <div className={classNames.join(' ')}>
            <div className="field-label">{fieldLabel || name}</div>
            <input
                min={min}
                max={max}
                type="number"
                value={value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="5"
                maxLength={4}
            />
          </div>

        /*</Tooltip>*/
    );
  }
}

export default NumericInput;