import React from 'react';
import CardCols from './CardCols';

const CardInnerHeader = (props) => {
  return (
      <div className={`card-inner-header ${props.className}`}>
        {props.children}
      </div>
  );
};

CardInnerHeader.defaultProps = {
  className: ''
};

export default CardInnerHeader;
