import React, {Fragment, Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import './LoginForm.scss';

import {authService} from '../../services/auth';
import {IS_DEVELOPMENT, SITE_HOST_URL} from '../../modules/constants';
import {createOauthToken, loginAuthSso} from '../../services/apiRequests';
import {
  getProfileAction,
  getProfilePhotoAction,
  getSectionsAction,
} from '../../redux/actions';
import TextInput from '../../components/TextInput/TextInput';
import alertNotice from '../../components/alertNotice/alertNotice';
import {message} from 'antd';

let oauthWindow = null;
let previousUrl = null;

class LoginForm extends Component {
  state = {
    redirectToReferrer: false,
    login: '',
    password: '',
    fromPathname: this.props.location.state || {from: {pathname: '/'}},
    successOauthUrl: '/my/personal',
    hasError: false,
  };

  handleSubmitLogin = (e) => {
    e.preventDefault();

    this.setState({
      hasError: false,
    });

    createOauthToken({
      'client_id': '43f9c5e3c53bd6666a372882888e5c24',
      'grant_type': 'password',
      'username': this.state.login.toLowerCase(),
      'password': this.state.password,
    }).then((resp) => {
      if (resp.status === 200) {
        authService.login(resp.data);
        authService.detectAndSetUserRole(resp.data);

        this.props.getSectionsAction();
        this.props.getProfileAction();
        this.props.getProfilePhotoAction();
        this.setState({redirectToReferrer: true});

        if (typeof this.props.onSuccess === 'function') {
          this.props.onSuccess(resp.data);
        }
      }
      else {
        alertNotice.error('Логин или пароль неправильный');

        this.setState({
          hasError: true,
        });
      }

      return resp;
    }).catch((err) => {
      console.log(err);
      alertNotice.error('Логин или пароль неправильный');

      this.setState({
        hasError: true,
      });
    });
  };

  handleInputChange = (value, name) => {
    this.setState({
      [name]: value,
      hasError: false,
    });
  };

  receiveMessage = (event) => {
    console.log('receiveMessage', event.origin, '->', SITE_HOST_URL);
    if (event.origin !== SITE_HOST_URL) {
      return;
    }
    const {data} = event;

    //console.log(data);
    //console.log(JSON.stringify(data));
    //console.log(data.payload.response.user);

    if (data && data.type === 'oauthResult' && data.result === true) {
      window.location.pathname = this.state.successOauthUrl;
    } else if (data && data.result === false) {
      console.error(data.response);
      alertNotice.error({
        content: 'Неизвестная ошибка, что-то пошло не так!',
        duration: 8,
        key: 'updatable-message',
      });
    }
  };

  openSignInWindow = (url, name) => {
    window.removeEventListener('message', this.receiveMessage);

    const strFeatures = 'width=600,height=700,top=100,left=100,resizable=yes,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=yes';

    if (oauthWindow === null || oauthWindow.closed) {
      oauthWindow = window.open(url, name, strFeatures);
    } else if (previousUrl !== url) {
      oauthWindow = window.open(url, name, strFeatures);
      oauthWindow.focus();
    } else {
      oauthWindow.focus();
    }

    window.addEventListener('message', (event) => {
      this.receiveMessage(event);
    }, false);

    previousUrl = url;
  };

  handleSsoTpuBtnClick = () => {
    const cancel_url = encodeURIComponent(SITE_HOST_URL);
    let urlSso = `https://portal.tpu.ru/ssogate/redirect?p_lsnrtoken=portfolio&p_cancel=${cancel_url}&p_forcedauth=true`;
    //let urlSso = `http://localhost:3333/sso/success?urlc=v1.2~9B8286EAC919C7FD39EF47A96656902F69AE793DA341C9555ED27CD24D463AA975A3F1069E7F8C29F20326ABEA8590936CEE41674FFE968FC14D93DD6D38BD34816791224EBEDFDF8CD9DEC2148414ACD8697CCB28EBD8ABFC1BB66DB1C9258073976E8E33A1FC847C87282D65C988F4CC68085303CBF08313D5B44510A4D368FB768AF4DA7F0C741C4FBA0C918552750852E7D81FD539ADB4D94D763704444BF68547CE8879F7308FE49BFB72868E616B992974F2DFE8F883F4979958E0EDE3A3B342B6B9AB60239DDCA291D7C10537674B4AC57ED76E55DC44449713FDD530C458B3B6E43CC385DF834789B1F055620FC8DCDCF422261521D95B6E259F3A92A8E16C3EAAD2A7F9ABE4554DC47D03EE72C5EBBD15DB2FBA1FFC96B819437A14E7159506289A6D2C440C34216B6F4A30CC150B498148DC9C`;

    /*For testing API for sso directly*/
    /*loginAuthSso({
      'client_id': '43f9c5e3c53bd6666a372882888e5c24',
      'provider': 'tpu_sso_v1',
      'token': 'v1.2~9B8286EAC919C7FD39EF47A96656902F69AE793DA341C9555ED27CD24D463AA975A3F1069E7F8C29F20326ABEA8590936CEE41674FFE968FC14D93DD6D38BD34816791224EBEDFDF8CD9DEC2148414ACD8697CCB28EBD8ABFC1BB66DB1C9258073976E8E33A1FC847C87282D65C988F4CC68085303CBF08313D5B44510A4D368FB768AF4DA7F0C741C4FBA0C918552750852E7D81FD539ADB4D94D763704444BF68547CE8879F7308FE49BFB72868E616B992974F2DFE8F883F4979958E0EDE3A3B342B6B9AB60239DDCA291D7C10537674B4AC57ED76E55DC44449713FDD530C458B3B6E43CC385DF834789B1F055620FC8DCDCF422261521D95B6E259F3A92A8E16C3EAAD2A7F9ABE4554DC47D03EE72C5EBBD15DB2FBA1FFC96B819437A14E7159506289A6D2C440C34216B6F4A30CC150B498148DC9C',
    }).then((resp) => {}).catch((err)=>{});*/
    this.openSignInWindow(urlSso, 'portal-tpu-ru-ssogate');
  };

  render() {
    let {redirectToReferrer, fromPathname} = this.state;
    let {header} = this.props;

    if (redirectToReferrer) {
      return <Redirect to={fromPathname}/>;
    }

    return (
        <div className="login-form-container">
          {header ?
              <h5 className="login-form-header">{header}</h5> : null
          }
          <form className="login-form" onSubmit={this.handleSubmitLogin}>
            <TextInput
                fieldLabel="Логин или Email"
                name='login'
                className="login-form-input"
                value={this.state.login}
                onTextChange={this.handleInputChange}
                placeholder="Логин или Email"/>
            <TextInput
                fieldLabel="Пароль"
                name="password"
                type="password"
                className="login-form-input"
                value={this.state.password}
                onTextChange={this.handleInputChange}
                placeholder="Пароль"/>

            <button className="login-btn btn btn-green btn-sm">
              Войти
            </button>

            {this.state.hasError
                ?
                <div className="formError">Логин или пароль неправильный</div>
                :
                null
            }
          </form>

          <div className="login-form-addition">
            <div className="form-addition-text">
              Войти с помощью:
            </div>
            <div className="form-addition-buttons">
              <button onClick={this.handleSsoTpuBtnClick} className="btn-square size-md btn-lightgreen">ТПУ</button>
            </div>
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = {
  getSectionsAction: getSectionsAction,
  getProfileAction: getProfileAction,
  getProfilePhotoAction: getProfilePhotoAction,
};

export default connect(null, mapDispatchToProps)(withRouter(LoginForm));
