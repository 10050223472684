import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Empty, Skeleton, Table, Select} from 'antd';
const {Option} = Select;
import moment from 'moment';
import 'moment/locale/ru';

import {
  calcDashboardAbsoluteAcademicQuality,
  calcDashboardAverageScore,
  calcDashboardEducationQuality,
  calcDashboardEnglishCertificatesStudents,
  calcDashboardForeignStudents,
  calcDashboardGraduates,
  calcDashboardPublications,
  calcDashboardScholarships,
  findDepartments,
  findSpecialities, getCountries,
  getEducationDegrees,
  getEducationForms,
  getEducationFoundation, getEducationLevels,
  getEducationScholarshipTypes,
  getGroups, getEducationCategories,
} from '../../services/apiRequests';
import {courses, idToName, terms} from '../../modules/constants';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import Tags from '../../components/Tags/Tags';
import LineGraph from '../../components/LineGraph/LineGraph';
import Card from '../../components/Card/Card';
import CardCols, {CardCol} from '../../components/Card/CardCols';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import SelectInput from '../../components/SelectInput/SelectInput';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import LeftPanelManager
  from '../../components/LeftPanelManager/LeftPanelManager';
import RightPanelManager
  from '../../components/RightPanelManager/RightPanelManager';
import GetIcon from '../../components/GetIcon/GetIcon';
import alertNotice from '../../components/alertNotice/alertNotice';

const generateArrayOfYears = (minOld = 20) => {
  let max = new Date().getFullYear();
  let min = max - minOld;
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

const paginationTableSettings = {
  size: 'small',
  showTotal: false,
  showSizeChanger: false,
  pageSize: 10,
  hideOnSinglePage: true,
  position: ['bottomCenter'],
};

const columnsForeignStudents = [
  {
    title: 'Страна',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Количество',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: '%',
    dataIndex: 'percent',
    key: 'percent',
  },
];

const foreignStudentsItems = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'СНГ',
    value: 'cis',
  },
  {
    label: 'Дальнее зарубежье',
    value: 'other',
  },
];

class ManagerDashboardPage extends Component {
  constructor(props) {
    super();

    this.state = {
      isApplyFilterLoading: false,
      isFilterOpen: true,

      yearsList: generateArrayOfYears(),

      departmentsList: null,
      specialitiesList: null,
      educationFormsList: null,
      educationLevelsList: null,

      selectedYears: [],
      selectedAccomplishments: [],
      selectedCourses: [],
      selectedTerms: [],
      selectedDepartments: [],
      selectedSpecialities: [],
      selectedEducationForms: [],
      selectedEducationLevels: [],
      selectedDateFrom: null,
      selectedDateTo: null,

      tagSelectedScholarships: null,
      tagSelectedForeignStudents: null,
      tagSelectedPerformance : null,

      //no default here for selectedScholarships default init in
      selectedScholarships: null,
      //'cis'
      selectedForeignStudents: foreignStudentsItems[1].value,
      selectedPerformance : 'absAndQuality',

      absoluteAcademicQualityList: null,
      averageScoreList: null,
      educationQualityList: null,
      /*chart data*/
      averageScoreChartData: {},
      absAndQualityChartData: {},

      englishCertificatesStudentsList: null,
      foreignStudentsList: null,
      foreignStudentsListObject: {},
      graduatesList: null,
      publicationsList: null,
      scholarshipsList: null,
    };
  }

  componentDidMount() {
    getEducationForms().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationFormsList: resp.data});
      }
    });

    getEducationLevels().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationLevelsList: resp.data});
      }
    });

    findSpecialities().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({specialitiesList: resp.data});
      }
    });

    findDepartments().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({departmentsList: resp.data});
      }
    });

    this.calcWidgetsEvent();
  }

  handleMultiSelectChange = (values, name) => {
    this.setState({[name]: values});
  };

  handleFromPeriodChange = (date, dateString) => {
    this.setState({selectedDateFrom: date});
  };

  handleToPeriodChange = (date, dateString) => {
    this.setState({selectedDateTo: date});
  };

  handleTagsScholarshipsChange = (value, name) => {
    const {scholarshipsList} = this.state;

    this.setState({
      tagSelectedScholarships: value,
      selectedScholarships: scholarshipsList ? scholarshipsList[value] : null
    });
  };

  handleTagsForeignStudentsChange = (value, name) => {
    const {foreignStudentsListObject} = this.state;

    this.setState({
      tagSelectedForeignStudents: value,
      selectedForeignStudents: value,
      foreignStudentsList: foreignStudentsListObject[value]
    });
  };

  handleTagsPerformanceChange = (value, name) => {
    this.setState({
      tagSelectedPerformance: value,
      selectedPerformance: value
    });
  };

  getGlobalFilterObject = () => {
    let {
      selectedYears,
      selectedAccomplishments,
      selectedCourses,
      selectedTerms,
      selectedDepartments,
      selectedSpecialities,
      selectedEducationForms,
      selectedEducationLevels,
      selectedDateFrom,
      selectedDateTo
    } = this.state;

    return {
      global: {
        date_from: selectedDateFrom ? selectedDateFrom.unix() : null,
        date_to: selectedDateTo ? selectedDateTo.unix() : null,
        graduation_years: this.getIdsFromList(selectedYears),
        education_levels_ids: this.getIdsFromList(selectedEducationLevels),
        education_forms_ids: this.getIdsFromList(selectedEducationForms),
        departments_ids: this.getIdsFromList(selectedDepartments),
        specialties_ids: this.getIdsFromList(selectedSpecialities),
        courses_ids: this.getIdsFromList(selectedCourses),
        terms_ids: this.getIdsFromList(selectedTerms),
        accomplishments_ids: this.getIdsFromList(selectedAccomplishments),
      }
    };
  };

  getWidgetLocalObject = (type, forceValue) => {
    let {
      selectedForeignStudents,
    } = this.state;

    if (forceValue || forceValue === '') {
      selectedForeignStudents = forceValue;
    }

    if (type === 'citizenship_type') {
      return {
        widget: {
          citizenship_type: selectedForeignStudents ?? null
        }
      };
    }

    return {}
  };

  handleApplyFilterClick = () => {
    this.setState({
      isApplyFilterLoading: true,
    });

    this.calcWidgetsEvent().catch((err)=>{
      if (err.response) {
        alertNotice.error(err.response.status + ' ' + err.response.statusText || err.message);
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        alertNotice.error(err.request);
        console.log(err.request);
      } else {
        alertNotice.error('Error' + ' ' + err.message);
        console.log('Error', err.message);
      }
      console.log(err.config);
    }).finally(()=>{

      this.setState({
        isApplyFilterLoading: false,
      });
    });
  };

  buildDataForChart = (rawData, settings = {}) => {
    let chartData = {
      labels: [],
      datasets: []
    };
    
    if (rawData) {
      let labels = [];
      let dataset = [];

      rawData.map((item, index) => {
        labels.push(parseInt(item['year'], 10));
        dataset.push(parseFloat(item['value']).toFixed(2));
      });

      chartData['labels'] = [...labels];
      chartData['datasets'].push({
        data: dataset,
        ...settings,
      });
    }

    return chartData;
  };

  calcWidgetsEvent = () => {
    let globalOptions = this.getGlobalFilterObject();

    const promiseCalcDashboardAbsoluteAcademicQuality = calcDashboardAbsoluteAcademicQuality(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        let {absAndQualityChartData} = this.state;

        let absChartData = this.buildDataForChart(resp.data,
            {
              label: 'Абсолютная успеваемость %',
              borderColor: 'rgba(142, 200, 200, 0.9)',
              backgroundColor: 'rgba(142, 200, 200, 0.2)',
            });

        /*datasets.length = 2 mean that we have
        some chart on canvas - reset it*/
        if (absAndQualityChartData['datasets'].length >= 2) {
          absAndQualityChartData['datasets'] = [];
        }

        /*promise may be resolved not first
        and push second chart to canvas*/
        if (absAndQualityChartData['datasets'].length) {
          absAndQualityChartData['datasets'].push(...absChartData['datasets']);
        }
        /*promise is first just add data*/
        else {
          absAndQualityChartData = absChartData;
        }

        this.setState({
          absoluteAcademicQualityList: resp.data,
          absAndQualityChartData,
        });
      }

      return resp;
    });

    const promiseCalcDashboardEducationQuality = calcDashboardEducationQuality(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        let {absAndQualityChartData} = this.state;

        let qualityChartData = this.buildDataForChart(resp.data,
            {
              label: 'Качество обучения %',
              borderColor: 'rgba(118, 103, 208, 0.9)',
              backgroundColor: 'rgba(118, 103, 208, 0.2)',
            });

        /*datasets.length = 2 mean that we have
         some chart on canvas - reset it*/
        if (absAndQualityChartData['datasets'].length >= 2) {
          absAndQualityChartData['datasets'] = [];
        }

        /*promise may be resolved not first
        and push second chart to canvas*/
        if (absAndQualityChartData['datasets'].length) {
          absAndQualityChartData['datasets'].push(...qualityChartData['datasets']);
        }
        /*promise is first just add data*/
        else {
          absAndQualityChartData = qualityChartData;
        }

        this.setState({
          educationQualityList: resp.data,
          absAndQualityChartData,
        });
      }

      return resp;
    });

    const promiseCalcDashboardAverageScore = calcDashboardAverageScore(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {

        let averageScoreChartData = this.buildDataForChart(resp.data,
            {
              label: 'Средний балл',
              borderColor: 'rgba(118, 103, 208, 0.9)',
              backgroundColor: 'rgba(118, 103, 208, 0.2)',

            });

        this.setState({
          averageScoreList: resp.data,
          averageScoreChartData,
        });
      }

      return resp;
    });

    const promiseCalcDashboardEnglishCertificatesStudents = calcDashboardEnglishCertificatesStudents(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({englishCertificatesStudentsList: resp.data});
      }

      return resp;
    });

    const promisesArrayForeignStudents = foreignStudentsItems.map((item, index)=>{
      let foreignStudentsOptions = this.getWidgetLocalObject('citizenship_type', item.value);
      return calcDashboardForeignStudents({...globalOptions, ...foreignStudentsOptions}).then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          /*Select default value for table init data loading*/
          let {foreignStudentsListObject, selectedForeignStudents} = this.state;
          foreignStudentsListObject[item.value] = resp.data;

          if (selectedForeignStudents === item.value) {

            this.setState({
              foreignStudentsList: resp.data
            });
          }

          this.setState({
            foreignStudentsListObject
          });
        }

        return resp;
      });
    });

    const promiseCalcDashboardGraduates = calcDashboardGraduates(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({graduatesList: resp.data});
      }

      return resp;
    });

    const promiseCalcDashboardPublications = calcDashboardPublications(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({publicationsList: resp.data});
      }

      return resp;
    });

    const promiseCalcDashboardScholarships = calcDashboardScholarships(globalOptions).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        const {selectedScholarships, tagSelectedScholarships} = this.state;

        this.setState({
          scholarshipsList: resp.data,
          selectedScholarships: tagSelectedScholarships ? resp.data[tagSelectedScholarships] : resp.data[0]
        });
      }

      return resp;
    });

    return Promise.all([
      promiseCalcDashboardAbsoluteAcademicQuality,
      promiseCalcDashboardEducationQuality,
      promiseCalcDashboardAverageScore,
      promiseCalcDashboardEnglishCertificatesStudents,
      promiseCalcDashboardGraduates,
      promiseCalcDashboardPublications,
      promiseCalcDashboardScholarships].concat(promisesArrayForeignStudents));
  };

  /*Helpers*/
  getIdsFromList = (list) => {
    return list.map((item) => (
        item['value'] ?? item['value']
    ));
  };

  /*View logic*/
  buildOptionItems = (itemsObj, extraField) => {
    if (itemsObj && Object.keys(itemsObj).length > 0) {
      return Object.keys(itemsObj).map((key, index) => (
          {
            value: itemsObj[key].id,
            label: (extraField ? itemsObj[key][extraField] + ' - ' : '') + (itemsObj[key].full_name || itemsObj[key].name),
          }
      ));
    }

    return [];
  };

  render() {
    let {
      isApplyFilterLoading, isFilterOpen,
      yearsList,
      departmentsList,
      specialitiesList,
      educationFormsList,
      educationLevelsList,

      tagSelectedScholarships,
      tagSelectedForeignStudents,
      tagSelectedPerformance ,

      selectedScholarships,
      selectedForeignStudents,
      selectedPerformance ,

      absoluteAcademicQualityList,
      averageScoreList,
      educationQualityList,
      /*chart data*/
      absAndQualityChartData,
      averageScoreChartData,

      englishCertificatesStudentsList,
      foreignStudentsList,
      foreignStudentsListObject,
      graduatesList,
      publicationsList,
      scholarshipsList,

      selectedDateFrom,
      selectedDateTo,
    } = this.state;

    const {sections} = this.props;

    let educationFormsItems = this.buildOptionItems(educationFormsList);
    let educationLevelsItems = this.buildOptionItems(educationLevelsList);
    let specialitiesItems = this.buildOptionItems(specialitiesList, 'number');
    let departmentsItems = this.buildOptionItems(departmentsList);

    let accomplishmentsItems = [];
    if (sections && sections.length) {
      const accomplishmentsList = sections[0]['subsections']['1']['accomplishments'];
      accomplishmentsItems = this.buildOptionItems(accomplishmentsList);
    }

    let dataSourceForeignStudents = [];
    let foreignStudentsCount = 0;

    if (foreignStudentsList) {
      foreignStudentsCount = foreignStudentsCount + foreignStudentsList['total'];

      dataSourceForeignStudents = Object.keys(foreignStudentsList['countries']).
          map((item, index) => {
            return {
              key: index,
              country: item,
              amount: foreignStudentsList['countries'][item],
              percent: (foreignStudentsList['countries'][item] * 100 /
                  foreignStudentsList['total']).toFixed(2),
            };
          });
    }

    let scholarshipsTagsItems = [];
    if (scholarshipsList) {
      scholarshipsTagsItems = Object.keys(scholarshipsList).map((item, index) => {
        return {
          label: scholarshipsList[item].name,
          value: item,
        };
      });
    }

    return (
        <LayoutMain>
          <PageContent>
            <LeftPanelManager/>

            <RightPanelManager
                header="Дашборд">

              <Card hasHover={false}
                    isCollapsible={true}
                    size="auto"
                    header="Фильтр"
                    minHeight={false}>
                <CardCols className="mb20">
                  <CardCol>
                    <DatePickerInput
                        value={selectedDateFrom}
                        fieldLabel="С периода"
                        onChange={this.handleFromPeriodChange}/>
                  </CardCol>
                  <CardCol>
                    <DatePickerInput
                        value={selectedDateTo}
                        fieldLabel="По период"
                        onChange={this.handleToPeriodChange}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Год выпуска"
                        name="selectedYears">
                      {yearsList.map((year) => (
                          <Option key={year} value={year}>{year}</Option>
                      ))}
                    </SelectInput>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        name="selectedEducationForms"
                        fieldLabel="Форма обучения"
                        items={educationFormsItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        name="selectedEducationLevels"
                        fieldLabel="Уровень подготовки"
                        items={educationLevelsItems}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        name="selectedSpecialities"
                        fieldLabel="Направление подготовки"
                        items={specialitiesItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        name="selectedDepartments"
                        fieldLabel="Школа"
                        items={departmentsItems}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Курс"
                        name="selectedCourses">
                      {courses.map(course => (
                          <Option key={course} value={course}>{course}</Option>
                      ))}
                    </SelectInput>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Семестр"
                        name="selectedTerms">
                      {terms.length > 0 ? terms.map(term => (
                          <Option key={term} value={term}>{term}</Option>
                      )) : null}
                    </SelectInput>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        name="selectedAccomplishments"
                        fieldLabel="Публикации"
                        items={accomplishmentsItems}/>
                  </CardCol>
                </CardCols>

                <div className="card-inner-bottom-actions mb20">
                  <button
                      disabled={isApplyFilterLoading}
                      onClick={this.handleApplyFilterClick}
                      className={'btn btn-green btn-sm action-save' +
                      (isApplyFilterLoading ? ' disabled' : '')}>
                    Применить
                  </button>
                </div>
              </Card>

              <Card hasHover={false}
                    size="half"
                    minHeight={false}>
                <CardInnerHeader>
                  Количество студентов
                </CardInnerHeader>
                <Tags
                    onTagChange={this.handleTagsForeignStudentsChange}
                    groupName="tagSelectedForeignStudents"
                    defaultValue={selectedForeignStudents}
                    items={foreignStudentsItems}/>

                <CardInnerText className="color-text-grey">
                  Количество
                </CardInnerText>
                <hr/>
                <CardInnerText>
                  {foreignStudentsCount}
                </CardInnerText>
                <div className="card-table mt24">
                  <Table dataSource={dataSourceForeignStudents}
                         columns={columnsForeignStudents}
                         locale={{ emptyText: <Empty description="Студентов не найдено"/> }}
                         pagination={paginationTableSettings}/>
                  {/*<div className="table-like table-record-book">
                   <div className="table-header">
                   <div className="table-row">
                   <div className="table-data data-wide-2">Страна</div>
                   <div className="table-data">Количество</div>
                   <div className="table-data">%</div>
                   </div>
                   </div>
                   <div className="table-body">
                   {foreignStudentsList && foreignStudentsList['countries'] ?
                   Object.keys(foreignStudentsList['countries']).map((item, index)=>{
                   return <div key={index + '-desktop'} className="table-row row-desktop">
                   <div className="table-data data-wide-2">{item}</div>
                   <div className="table-data">{foreignStudentsList['countries'][item]}</div>
                   <div className="table-data">{(foreignStudentsList['countries'][item]*100/foreignStudentsList['total']).toFixed(2)}</div>
                   </div>
                   }) : <Skeleton
                   title={false}
                   paragraph={{
                   rows: 4,
                   }}
                   active/>}
                   </div>
                   </div>*/}
                </div>
              </Card>

              <Card hasHover={false}
                    size="half"
                    minHeight={false}>
                <CardInnerHeader>Количество стипендиатов</CardInnerHeader>
                <Tags
                    onTagChange={this.handleTagsScholarshipsChange}
                    groupName="tagSelectedScholarships"
                    defaultValue="0"
                    items={scholarshipsTagsItems}/>
                <CardInnerText className="color-text-grey">
                  Количество
                </CardInnerText>
                <hr/>
                <CardInnerText>
                  {selectedScholarships ? selectedScholarships.count : 0}
                </CardInnerText>

                <CardInnerHeader className="mt20">
                  Успеваемость
                </CardInnerHeader>
                <Tags
                    onTagChange={this.handleTagsPerformanceChange}
                    groupName="tagSelectedPerformance"
                    defaultValue="absAndQuality"
                    items={[
                      {
                        label: 'Успеваемость и качество обучения',
                        value: 'absAndQuality',
                      },
                      {
                        label: 'Средний балл',
                        value: 'average',
                      },
                    ]}/>

                <div className="card-inner-chart">
                  {selectedPerformance === 'absAndQuality' ?
                      <LineGraph
                          id="absAndQuality"
                          data={absAndQualityChartData}/>
                      : selectedPerformance === 'average' ?
                              <LineGraph
                                  id="average"
                                  options={{
                                    scales: {
                                      yAxes: [
                                        {
                                          ticks: {
                                            max: 5,
                                            min: 2,
                                          },
                                        }]
                                    },
                                  }}
                                  data={averageScoreChartData}/> : null}
                </div>
              </Card>

              <Card hasHover={false}
                    size="half"
                    minHeight={false}>
                <CardInnerHeader>
                  Количество студентов, имеющих сертификаты по иностранному языку
                </CardInnerHeader>
                <div className="card-inner-text color-text-grey">
                  Количество
                </div>
                <hr/>
                <CardInnerText>
                  {englishCertificatesStudentsList ?
                      englishCertificatesStudentsList.count :
                      0}
                </CardInnerText>
              </Card>

              <Card hasHover={false}
                    size="half"
                    minHeight={false}>
                <CardInnerHeader>
                  Количество публикаций
                </CardInnerHeader>
                <CardInnerText className="color-text-grey">
                  Количество
                </CardInnerText>
                <hr/>
                <CardInnerText>
                  {publicationsList ? publicationsList.count : 0}
                </CardInnerText>
              </Card>
              <Card hasHover={false}
                    size="half"
                    minHeight={false}>
                <CardInnerHeader>
                  Количество выпускников
                </CardInnerHeader>
                <CardInnerText className="color-text-grey">
                  Количество
                </CardInnerText>
                <hr/>
                <CardInnerText className="color-text-grey">
                  {graduatesList ? graduatesList.count : 0}
                </CardInnerText>
              </Card>

            </RightPanelManager>
          </PageContent>
        </LayoutMain>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    sections: state.sectionLeftPanel.sections,
  };
};

export default connect(mapStateToProps, null)(ManagerDashboardPage);
