import React, {Component} from 'react';
import {Skeleton} from 'antd';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import PageContent from '../../components/PageContent/PageContent';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';
import GetIcon from '../../components/GetIcon/GetIcon';

class StudentStudyEducationPage extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    let education = null;
    if (profile) {
      education = profile.education;
    }

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageHeadline>
            {profile ?
                `${profile.personal.last_name} ${profile.personal.first_name} ${profile.personal.middle_name.length > 1 ? profile.personal.middle_name : ''}` :
                <Skeleton.Input style={{ minWidth: 300 }} active/>
            }
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent
                goBackAction
                header="Образование">

              {education ?
                  <Card size="auto"
                        hasHover={false}>
                    <CardInnerHeader>Диплом</CardInnerHeader>

                    <div className="card-cols card-cols-2">
                      <div className="card-col card-col-1">
                        {/*<div className="card-read-field">
                          <div className="field-label">Вид</div>
                          <div className="field-value">Высшее</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Полное наименование организации</div>
                          <div className="field-value">Томский политехнический университет</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Краткое наименование организации</div>
                          <div className="field-value">ТПУ</div>
                        </div>*/}
                        <div className="card-read-field">
                          <div className="field-label">Уровень подготовки</div>
                          <div className="field-value">{education.education_level.full_name}</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Год поступления</div>
                          <div className="field-value">{education.admission_year}</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Форма обучения</div>
                          <div className="field-value">{education.education_form.short_name}</div>
                        </div>
                      </div>
                      <div className="card-col card-col-2">

                        <div className="card-read-field">
                          <div className="field-label">Подразделение</div>
                          <div className="field-value">{education.department.full_name}</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Направление подготовки</div>
                          <div className="field-value">{`${education.specialty.number} ${education.specialty.full_name}`}</div>
                        </div>
                        <div className="card-read-field">
                          <div className="field-label">Группа</div>
                          <div className="field-value">{
                            Object.keys(education.groups).map((index)=>(
                                education.groups[index].name + ' '
                            ))
                          }</div>
                        </div>
                        {/*<div className="card-read-field">
                          <div className="field-label">Профиль</div>
                          <div className="field-value">Экономика и управление на предприятии</div>
                        </div>*/}
                        {/*<div className="card-read-field">
                          <div className="field-label">Скан подтверждающего документа </div>
                          <div className="field-value">
                            <a className="link-std" href="#">Диплом 2018</a></div>
                        </div>*/}
                      </div>
                    </div>
                  </Card>
                  :
                  <Skeleton/>
              }



              {/*<Card size="auto"
                    hasHover={false}>
                <div className="card-inner-header">
                  Переподготовка и повышение квалификации
                </div>
                <div className="card-cols card-cols-2">
                  <div className="card-col card-col-1">
                    <div className="card-read-field">
                      <div className="field-label">Тип документа</div>
                      <div className="field-value">Удостоверение о повышении квалификации</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Серия</div>
                      <div className="field-value">70AB</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Номер</div>
                      <div className="field-value">028874</div>
                    </div>
                  </div>
                  <div className="card-col card-col-2">

                    <div className="card-read-field">
                      <div className="field-label">Дата выдачи</div>
                      <div className="field-value">07.08.2020</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Программа</div>
                      <div className="field-value">Экономика и менеджмент</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Учебное заведение</div>
                      <div className="field-value">Томский политехнический университет</div>
                    </div>
                  </div>
                </div>
              </Card>*/}

            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}


export default StudentStudyEducationPage;