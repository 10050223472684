import {
  USER_LOGOUT,
  GET_PROFILE,
  GET_PROFILE_PHOTO,
  GET_PROFILE_VIEWED,
  GET_PROFILE_VIEWED_PHOTO,
} from './types';

const initialStateProfile = {
  profile: null,
};

const initialStateProfilePhoto = {
  profilePhotoBase64: null,
};

/*FIX LATER clearing state after logout not working*/
export const profileLogoutReducer = (state = initialStateProfile, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        ...state,
        profile: initialStateProfile,
        profilePhotoBase64: initialStateProfilePhoto,
      };
    default: return state
  }
};

export const profileReducer = (state = initialStateProfile, action) => {
  switch (action.type) {
    case GET_PROFILE:
      //return {...state, sections: state.sections.concat(action.payload)}
      return {...state, profile: action.payload }
    default: return state
  }
};

export const profilePhotoReducer = (state = initialStateProfilePhoto, action) => {
  switch (action.type) {
    case GET_PROFILE_PHOTO:
      return {...state, profilePhotoBase64: action.payload }
    default: return state
  }
};

const initialStateProfileViewed = {
  profileViewed: null,
};

const initialStateProfileViewedPhoto = {
  profileViewedPhotoBase64: null,
};

export const profileViewedReducer = (state = initialStateProfileViewed, action) => {
  switch (action.type) {
    case GET_PROFILE_VIEWED:
      //return {...state, sections: state.sections.concat(action.payload)}
      return {...state, profileViewed: action.payload }
    default: return state
  }
};

export const profileViewedPhotoReducer = (state = initialStateProfileViewedPhoto, action) => {
  switch (action.type) {
    case GET_PROFILE_VIEWED_PHOTO:
      return {...state, profileViewedPhotoBase64: action.payload }
    default: return state
  }
};