import {Component} from 'react';

import {authService} from '../../services/auth';

class SsoLogoutPage extends Component {
  constructor(props) {
    super(props);
    authService.logout(()=>{
      //window.location.href = 'http://aid.main.tpu.ru:7777/pls/orasso/orasso.wwsso_app_admin.ls_logout?p_done_url=https://portfolio.tpu.ru';
      this.props.history.push('/login');
    });
  }


  render() {
    return null;
  }
}

export default SsoLogoutPage;