import React, {Component, Fragment} from 'react';

import './RightPanelManager.scss';

import RightPanel from '../RightPanel/RightPanel';

class RightPanelManager extends Component {
  state = {};

  componentDidMount() {
  }

  render() {
    return (
        <RightPanel {...this.props}>
          {this.props.children}
        </RightPanel>
    );
  }
}

export default RightPanelManager;