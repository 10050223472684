import React, {Children} from 'react';

const CardCols = (props) => {
  return (

      <div className={`card-cols card-cols-${Children.count(
          props.children)} ${props?.className}`}>
        {React.Children.map(props.children, (child, i) => {
          return React.cloneElement(child, { colNum: ++i })
        })}
      </div>
  );
};

export const CardCol = (props) => {
  return (
      <div className={`card-col card-col-${props.colNum} ${props.className}`}>
        {props.children}
      </div>
  );
};

CardCols.defaultProps = {
  className: ''
};

CardCol.defaultProps = {
  className: ''
};

CardCols.CardCol = CardCol;

export default CardCols;
