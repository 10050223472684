import React, {Component} from 'react';
import {CaretDownOutlined, CheckSquareOutlined} from '@ant-design/icons';
import {Checkbox, Select, Space} from 'antd';
const {Option} = Select;

import './SelectInput.scss';

import GetIcon from '../GetIcon/GetIcon';

class SelectInput extends Component {
  state = {
    selectedItems: this.props.items && this.props.items.length > 0 ?
        [this.props.items[0]] :
        [],
  };

  componentDidMount() {}

  handleChange = (value) => {
    if (typeof this.props.onSelectChange === 'function') {
      this.props.onSelectChange(value, this.props.name);
    }

    /*if (Array.isArray(value)) {
      value.map((item) => {
        if (item.value === 'all') {
          this.setState({items: this.props.items});
        }
        else {
          this.setState({items: value});
        }
      });
    }
    else {
      this.setState({items: value});
    }*/
  };

  handleSearchChange = (value) => {
    if (typeof this.props.onSearchChange === 'function') {
      this.props.onSearchChange(value, this.props.name);
    }
  };


  render() {
    let {
      name,
      fieldLabel,
      isMulti,
      className,
      disabled,
      allowClear,
      dropdownClassName,
      notFoundContent,
      placeholder,
      defaultValue,
      onSearchChange,
      hasSelectAll,
      hasSearch,
      value,
      maxTagCount,
      items: itemsProp,
    } = this.props;

    let {selectedItems} = this.state;

    let rows = [];
    /*if (items && items.length > 0) {
     items.map((item, key) =>{
     rows.push(<Option value={item.value} key={'val-' + item.value}>{item.label}</Option>);
     })
     }*/

    if (hasSelectAll && itemsProp[0].value !== 'all') {
      itemsProp.unshift({label: 'Все', value: 'all'});
    }

    let selectMode = null;
    let selectClasses = 'select-single';
    let optionClasses = 'option-single';
    if (!className) {
      className = '';
    }

    if (isMulti) {
      selectMode = 'multiple';
      selectClasses = 'select-multi';
      optionClasses = 'option-multi';
    }

    /*let defaultValue = null;

    if (itemsProp) {
      defaultValue =  itemsProp[0] ?? itemsProp[0];
      if (hasSelectAll) {
        defaultValue = itemsProp[1] ?? itemsProp[1];
      }
    }*/

    let finalProps = {};

    if (placeholder) {
      finalProps.placeholder = placeholder;
    }

    if (value) {
      finalProps.value = value;
    }

    finalProps.maxTagCount = 3;
    if (maxTagCount) {
      finalProps.maxTagCount = maxTagCount;
    }

    if (typeof disabled === 'boolean') {
      finalProps.disabled = disabled;
    }

    if (typeof allowClear === 'boolean') {
      finalProps.allowClear = allowClear;
    }
    else {
      finalProps.allowClear = true;
    }

    if (name) {
      finalProps.name = name;
    }

    if (defaultValue) {
      if (isMulti) {
        defaultValue = [defaultValue];
      }

      finalProps.defaultValue = defaultValue;
    }

    if (hasSearch) {
      finalProps.showSearch = hasSearch;
      finalProps.filterOption = (input, option) =>
          (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0);
      finalProps.filterSort = (optionA, optionB) =>
          (optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase()));
      finalProps.onSearch = this.handleSearchChange;
      finalProps.defaultActiveFirstOption = true;
    }
    finalProps.notFoundContent = notFoundContent ? notFoundContent : 'Начните вводить название...';

    return (
        <div className={`select-field ${className} ${selectClasses}`}>
          <div className="field-label">{fieldLabel || name}</div>
          <Select
              {...finalProps}
              /*value={items}*/
              options={itemsProp ? itemsProp : undefined}
              optionFilterProp="options"
              mode={selectMode}
              menuItemSelectedIcon={<GetIcon name="icon-check"/>}
              bordered={true}
              style={{width: '100%'}}
              suffixIcon={<CaretDownOutlined/>}
              showArrow
              labelInValue={true}
              dropdownClassName={optionClasses + ' ' + dropdownClassName}
              onChange={this.handleChange}>
            {this.props.children}
          </Select>
        </div>
    );
  }
}

export default SelectInput;

SelectInput.Option = Option;
