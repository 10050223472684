import React, {Component, Children} from 'react';
import {NavLink, Link, withRouter} from 'react-router-dom';

import GetIcon from '../GetIcon/GetIcon';
import './RightPanel.scss';

class RightPanel extends Component {
  state = {};

  componentDidMount() {}

  handleGoBack = (e) => {
    this.props.history.goBack(e);

    if (typeof this.props.goBackActionCb === 'function') {
      this.props.goBackActionCb(e)
    }
  }

  render() {
    let {
      header,
      topActions,
      notice,
      headerActions,
      underheadActions,
      goBackAction,
      className,
    } = this.props;

    if (topActions === undefined) {
      topActions = [];
    }
    if (goBackAction) {
      topActions = [
        <div key="action-go-back" className="top-action action-btn"
             onClick={this.handleGoBack}>
          <GetIcon name="icon-arrow-bold-left"/>
          назад
        </div>,
        topActions,
      ];
    }

    let headerActionsClasses = '';

    if (notice) {
      headerActionsClasses = 'has-notice';
    }

    return (
        <div className={`right-panel ${className}`}>
          {topActions ?
              <div className="right-panel-top-actions">
                {topActions}
              </div>
              : null
          }

          <div className="right-panel-header mb20">
            <h6 className="header-headline headline-6">{header}</h6>
            {
              headerActions ?
                  <div className={`right-panel-header-actions header-actions-${Children.count(
                      headerActions)} ${headerActionsClasses}`}>
                    {headerActions}
                  </div> : null
            }

            {notice ?
                <div className="right-panel-header-notice notice">
                  {notice}
                </div>
                : null
            }
          </div>

          {underheadActions ?
              <div className={`right-panel-underhead-actions mb12 underhead-actions-${Children.count(
                  underheadActions)}`}>
                {underheadActions}
              </div>
              : null
          }

          <div className="right-panel-content">
            {this.props.children}
          </div>
        </div>
    );
  }
}

RightPanel.defaultProps = {
  className: '',
}

export default withRouter(RightPanel);