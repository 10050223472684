import React, {Component, Fragment} from 'react';
import {NavLink, Link} from 'react-router-dom';

import GetIcon from '../GetIcon/GetIcon';

import './Card.scss';

class Card extends Component {
  state = {
    isCardOpen: true,
  };

  handleCollapseClick = (value, name) => {

    if (typeof this.props.collapsibleCb === 'function') {
      this.props.collapsibleCb();
    }

    this.setState({isCardOpen: !this.state.isCardOpen});
  }

  componentDidMount() {
  }

  render() {
    let {isCardOpen} = this.state;

    let {
      isCollapseOpened,
      header,
      notice,
      size,
      linkTo,
      hasHover,
      separatedHeader,
      isCollapsible,
      noPadding,
      disabled,
      minHeight,
      className,
      style,
    } = this.props;

    if (!size) {
      size = 'auto';
    }

    let cardClasses = [className, 'card-round-shadow', 'card-' + size];

    if (hasHover || hasHover === undefined) {
      cardClasses.push('has-hover');
    }

    if (disabled) {
      cardClasses.push('disabled');
    }

    if (!minHeight) {
      cardClasses.push('no-min-height');
    }

    if (isCollapsible) {
      if (typeof isCollapseOpened === 'boolean') {
        isCardOpen = isCollapseOpened;
      }

      let collapsibleClasses = 'collapsible closed';
      if (isCardOpen) {
        collapsibleClasses = 'collapsible opened';
      }
      cardClasses.push(collapsibleClasses);
    }

    if (noPadding) {
      cardClasses.push('no-padding');
    }

    let separatedHeaderClasses = '';
    if (separatedHeader) {
      separatedHeaderClasses = 'separated';
    }

    return (
        <div className={cardClasses.join(' ')} style={style}>
          <div className="card-top" onClick={this.handleCollapseClick}>
            {header ?
                <div className={'card-header ' + separatedHeaderClasses}>
                  {header}
                </div> : null
            }

            {isCollapsible ?
                <div className="card-collapse-icon">
                  {isCardOpen ?
                      <GetIcon name="icon-angle-up"/> :
                      <GetIcon name="icon-angle-down"/>}
                </div> :
                null
            }

            {notice ?
                <div className="card-notice notice">
                  {notice}
                </div> : null
            }
          </div>

          <div className="card-content">
            {this.props.children}
          </div>

          {linkTo ?
              [
                <div key="card-link" className="card-link">
                  <div className="link-btn">
                    <Link to={linkTo}>
                      <GetIcon name="icon-arrow-right"/>
                    </Link>
                  </div>
                </div>,
                <Link key="card-link-mobile"
                      to={linkTo}
                      className="card-link-mobile"/>
                ]
              : null
          }
        </div>
    );
  }
}

export default Card;