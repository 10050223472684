import {combineReducers} from 'redux';
import {appMobileMenuReducer} from './appMobileMenuReducer';
import {sectionsStudentReducer} from './sectionsStudentReducer';
import {
  profilePhotoReducer,
  profileReducer,
  profileViewedPhotoReducer,
  profileViewedReducer,
  profileLogoutReducer,
} from './profileReducer';

export const rootReducer = combineReducers({
  mobileMenu: appMobileMenuReducer,
  sectionLeftPanel: sectionsStudentReducer,
  profile: profileReducer,
  profileLogout: profileLogoutReducer,
  profilePhotoBase64: profilePhotoReducer,
  profileViewed: profileViewedReducer,
  profileViewedPhotoBase64: profileViewedPhotoReducer,
})