let commonConfig;
try {
  commonConfig = require('./common.json');
}
catch (e) {
  throw new Error('Please define config/common.json file. Exception: ' + e.message);
}

module.exports = {
  apiHostUrl: commonConfig.apiHostUrl,
  siteHostUrl: commonConfig.siteHostUrl,
  storageCryptoSalt: commonConfig.storageCryptoSalt,
  env: commonConfig.env,
  build: commonConfig.build
};
