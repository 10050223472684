import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {authService} from '../../services/auth';

const ProtectedRoute = ({component: Component, redirectTo, ...rest}) => {
  return (
      <Route
          {...rest}
          render={(props) =>
              authService.isAuthenticated() === true ? (
                  <Component {...props} {...rest}/>
              ) : (
                  <Redirect
                      to={{
                        pathname: redirectTo ? redirectTo : '/login',
                        state: {from: props.location},
                      }}
                  />
              )
          }
      />
  );
};

export default ProtectedRoute;
