import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeBox = (props) => {
  return (
      <QRCode size={props.size}
              value={props.text}
              renderAs='canvas'/>
  );
};

export default QRCodeBox;