import axios from 'axios';
import {API_HOST_URL} from '../modules/constants';
import {authService} from './auth';
import qs from 'qs';

axios.defaults.baseURL = API_HOST_URL;

const axiosWrapper = (axiosOptions) => {
  let token = authService.getUserToken();

  if (!axiosOptions.hasOwnProperty('headers')) {
    axiosOptions.headers = {
      'Authorization':  token ? 'Bearer ' + token : '',
    };
  } else {
    axiosOptions.headers = {
      ... axiosOptions.headers,
      'Authorization': token ? 'Bearer ' + token : '',
    };
  }

  return axios(axiosOptions).then((resp) => {
    if (resp.data.status === 0 && resp.data.data.message) {

    }
    return resp;
  })/*.catch((error) => {
    console.log(error);
    /!*if (error.response.status && (error.response.status === 401 || error.response.status === 403)) {
     //authService.logout();
     }
     if (error.response && error.response.data &&
     error.response.data.message) {
     console.log(error.response.data.message);
     //message.showNotice(error.response.data.data.message, 'error');
     }*!/
    return error;
  })*/;

};

export const loginAuthSso = (obj) => {
  /*{
   "client_id": "43f9c5e3c53bd6666a372882888e5c24",
   "provider": "tpu_sso_v1",
   "token": "v1.2~B3CA68C2D5A2D68E134AD4C8BE1195ACC3550AF6FD2FB5CABC42F3E2C886D34C9F5D209CAA9899F2DF4CC46A01BC6A9565DFE3793845F37DC4665E338126F1ED37EE468D5699E21F8291E55A23205B214EF45B1EAE3B3EF1C6DB8A1C8B467E5277BD4311AD09BDEC496433EEE890C2BF950A9552DC37518886B3BF9C7C0C433B3956E49E64848CA5E41F7B32A482163EA226006D27FEF556D902D99B366052CC2CB9AB50CC9F5DDEB7FC3FD2A2EF92E7D9142F952C1D6621280790FC376D3553B6F5FBA85276920DBE82F654B3C8936C83C76ACE20CA6741773DF0F367EB307AE7A2E448CFF0356A245E8C76FFAEDBB4FAFAD762FEFA071B8F1535EE357956EED001C9603CD43758D45D3CCF14D8A52629003150CB1F691F5E648AFA1B169B7153D4291876C0140FA5CAA2C582F03919FB0459915783C2AE9B26683D5A67E0EA"
   }*/
  const requestUrl = '/v1/auth/sso';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const createOauthToken = (obj) => {
  /*{
   "client_id": "43f9c5e3c53bd6666a372882888e5c24",
   "grant_type": "password",
   "username": "test.employee@tpu.ru",
   "password": "password_0"
   }*/
  const requestUrl = '/oauth2/token';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const revokeOauthToken = (obj) => {
  /*{
   "token_type_hint": "access_token",
   "token": "19dc2ab7fea4a5fe2c8453908f21bd004792baab"
   }*/
  const requestUrl = '/oauth2/revoke';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

/* Profile of auth (current) user */
export const getAuthProfile = () => {
  const requestUrl = '/v1/profile';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

/*{"is_public":true|false} */
export const changeAuthProfileSettings = (obj) => {
  const requestUrl = '/v1/profile/settings';
  return axiosWrapper({
    method: 'put',
    url: requestUrl,
    data: obj,
  });
};

export const getAuthProfilePhoto = () => {
  const timestamp = authService.getUserTimestamp();
  const requestUrl = '/v1/profile/photo' + (timestamp ? '?' + timestamp : '');
  return axiosWrapper({
    method: 'get',
    responseType: 'arraybuffer',
    url: requestUrl,
  });
};

export const getAuthProfileRatingAll = () => {
  const requestUrl = '/v1/profile/rating';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getAuthProfileRatingSection = (id) => {
  const requestUrl = '/v1/profile/rating/' + id;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

/*Moodle Course Works*/
export const getMdlCourseWorks = (encryptedId) => {
  const requestUrl = '/v1/course-works/' + encryptedId;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getMdlCourseWorksTypes = () => {
  const requestUrl = '/v1/course-works/types';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

/* Profiles */
export const searchProfile = (obj) => {
  const requestUrl = '/v1/profiles/search';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const getProfile = (id) => {
  const requestUrl = '/v1/profiles/' + id;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getProfilePhoto = (id) => {
  const requestUrl = '/v1/profiles/' + id + '/photo';
  return axiosWrapper({
    method: 'get',
    responseType: 'arraybuffer',
    url: requestUrl,
  });
};

export const getProfileRecordBook = (id) => {
  const requestUrl = '/v1/profiles/' + id + '/export/record-books';
  return axiosWrapper({
    method: 'get',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'arraybuffer',
    url: requestUrl,
  });
};

export const exportProfileResults = (obj) => {
  const requestUrl = '/v1/profiles/export';
  return axiosWrapper({
    method: 'post',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    data: obj,
    responseType: 'arraybuffer',
    url: requestUrl,
  });
};

export const getProfilePhotoSrc = (id) => {
  const requestUrl = '/v1/profiles/' + id + '/photo';
  return API_HOST_URL + requestUrl
};

export const getProfileRatingSection = (profileId, sectionId) => {
  const requestUrl = '/v1/profiles/' + profileId + '/rating/' + sectionId;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getProfileRatingAll = (profileId, sectionId) => {
  const requestUrl = '/v1/profiles/' + profileId + '/rating';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const shareProfilesSearch = (obj) => {
  /*
  {
     "filters_ids": [
        2
     ]
   }
  */
  const requestUrl = '/v1/profiles/search/share';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const getSharedProfilesSearch = (id) => {
  const requestUrl = '/v1/profiles/search/' + id;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

/* Sections */
export const getSections = (obj) => {
  const requestUrl = '/v1/sections';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};


/* Departments & Specialties */
export const findDepartments = (obj) => {
  /*{
    "types_ids": [
    37,
    42
  ],
      "query": "ядер",
      "limit": 3
  }*/

  const requestUrl = '/v1/departments';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const findSpecialities = (obj) => {
  /*{
    "parent_id": 3032,
      "query": "Физ",
      "limit": 2
  }*/
  const requestUrl = '/v1/specialties';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};


/* Groups */
export const getGroups = () => {
  const requestUrl = '/v1/groups';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};


/* Education Dictionaries */
export const getEducationCategories = () => {
  const requestUrl = '/v1/education/categories';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getEducationDegrees = () => {
  const requestUrl = '/v1/education/degrees';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getEducationForms = () => {
  const requestUrl = '/v1/education/forms';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getEducationFoundation = () => {
  const requestUrl = '/v1/education/foundation';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getEducationLevels = () => {
  const requestUrl = '/v1/education/levels';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const getEducationScholarshipTypes = () => {
  const requestUrl = '/v1/education/scholarship-types';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};


/* Residence */
export const getCountries = (obj) => {
  const requestUrl = '/v1/countries';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
    data: obj,
  });
};

export const searchRegions = (obj) => {
  /*{
   "query": "том",
   "limit": 3
   }*/

  const requestUrl = '/v1/regions';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const searchCities = (obj) => {
  /*{
    "region_id": "17970000000000",
      "query": "том",
      "limit": 2
  }*/

  const requestUrl = '/v1/cities';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

/* Filters */
export const getFilters = () => {
  const requestUrl = '/v1/filters';
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const createFilter = (obj = {}) => {
  /*{
    'id': 1,
    'title': 'Повышенная стипендия',
    'created_at': 1607375094,
    'updated_at': 1607375094,
    'expressions': [
      {
        'section_id': 0,
        'subsection_id': 4,
        'accomplishment_id': 19,
        'points': 125.5,
      },
      {
        'section_id': 0,
        'subsection_id': 4,
        'accomplishment_id': 152,
        'points': null,
      },
      {
        'section_id': 0,
        'subsection_id': 4,
        'accomplishment_id': 22,
        'points': 50,
      },
    ],
  };*/
  const requestUrl = '/v1/filters';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const getFilter = (id) => {
  const requestUrl = '/v1/filters/' + id;
  return axiosWrapper({
    method: 'get',
    url: requestUrl,
  });
};

export const updateFilter = (id, obj = {}) => {
  /*{
    "id": 1,
    "title": "Повышенная стипендия",
    "created_at": 1607375094,
    "updated_at": 1607375094,
    "expressions": [
      {
        "section_id": 0,
        "subsection_id": 4,
        "accomplishment_id": 19,
        "points": 125.5
      },
      {
        "section_id": 0,
        "subsection_id": 4,
        "accomplishment_id": 152,
        "points": null
      },
      {
        "section_id": 0,
        "subsection_id": 4,
        "accomplishment_id": 22,
        "points": 50
      }
    ]
  }*/

  const requestUrl = '/v1/filters/' + id;
  return axiosWrapper({
    method: 'put',
    url: requestUrl,
    data: obj,
  });
};

export const deleteFilter = (id) => {
  const requestUrl = '/v1/filters/' + id;
  return axiosWrapper({
    method: 'delete',
    url: requestUrl,
  });
};

export const shareFilter = (id, obj = {}) => {
  const requestUrl = '/v1/filters/' + id + '/share';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};


/*Dashboard methods*/
/*global{
 date_from	integer($int64)
 date_to	integer($int64)
 graduation_years:	[...]
 education_levels_ids:	[...]
 education_forms_ids:	[...]
 departments_ids:	[...]
 specialties_ids:	[...]
 courses_ids:	[...]
 terms_ids:	[...]
 accomplishments_ids: [...]
 }*/
export const calcDashboardAbsoluteAcademicQuality = (obj) => {
  const requestUrl = '/v1/dashboard/absolute-academic-quality';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const calcDashboardAverageScore = (obj) => {
  const requestUrl = '/v1/dashboard/average-score';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const calcDashboardEducationQuality = (obj) => {
  const requestUrl = '/v1/dashboard/education-quality';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const calcDashboardEnglishCertificatesStudents = (obj) => {
  const requestUrl = '/v1/dashboard/english-certificates-students';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
}

export const calcDashboardForeignStudents = (obj) => {

  /*citizenship_type = [ cis, other ]*/
  const requestUrl = '/v1/dashboard/foreign-students';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const calcDashboardGraduates = (obj) => {
  const requestUrl = '/v1/dashboard/graduates';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};

export const calcDashboardPublications = (obj) => {
  const requestUrl = '/v1/dashboard/publications';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};
export const calcDashboardScholarships = (obj) => {
  const requestUrl = '/v1/dashboard/scholarships';
  return axiosWrapper({
    method: 'post',
    url: requestUrl,
    data: obj,
  });
};


/* Common requesta*/
export const createPost = (urlTo, obj) => {
  return axiosWrapper({
    method: 'post',
    url: urlTo,
    data: obj,
  });
};

export const createGet = (urlTo) => {
  return axiosWrapper({
    method: 'get',
    url: urlTo,
  });
};
