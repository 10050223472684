import React, {Component} from 'react';
import {connect} from 'react-redux';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import {Empty, Skeleton} from 'antd';
import 'moment/locale/ru';

import {
  searchProfile,
  getSections,
  findDepartments,
  findSpecialities,
  getGroups,
  getEducationCategories,
  getEducationDegrees,
  getEducationForms,
  exportProfileResults,
  getEducationFoundation,
  getEducationLevels,
  getEducationScholarshipTypes,
  getCountries,
  searchRegions,
  searchCities,
  getFilter,
  shareProfilesSearch,
  getSharedProfilesSearch,
} from '../../services/apiRequests';
import {courses,terms, SITE_HOST_URL} from '../../modules/constants';
import {authService} from '../../services/auth';
import {refreshPage} from '../../modules/helpers';

import alertNotice from '../../components/alertNotice/alertNotice';
import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import SelectInput from '../../components/SelectInput/SelectInput';
const {Option} = SelectInput;
import NumericInput from '../../components/NumericInput/NumericInput';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import SmartFilterItem from '../../components/SmartFilterItem/SmartFilterItem';
import LeftPanelManager
  from '../../components/LeftPanelManager/LeftPanelManager';
import RightPanelManager
  from '../../components/RightPanelManager/RightPanelManager';
import Popconfirm from '../../components/Popconfirm/Popconfirm';
import GetIcon from '../../components/GetIcon/GetIcon';
import Loader from '../../components/Loader/Loader';
import CardCols, {CardCol} from '../../components/Card/CardCols';

let searchTimer;

class ManagerRatingPage extends Component {
  constructor(props) {
    super();

    const query = new URLSearchParams(props.location.search);

    this.state = {
      currentDomain: window ? window.location.protocol + '//' + window.location.host : SITE_HOST_URL,

      searchedUsersList: [],
      searchOptions: {},
      currentPage: 1,
      isUsersLoading: true,
      isApplyFilterLoading: true,
      isLoadMoreLoading: true,
      isLoadMoreNoResults: false,
      isExportSearchLoading: false,

      isFilterOpen: true,

      sectionsAndSubsectionsList: null,
      departmentsList: null,
      specialitiesList: null,
      groupsList: null,
      educationCategoriesList: null,
      educationDegreesList: null,
      educationFormsList: null,
      educationFoundationList: null,
      educationLevelsList: null,
      educationScholarshipTypesList: null,
      countriesList: null,
      regionsList: null,
      citiesList: null,

      sectionsItems: [],
      subsectionBySectionItems: [],

      selectedEducationCategories: [],
      selectedCourses: [],
      selectedTerms: [],
      selectedSection: null,
      selectedSubsections: [],
      selectedDepartments: [],
      selectedSpecialities: [],
      selectedGroups: [],
      selectedEducationDegrees: [],
      selectedEducationForms: [],
      selectedEducationFoundation: [],
      selectedEducationLevels: [],
      selectedEducationScholarshipTypes: [],
      selectedCountries: [],
      selectedRegions: [],
      selectedCities: [],
      selectedDateFrom: null,
      selectedDateTo: null,

      averageScoreInput: '',

      query,

      querySearchId: parseInt(query.get('searchId'), 10) ?
          parseInt(query.get('searchId'), 10) :
          null,
      selectedSearch: null,
      selectedSearchId: null,
      lastCreatedSearchId: null,
      lastCreatedSearchLink: '',
      hasSearchChanges: false,

      queryFilterId: parseInt(query.get('filterId'), 10) ?
          parseInt(query.get('filterId'), 10) :
          null,
      selectedFilter: null,
      selectedFilterId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /*refresh page if we want reset filterId or searchId
    by clicking on link "Rating" by left panel*/
    if (this.props.location.search !== prevProps.location.search) {
      refreshPage();
    }
  }
  
  componentDidMount() {
    const {querySearchId, queryFilterId} = this.state;

    /*Promises all must be resolved for continue*/
    this.initFormInputsEvent().then(async (resp)=>{
      /*if we have query get parameter of search
      we must preload this parameters to inputs*/
      if (querySearchId) {
        this.getSharedSearchEvent(querySearchId).then(async (resp) => {
          if (resp.status >= 200 && resp.status < 300) {
            let searchParams = resp.data;

            let {
              currentDomain,

              departmentsList,
              groupsList,
              educationCategoriesList,
              educationFormsList,
              educationFoundationList,
              educationLevelsList,
              educationScholarshipTypesList,
              countriesList,
              regionsList,

              sectionsItems,
              subsectionBySectionItems,
            } = this.state;

            const average_score = searchParams['average_score'];
            const categories_ids = searchParams['categories_ids'];
            const cities_ids = searchParams['cities_ids'];
            const citizenship_countries_ids = searchParams['citizenship_countries_ids'];
            const citizenship_types = searchParams['citizenship_types'];
            const countries_ids = searchParams['countries_ids'];
            const courses_ids = searchParams['courses_ids'];
            const date_from = searchParams['date_from'];
            const date_to = searchParams['date_to'];
            const departments_ids = searchParams['departments_ids'];
            const education_forms_ids = searchParams['education_forms_ids'];
            const education_foundation_ids = searchParams['education_foundation_ids'];
            const education_levels_ids = searchParams['education_levels_ids'];
            const filters_ids = searchParams['filters_ids'];
            const groups_ids = searchParams['groups_ids'];
            const points_accomplishments_ids = searchParams['points_accomplishments_ids'];
            const points_sections_ids = searchParams['points_sections_ids'];
            const regions_ids = searchParams['regions_ids'];
            const scholarship_types_ids = searchParams['scholarship_types_ids'];
            const specialties_ids = searchParams['specialties_ids'];
            const terms_ids = searchParams['terms_ids'];

            let selectedSubsections = [];
            const selectedSection = this.getItemsByIdsFromItems(points_sections_ids, sectionsItems);
            if (selectedSection && selectedSection[0]) {
              selectedSubsections = this.getItemsByIdsFromItems(points_accomplishments_ids, subsectionBySectionItems[selectedSection[0].value]);
            }

            const selectedEducationFoundation = this.getItemsByIdsFromList(education_foundation_ids, educationFoundationList);

            const selectedEducationCategories = this.getItemsByIdsFromList(categories_ids, educationCategoriesList);
            const selectedDepartments = this.getItemsByIdsFromList(departments_ids, departmentsList);
            const selectedGroups = this.getItemsByIdsFromList(groups_ids, groupsList);
            const selectedEducationForms = this.getItemsByIdsFromList(education_forms_ids, educationFormsList);
            const selectedEducationLevels = this.getItemsByIdsFromList(education_levels_ids, educationLevelsList);
            const selectedEducationScholarshipTypes = this.getItemsByIdsFromList(scholarship_types_ids, educationScholarshipTypesList);
            const selectedCountries = this.getItemsByIdsFromList(citizenship_countries_ids, countriesList);
            const selectedRegions = this.getItemsByIdsFromList(regions_ids, regionsList);

            let selectedCities = [];
            let citiesList = [];
            /*Getting cities only if we have regions*/
            if (cities_ids.length && regions_ids.length) {
              citiesList = await searchCities({
                'regions_ids': regions_ids,
                'limit': 100
              }).then((resp)=>{
                if (resp.status >= 200 && resp.status < 300) {
                  this.setState({citiesList: resp.data});

                  return resp.data;
                } else {
                  return [];
                }
              });

              selectedCities = this.getItemsByIdsFromList(cities_ids, citiesList);
            }

            /*momentjs object used in datepicker like input value*/
            let selectedDateFrom = date_from ? moment.unix(date_from) : null;
            let selectedDateTo = date_to ? moment.unix(date_to) : null;

            let selectedFilterId = null;
            if (filters_ids.length) {
              selectedFilterId = filters_ids[0]
              await this.getFilterEvent(filters_ids[0]);
            }
            const lastCreatedSearchLink = `${currentDomain}/manager/rating?searchId=${querySearchId}`;

            this.setState({
              selectedFilterId,
              selectedSearchId: querySearchId,
              selectedSearch: resp.data,
              lastCreatedSearchLink,
              hasSearchChanges: false,

              selectedSection: selectedSection[0] ?? null,
              selectedSubsections,
              selectedEducationCategories,
              selectedCourses: this.getItemsByIds(courses_ids),
              selectedTerms: this.getItemsByIds(terms_ids),
              selectedDepartments,
              selectedGroups,
              selectedEducationForms,
              selectedEducationFoundation,
              selectedEducationLevels,
              selectedEducationScholarshipTypes,
              selectedCountries,
              selectedRegions,
              selectedCities,
              selectedDateFrom,
              selectedDateTo,
              averageScoreInput: average_score ?? '',
            }, ()=>{
              this.searchProfileEvent(this.buildSearchOptions()).then(() => {
                this.setState({
                  isLoadMoreLoading: false,
                  isApplyFilterLoading: false,
                });
              });
            });
          }
        });
      }
      /*else if we don't have SearchId but have FilterId,
      we need apply filter - try-catch check filter existing*/
      else if (queryFilterId) {
        try {
          await this.getFilterEvent(queryFilterId);
        } catch(err) {
          console.log(err);
        }

        /*get users profiles with filter or without them*/
        this.searchProfileEvent(this.buildSearchOptions()).then(() => {
          this.setState({
            hasSearchChanges: true,
            isLoadMoreLoading: false,
            isApplyFilterLoading: false,
          });
        });
      } else {
        /*get users profiles with default options (empty filter and other)*/
        this.searchProfileEvent(this.state.searchOptions).then(() => {
          this.setState(
              {
                hasSearchChanges: true,
                isLoadMoreLoading: false,
                isApplyFilterLoading: false,
              });
        });
      }
    });
  }

  /*Handlers*/
  handleFilterHideClick = (value, name) => {
    this.setState({isFilterOpen: !this.state.isFilterOpen});
  }

  handleSectionSelectChange = (value, name) => {
    this.setState({
      [name]: value ?? null,
    });
  }

  handleSubsectionSelectChange = (value, name) => {
    this.setState({
      [name]: value ?? null,
    });
  };

  handleSingleSelectChange = (value, name) => {
    this.setState({[name]: value});
  }

  handleMultiSelectChange = (values, name) => {
    this.setState({
      [name]: values,
    });
  };

  /* If need find regions from API*/
  handleRegionSearchChange = (value, name) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
      searchTimer = null;
    }
    if (value && value.length >= 3) {
      searchTimer = setTimeout(()=>{
        searchRegions({
          query: value.trim(),
          limit: 100
        }).then((resp)=>{
          if (resp.status >= 200 && resp.status < 300) {
            this.setState({regionsList: resp.data});
          }
        });
      }, 500);
    }
  }

  /* If need find cities from API*/
  handleCitySearchChange = (value, name) => {
    /*if (searchTimer) {
     clearTimeout(searchTimer);
     searchTimer = null;
     }

     if (value && value.length >=3) {
     searchTimer = setTimeout(()=>{
     searchCities({
     region_id: this.state.selectedRegions.value || '',
     query: value.trim(),
     limit: 100
     }).then((resp)=>{
     if (resp.status >= 200 && resp.status < 300) {
     this.setState({citiesList: resp.data});
     }
     });
     }, 500);
     }*/
  }

  handleRegionSelectChange = (values, name) => {
    this.setState({
      selectedRegions: values,
      citiesList: [],
      selectedCities: [],
    });

    if (searchTimer) {
      clearTimeout(searchTimer);
      searchTimer = null;
    }

    if (values) {
      searchTimer = setTimeout(()=>{

        searchCities({
          'regions_ids': this.getIdsFromList(values),
          'limit': 100
        }).then((resp)=>{
          if (resp.status >= 200 && resp.status < 300) {
            this.setState({citiesList: resp.data});
          }
        });
      }, 1000);
    }
  }

  handleCitySelectChange = (value, name) => {
    this.setState({
      selectedCities: value,
    });

    /*searchCities().then((resp)=>{
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({citiesList: resp.data});
      }
    });*/
  }

  handleLoadMoreClick = () => {
    let nextPage = this.state.currentPage + 1;
    this.setState({isLoadMoreLoading: true});

    this.searchProfileEvent(this.state.searchOptions, nextPage).then(() => {
      this.setState({currentPage: nextPage, isLoadMoreLoading: false});
    });
  };

  handleNumericChange = (value)=>{
    this.setState({
      averageScoreInput: value,
    });
  }

  handleFromPeriodChange = (date, dateString) => {
    this.setState({
      selectedDateFrom: date,
    });
  };

  handleToPeriodChange = (date, dateString) => {
    this.setState({
      selectedDateTo: date,
    });
  };

  handleApplyFilterClick = () => {
    let searchOptions = this.buildSearchOptions();

    this.setState({
      searchedUsersList: [],
      searchOptions,
      isUsersLoading: true,
      isLoadMoreLoading: true,
      isApplyFilterLoading: true,
    });

    this.searchProfileEvent(searchOptions).then(() => {
      this.setState({
        currentPage: 1,
        isUsersLoading: false,
        isLoadMoreLoading: false,
        isApplyFilterLoading: false,
        hasSearchChanges: true,
      });
    });
  };

  handleExportSearchedUsersClick = () => {
    let searchOptions = this.buildSearchOptions();

    this.setState({
      isExportSearchLoading: true,
    });

    exportProfileResults(searchOptions).then((resp)=>{
      if (resp.status === 200) {
        this.setState({
          isExportSearchLoading: false,
        });
        // BE endpoint sends a readable stream of bytes
        const byteArray = new Uint8Array(resp.data);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(
            new Blob([byteArray], {
              type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
        );
        a.download = `profile-results.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }).catch((err)=>{
      if (err.response) {
        alertNotice.error(err.response.status + ' ' + err.response.statusText || err.message);
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        alertNotice.error(err.request);
        console.log(err.request);
      } else {
        alertNotice.error('Error' + ' ' + err.message);
        console.log('Error', err.message);
      }
      console.log(err.config);
    })
  };

  handleShareFilterClick = () => {
    let searchOptions = this.buildSearchOptions();

    if (this.state.hasSearchChanges) {
      shareProfilesSearch(searchOptions).then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {

          let respSplitString = resp.data.link.split('/');
          let lastCreatedSearchId = respSplitString[respSplitString.length-1];

          const lastCreatedSearchLink = `${this.state.currentDomain}/manager/rating?searchId=${lastCreatedSearchId}`;
          this.setState({
            lastCreatedSearchLink,
            lastCreatedSearchId,
            hasSearchChanges: false
          });

          copy(lastCreatedSearchLink);

          //can be deleted later
          this.getSharedSearchEvent(lastCreatedSearchId);
        }
      });
    } else {
      copy(this.state.lastCreatedSearchLink);
    }
  };

  handleConfirmNewTabSearchClick = () => {
    window.open(this.state.lastCreatedSearchLink, '_blank');
  };

  /*Events logic*/
  searchProfileEvent = (options, page = 1) => {
    const perPage = 50;

    options.pagination =  {
      page: page,
      per_page: perPage,
    }

    if (!options.filters_ids && this.state.selectedFilterId) {
      options.filters_ids = [this.state.selectedFilterId]
    }

    return searchProfile(options).then((resp) => {
      this.setState({isUsersLoading: false});

      if (resp.status >= 200 && resp.status < 300) {
        let searchedUsersListOld = this.state.searchedUsersList;
        let isLoadMoreNoResults = true;
        if (resp.data.length >= perPage) {
          isLoadMoreNoResults = false;
        }

        this.setState({isLoadMoreNoResults, searchedUsersList: searchedUsersListOld.concat(resp.data)});
      }
    }).catch((err)=>{
      if (err.response) {
        alertNotice.error(err.response.status + ' ' + err.response.statusText || err.message);
        console.log(err.response.data);
      } else if (err.request) {
        alertNotice.error(err.request);
        console.log(err.request);
      } else {
        alertNotice.error('Error' + ' ' + err.message);
        console.log('Error', err.message);
      }
      console.log(err.config);
    })
  };

  getFilterEvent = (id) => {
    return getFilter(id).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        let searchedUsersListOld = this.state.searchedUsersList;
        this.setState({
          selectedFilter: resp.data,
          selectedFilterId: resp.data.id,
        });
      }

      return resp;
    });
  };

  getSharedSearchEvent = (searchId)=>{
    return getSharedProfilesSearch(searchId);
  };

  initFormInputsEvent = () => {
    const promiseGetSections = getSections().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        const {sections} = resp.data;

        let sectionsItems = [];
        let subsectionBySectionItems = [];
        sectionsItems = sections.map((item, key) => {
          return  {
            value: item.id,
            label: item.name,
          }
        })

        subsectionBySectionItems = sections.map((item, key) => {
          return Object.keys(item['subsections']).map((key, index) => (
              {
                value: item['subsections'][key].id,
                label: item['subsections'][key].name,
              }
          ));
        })

        this.setState({
          sectionsItems,
          subsectionBySectionItems,
          sectionsAndSubsectionsList: sections
        });
      }
      return resp;
    });

    const promiseFindDepartments = findDepartments().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({departmentsList: resp.data});
      }
      return resp;
    });

    const promiseFindSpecialities = findSpecialities().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({specialitiesList: resp.data});
      }

      return resp;
    });
    const promiseGetEducationDegrees = getEducationDegrees().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationDegreesList: resp.data});
      }

      return resp;
    });

    const promiseGetGroups = getGroups().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({groupsList: resp.data});
      }

      return resp;
    });

    const promiseGetEducationCategories = getEducationCategories().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationCategoriesList: resp.data});
      }

      return resp;
    });

    const promiseGetEducationForms = getEducationForms().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationFormsList: resp.data});
      }

      return resp;
    });

    const promiseGetEducationFoundation = getEducationFoundation().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationFoundationList: resp.data});
      }

      return resp;
    });

    const promiseGetEducationScholarshipTypes = getEducationScholarshipTypes().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationScholarshipTypesList: resp.data});
      }

      return resp;
    });

    const promiseGetEducationLevels = getEducationLevels().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({educationLevelsList: resp.data});
      }

      return resp;
    });

    const promiseGetCountries = getCountries().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({countriesList: resp.data});
      }

      return resp;
    });

    const promiseSearchRegions = searchRegions().then((resp)=>{
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({regionsList: resp.data});
      }

      return resp;
    });

    return Promise.all([
      promiseGetSections,
      promiseFindDepartments,
      /*promiseFindSpecialities,
      promiseGetEducationDegrees,*/
      promiseGetGroups,
      promiseGetEducationCategories,
      promiseGetEducationForms,
      promiseGetEducationFoundation,
      promiseGetEducationScholarshipTypes,
      promiseGetEducationLevels,
      promiseGetCountries,
      promiseSearchRegions]);
  };

  /*Helpers*/
  buildSearchOptions = () => {
    let {
      selectedSection,
      selectedSubsections,
      selectedEducationCategories,
      selectedCourses,
      selectedTerms,
      selectedDepartments,
      selectedGroups,
      selectedEducationForms,
      selectedEducationFoundation,
      selectedEducationLevels,
      selectedEducationScholarshipTypes,
      selectedCountries,
      selectedRegions,
      selectedCities,
      selectedDateFrom,
      selectedDateTo,
      averageScoreInput,
      selectedFilterId,
      currentPage,
    } = this.state;

    return {
      filters_ids: selectedFilterId !== null ? [selectedFilterId] : [],
      points_sections_ids: selectedSection !== null ?
          [selectedSection.value] : [],
      points_accomplishments_ids: this.getIdsFromList(selectedSubsections),
      education_levels_ids: this.getIdsFromList(selectedEducationLevels),
      education_forms_ids: this.getIdsFromList(selectedEducationForms),
      education_foundation_ids: this.getIdsFromList(
          selectedEducationFoundation),
      departments_ids: this.getIdsFromList(selectedDepartments),
      categories_ids: this.getIdsFromList(selectedEducationCategories),
      //specialties_ids: this.getIdsFromList(aaaaaaa),
      groups_ids: this.getIdsFromList(selectedGroups),
      courses_ids: this.getIdsFromList(selectedCourses),
      terms_ids: this.getIdsFromList(selectedTerms),
      average_score: averageScoreInput ? parseFloat(averageScoreInput) : null,
      scholarship_types_ids: this.getIdsFromList(
          selectedEducationScholarshipTypes),
      //citizenship_types: this.getIdsFromList(),
      //countries_ids: selectedCountries,
      citizenship_countries_ids: this.getIdsFromList(selectedCountries),
      regions_ids: this.getIdsFromList(selectedRegions),
      cities_ids: this.getIdsFromList(selectedCities),
      date_from: selectedDateFrom ? selectedDateFrom.unix() : null,
      date_to: selectedDateTo ? selectedDateTo.unix() : null,
    };
  }

  getIdsFromList = (list) => {
    return list.map((item) => (
        item['value'] ?? item['value']
    ));
  };

  getItemsByIdsFromList = (ids = [], list) => {
    if (!ids) {
      return [];
    }

    return ids.map((id) => (
        {
          value: list[id].id,
          label: list[id].full_name || list[id].name,
        }
    ));
  };

  getItemsByIdsFromItems = (ids = [], items = []) => {
    if (!ids) {
      return [];
    }

    if (!items) {
      return [];
    }

    return items.filter((item) => ids.indexOf(item.value) !== -1);
  };

  getItemsByIds = (ids = []) => {
    if (!ids) {
      return [];
    }

    return ids.map((id) => (
        {
          value: id,
          label: id,
        }
    ));
  };

  /*View logic*/
  buildOptionItems = (itemsObj) => {
    if (itemsObj && Object.keys(itemsObj).length > 0) {
      return Object.keys(itemsObj).map((key, index)=>(
          {
            value: itemsObj[key].id,
            label: itemsObj[key].full_name || itemsObj[key].name,
          }
      ));
    }

    return [];
  }

  render() {
    const {
      lastCreatedSearchLink,

      searchedUsersList, isUsersLoading,
      isLoadMoreLoading, isLoadMoreNoResults, isExportSearchLoading,
      isApplyFilterLoading, isFilterOpen,
      departmentsList,
      groupsList,
      educationCategoriesList,
      educationFormsList,
      educationFoundationList,
      educationLevelsList,
      educationScholarshipTypesList,
      countriesList,
      regionsList,
      citiesList,

      sectionsItems,
      subsectionBySectionItems,

      averageScoreInput,
      selectedSection,
      selectedSubsections,
      selectedEducationCategories,
      selectedCourses,
      selectedTerms,
      selectedDepartments,
      selectedGroups,
      selectedEducationForms,
      selectedEducationFoundation,
      selectedEducationLevels,
      selectedEducationScholarshipTypes,
      selectedCountries,
      selectedRegions,
      selectedCities,
      selectedDateFrom,
      selectedDateTo,

      selectedSearchId,
      selectedFilter,
    } = this.state;

    let rows = [];
    if (searchedUsersList.length > 0) {
      let token = authService.getUserToken();
      rows = searchedUsersList.map((item, key)=>{
        return <li
            key={item.id}
            className="search-users-item centered unordered-item">
          <div className="item-number">
            {key + 1}
          </div>
          <div className="search-user-photo">
            <a target="_blank" href={'/manager/view-profile/personal?pid=' + item.id} className="link-off">
              {
                item.personal && item.personal.photo_url ?
                    <img src={item.personal.photo_url + (token ?
                        '?access_token=' + token :
                        '')} alt={item.personal.last_name}/> :
                    <GetIcon name="icon-user"/>
              }
            </a>
          </div>
          <div className="search-user-short-info">
            <a target="_blank" href={'/manager/view-profile/personal?pid=' + item.id} className="link-off">
              <div className="search-user-name">
                {`${item.personal.last_name} ${item.personal.first_name}  ${item.personal.middle_name.length > 1 ? item.personal.middle_name : ''}`}
              </div>
              <div className="search-user-rang">
                {item.category.full_name}
              </div>
              <div className="search-user-from">
                {item.education.department.full_name}
              </div>
            </a>
          </div>
          <div className="search-user-points">
            {parseInt(item.rating.points_total, 10)} б.
          </div>
        </li>
      })
    }

    let departmentsItems = this.buildOptionItems(departmentsList);
    let groupsItems = this.buildOptionItems(groupsList);
    let educationFormsItems = this.buildOptionItems(educationFormsList);
    let educationFoundationItems = this.buildOptionItems(educationFoundationList);
    let educationLevelsItems = this.buildOptionItems(educationLevelsList);
    let educationScholarshipTypesItems = this.buildOptionItems(educationScholarshipTypesList);
    let countriesItems = this.buildOptionItems(countriesList);
    let regionsItems = this.buildOptionItems(regionsList);
    let citiesItems = this.buildOptionItems(citiesList);
    let educationCategoryItems = this.buildOptionItems(educationCategoriesList);

    return (
        <LayoutMain>
          <PageContent>
            <LeftPanelManager/>

            <RightPanelManager
                header="Рейтинг">
              {
                selectedFilter ?
                    <Card
                        key={selectedFilter.id}
                        header="Примененные фильтры"
                        hasHover={false}
                        minHeight={false}>
                      <SmartFilterItem
                          header={selectedFilter.title}
                          hideActions={true}
                          filterId={selectedFilter.id}
                          filter={selectedFilter}
                          textDate={moment.unix(selectedFilter.created_at).format('DD.MM.YYYY hh:mm')}/>
                    </Card> : null
              }

              <Card hasHover={false}
                    isCollapsible={true}
                    isCollapseOpened={isFilterOpen}
                    collapsibleCb={this.handleFilterHideClick}
                    size="auto"
                    header="Добавить ограничение"
                    minHeight={false}>
                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        hasSearch
                        onSelectChange={this.handleSectionSelectChange}
                        fieldLabel="Вид деятельности"
                        name="selectedSection"
                        value={selectedSection}
                        items={sectionsItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleSubsectionSelectChange}
                        fieldLabel="Показатели"
                        name="selectedSubsections"
                        value={selectedSubsections}
                        items={subsectionBySectionItems[selectedSection ? selectedSection.value : 0]}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Категория пользователей"
                        name="selectedEducationCategories"
                        value={selectedEducationCategories}
                        items={educationCategoryItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Уровень подготовки"
                        name="selectedEducationLevels"
                        value={selectedEducationLevels}
                        items={educationLevelsItems}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Форма обучения"
                        name="selectedEducationForms"
                        value={selectedEducationForms}
                        items={educationFormsItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Основа обучения"
                        name="selectedEducationFoundation"
                        value={selectedEducationFoundation}
                        items={educationFoundationItems}/>
                  </CardCol>
                  {/*<CardCol>
                    <SelectInput
                        isMulti
                        allowClear={true}
                        name="Наличие договора"
                        items={[
                          {
                            value: 'Договорная',
                            label: 'Договорная',
                          },
                          {
                            value: 'Бюджетная',
                            label: 'Бюджетная',
                          },
                          {
                            value: 'Целевая',
                            label: 'Целевая',
                          },
                        ]}/>
                  </CardCol>*/}
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Школа"
                        name="selectedDepartments"
                        value={selectedDepartments}
                        items={departmentsItems}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Группа"
                        name="selectedGroups"
                        value={selectedGroups}
                        items={groupsItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Стипендиат"
                        name="selectedEducationScholarshipTypes"
                        value={selectedEducationScholarshipTypes}
                        items={educationScholarshipTypesItems}/>
                  </CardCol>
                  <CardCol>
                    <NumericInput
                        fieldLabel="Средний балл не менее"
                        min={2}
                        max={5}
                        value={averageScoreInput}
                        onChange={this.handleNumericChange}/>
                    {/*<SelectInput
                        isMulti
                        name="Средний балл не менее"
                        items={[
                          {
                            value: '1',
                            label: '1',
                          },
                          {
                            value: '2',
                            label: '2',
                          },
                          {
                            value: '3',
                            label: '3',
                          },
                          {
                            value: '4',
                            label: '4',
                          },
                          {
                            value: '5',
                            label: '5',
                          },
                        ]}/>*/}
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleMultiSelectChange}
                        dropdownClassName="text-transform-capitalize"
                        fieldLabel="Гражданство"
                        name="selectedCountries"
                        value={selectedCountries}
                        items={countriesItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleRegionSelectChange}
                        /*onSearchChange={this.handleRegionSearchChange}*/
                        fieldLabel="Область"
                        name="selectedRegions"
                        value={selectedRegions}
                        items={regionsItems}/>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        hasSearch
                        onSelectChange={this.handleCitySelectChange}
                        notFoundContent="Сперва выберите области"
                        /*onSearchChange={this.handleCitySearchChange}*/
                        fieldLabel="Город"
                        name="selectedCities"
                        value={selectedCities}
                        items={citiesItems}/>
                  </CardCol>
                </CardCols>

                <CardCols className="mb20">
                  <CardCol>
                    <SelectInput
                        isMulti
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Курс"
                        name="selectedCourses"
                        value={selectedCourses}>
                      {courses.map(course => (
                          <Option key={course} value={course}>{course}</Option>
                      ))}
                    </SelectInput>
                  </CardCol>
                  <CardCol>
                    <SelectInput
                        isMulti
                        onSelectChange={this.handleMultiSelectChange}
                        fieldLabel="Семестр"
                        name="selectedTerms"
                        value={selectedTerms}>
                      {terms.length > 0 ? terms.map(term => (
                          <Option key={term} value={term}>{term}</Option>
                      )) : null}
                    </SelectInput>
                  </CardCol>
                  <CardCol>
                    <DatePickerInput
                        value={selectedDateFrom}
                        fieldLabel="С периода"
                        onChange={this.handleFromPeriodChange} />
                  </CardCol>
                  <CardCol>
                    <DatePickerInput
                        value={selectedDateTo}
                        fieldLabel="По период"
                        onChange={this.handleToPeriodChange} />
                  </CardCol>
                </CardCols>

                <div className="card-inner-bottom-actions mt20">
                  <button
                      disabled={isApplyFilterLoading}
                      onClick={this.handleApplyFilterClick}
                      className={'btn btn-green btn-sm action-save' + (isApplyFilterLoading ? ' disabled' : '')}>
                    Применить
                  </button>
                  <button
                      onClick={this.handleFilterHideClick}
                      className={'btn-link'}>
                    Свернуть фильтр
                  </button>
                </div>
              </Card>

              <div className="right-panel-inner-actions">
                <div className="inner-actions-header">Список студентов</div>
                <div className="inner-actions-container">
                  <Popconfirm title={<span>Ссылка скопирована в буфер обмена.
                    <br/>Открыть в новой вкладке?
                    <br/><pre>{lastCreatedSearchLink}</pre></span>}
                              onClick={this.handleShareFilterClick}
                              onConfirm={this.handleConfirmNewTabSearchClick}>
                    <button className="btn-link">
                      <GetIcon name="icon-chain"/> Сформировать ссылку
                    </button>
                  </Popconfirm>
                  <button disabled={isExportSearchLoading}
                          className={'btn-link' + (isExportSearchLoading ? ' disabled' : '')}
                          onClick={this.handleExportSearchedUsersClick}>
                    {isExportSearchLoading ?
                        <Loader/> :
                        <GetIcon name="icon-download"/>} Выгрузить список
                  </button>
                </div>
              </div>
              <Card hasHover={false}
                    size="auto"
                    minHeight={false}>

                {rows.length > 0 ?
                    <ul className="card-unordered-list search-users-list">
                      {rows}
                    </ul>
                    : (isUsersLoading ? <Skeleton
                            title={false}
                            paragraph={{
                              rows: 3,
                            }}
                            active/> :
                        <Empty description="Пользователей не найдено"/>)
                }

                {!isLoadMoreNoResults ? <div className="card-inner-bottom-actions mt20">
                  <button disabled={isLoadMoreLoading}
                          onClick={this.handleLoadMoreClick}
                          className={'btn btn-white btn-md action-save' + (isLoadMoreLoading ? ' disabled' : '') }>
                    Показать ещё
                  </button>
                </div> : null}
              </Card>

            </RightPanelManager>
          </PageContent>
        </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sections: state.sectionLeftPanel.sections
  };
}

export default connect(mapStateToProps, null)(ManagerRatingPage);
