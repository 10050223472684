import React, {Component} from 'react';
import {Switch} from 'antd';

import './SwitchInput.scss';

class SwitchInput extends Component {
  handleChange = (value) => {
    if (typeof this.props.onSwitchChange === 'function') {
      this.props.onSwitchChange(value, this.props.name);
    }
  }

  render() {
    const {value, fieldLabel, name, placeholder, defaultValue} = this.props;

    const finalProps ={};

    if (placeholder) {
      finalProps.placeholder = placeholder;
    }

    if (defaultValue) {
      finalProps.defaultChecked = defaultValue;
    }

    if (value) {
      finalProps.checked = value
    }

    if (name) {
      finalProps.name = name
    }

    return (
          <div className="switch-field">
            {fieldLabel ? <div className="field-label">{fieldLabel}</div> : null}
            <Switch
                {...finalProps}
                onChange={this.handleChange}>{fieldLabel}</Switch>
          </div>
    );
  }
}

export default SwitchInput;