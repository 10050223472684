import React, {Component} from 'react';
import {NavLink, Link, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';


import './LeftPanelStudent.scss';

import {idToName} from '../../modules/constants';
import {authService} from '../../services/auth';
import {toggleMobileMenuAction, toggleMobileUserPointsAction, profileLogoutAction} from '../../redux/actions';

import Card from '../../components/Card/Card';
import GetIcon from '../GetIcon/GetIcon';
import SwitchInput from '../SwitchInput/SwitchInput';
import QRCodeBox from '../QRCodeBox/QRCodeBox';
import LeftPanel from '../LeftPanel/LeftPanel';
import {changeAuthProfileSettings} from '../../services/apiRequests';

let publishingTimer;

class LeftPanelStudent extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      redirectUrl: '/login',
      isShowedQr: false
    };

    this.qrCanvasRef = React.createRef();
  }

  handleClickLogout = (e) => {
    e.preventDefault();
    authService.logout();
    this.props.profileLogoutAction();

    this.setState({
      redirect: true,
    });
  };

  handleClickUserPointsTrigger = () => {
    this.props.toggleMobileUserPointsAction();
  }

  handleClickMenuToggle = (e) => {
    this.props.toggleMobileMenuAction();
  };

  handlePublicProfileClick = (value, name) => {

    if (publishingTimer) {
      clearTimeout(publishingTimer);
      publishingTimer = null;
    }

    publishingTimer = setTimeout(()=>{
      changeAuthProfileSettings({'is_public': value}).then(() => {
            this.setState({
              isShowedQr: value,
            });
          },
      );
    }, 2000);
  };

  handleDownloadQrClick = (e) => {
    e.preventDefault();
    const qrCanvasRef = this.qrCanvasRef;
    const canvas = qrCanvasRef.current.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').
        replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'student-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  componentDidMount() {}

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl}/>;
    }

    const {isShowedQr} = this.state;
    const {mobileMenu, sections, profile, profilePhotoBase64, ratings, viewType} = this.props;

    const {ratingPersonal, ratingScience0, ratingStudy1, ratingSport2, ratingCreative3} = ratings;

    let partLinkSubsection = '/my';

    const isManagerView = viewType === 'manager';
    if (viewType === 'manager') {
      partLinkSubsection = '/manager/view-profile'
    }
    const isGuestView = viewType === 'guest';
    if (viewType === 'guest') {
      partLinkSubsection = '/student'
    }

    return (
        <LeftPanel>
          <div className="card-user-info card-round-shadow">
            <div className="user-photo-info">
              <div className="user-photo">
               {/*{profile && profile.personal.photo_url ?
                    <img src={profile.personal.photo_url} alt="Username"/> :
                    null}*/}
                {profilePhotoBase64 ?
                    <img src={profilePhotoBase64} alt="Username"/> :
                    <GetIcon name="icon-user"/>
                }
              </div>
              {profile ?
                  <div className="user-info">
                    <div className="user-info-name mobile">
                      {`${profile.personal.first_name ?? profile.personal.first_name} ${profile.personal.last_name ?? profile.personal.last_name}`}
                    </div>
                    <div className="user-info-location">
                      {profile.personal.citizenship.length ? profile.personal.citizenship[0].name : null}
                    </div>
                    <div className="user-info-education">
                      {profile.category.full_name ?? profile.category.full_name}
                    </div>
                  </div> : null
              }
            </div>

            <div className={`user-points ${mobileMenu.isPointsOpened ? 'is-opened' : ''}`}>
              <div className="user-points-trigger" onClick={this.handleClickUserPointsTrigger}>
                <GetIcon name="icon-triangle-down"/>
              </div>
              <div className={`user-all-points ${!mobileMenu.isPointsOpened ? 'hidden' : ''}`}>
                <div className={`points-wrapper points-personal ${!ratingPersonal ? 'disabled-points' : ''}`}>
                  <span className="points-icon"><GetIcon name="icon-user-2"/></span>
                  <span className="points-value">{ratingPersonal || '-'}</span>
                </div>
                <div className={`points-wrapper points-science ${!ratingScience0 ?
                    'disabled-points' : ''}`}>
                  <span className="points-icon"><GetIcon name="icon-flask"/></span>
                  <span className="points-value">{ratingScience0 || '-'}</span>
                </div>
                <div className={`points-wrapper points-study ${!ratingStudy1 ?
                    'disabled-points' : ''}`}>
                  <span className="points-icon"><GetIcon name="icon-academic-cap"/></span>
                  <span className="points-value">{ratingStudy1 || '-'}</span>
                </div>
                {/*<div className="points-wrapper points-social">
                  <span className="points-icon"><GetIcon name="icon-speaker"/></span>
                  <span className="points-value">{faker.random.number(900)}</span>
                </div>*/}
                <div className={`points-wrapper points-sport ${!ratingSport2 ?
                    'disabled-points' : ''}`}>
                  <span className="points-icon"><GetIcon name="icon-ball"/></span>
                  <span className="points-value">{ratingSport2 || '-'}</span>
                </div>
                <div className={`points-wrapper points-creative ${!ratingCreative3 ?
                    'disabled-points' : ''}`}>
                  <span className="points-icon"><GetIcon name="icon-palette"/></span>
                  <span className="points-value">{ratingCreative3 || '-'}</span>
                </div>
              </div>
            </div>
          </div>

          <div onClick={this.handleClickMenuToggle}
              className={`card-activities-menu ${mobileMenu.isMenuOpened ? 'is-opened' : ''} card-round-shadow mt24`}>
            <ul className="activities-menu-list">
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/personal/' +
                         this.props.location.search}>Личные данные</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/projects/' +
                         this.props.location.search}>Мои работы и проекты</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/record-book/' +
                         this.props.location.search}>Электронная зачётная книжка</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/science/' +
                         this.props.location.search}>Научная деятельность</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/study/' +
                         this.props.location.search}>Учебная деятельность</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/sport/' +
                         this.props.location.search}>Спортивная деятельность</NavLink>
              </li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/creative/' +
                         this.props.location.search}>Культурная деятельность</NavLink>
              </li>
              {/*{
                sections.map((item, key) => {
                  return <li key={key} className="activities-menu-item">
                    <NavLink className="item-link"
                             activeClassName="active"
                             to={`${partLinkSubsection}/${idToName.getSectionById(item.id).slug}` + this.props.location.search}>
                      {item.name}
                    </NavLink>
                  </li>;
                })
              }*/}
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to={partLinkSubsection + '/social/' +
                         this.props.location.search}>Общественная деятельность</NavLink>
              </li>
            </ul>
            {authService.isAuthenticated() ?
                <ul className="activities-menu-list activities-menu-list-mobile">
                  <li className="activities-menu-item">
                    <NavLink className="item-link"
                             activeClassName="active"
                             to={partLinkSubsection + '/qr'}>QR-код</NavLink>
                  </li>
                  {
                    authService.getUserRole() === 'manager' ?
                        <li className="activities-menu-item">
                          <a className="item-link" href="/manager">Управление</a>
                        </li> : null

                  }
                  <li className="activities-menu-item">
                    <Link className="item-link"
                          onClick={this.handleClickLogout}
                          to="/logout">Выйти</Link>
                  </li>
                </ul> : null}
          </div>

          {profile && !isManagerView && !isGuestView ?
              <div className="card-block-share qr-container mt24 card-round-shadow no-min-height">
                <div className="card-block-share-action">
                  <Tooltip placement="bottom"
                           trigger="hover"
                           title="При активации Ваше портфолио будет общедоступно по прямой ссылке!">
                    Открыть доступ к профилю <QuestionCircleOutlined/>
                  </Tooltip>

                  <SwitchInput
                      defaultValue={profile.is_public}
                      onSwitchChange={this.handlePublicProfileClick}/>
                </div>
                {isShowedQr || profile.is_public ?
                    <React.Fragment>
                      <div className="share-qr" ref={this.qrCanvasRef}>
                        <QRCodeBox size={266} text={'https://portfolio.tpu.ru/student/personal?pid=' +
                        profile.id}/>
                      </div>
                      <div className="share-actions">
                        <a className="action-share btn btn-green btn-sm"
                           href={'https://vk.com/share.php?url=https://portfolio.tpu.ru/student/personal?pid=' +
                           profile.id}
                           target="_blank">Поделиться</a>
                        <button onClick={this.handleDownloadQrClick} className="btn-link action-save">
                          Скачать
                        </button>
                      </div>
                    </React.Fragment> : null}
              </div>
          : null}
        </LeftPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileMenu: state.mobileMenu,
    sections: state.sectionLeftPanel.sections,
  };
}

const mapDispatchToProps = {
  toggleMobileMenuAction: toggleMobileMenuAction,
  toggleMobileUserPointsAction: toggleMobileUserPointsAction,
  profileLogoutAction: profileLogoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftPanelStudent));