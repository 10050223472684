import React, {Component} from 'react';
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

import './DatePickerInput.scss';

class DatePickerInput extends Component {
  handleChange = (value) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, this.props.name);
    }
  };

  render() {
    let {
      name,
      fieldLabel,
      className,
      disabled,
      allowClear,
      placeholder,
      defaultValue,
      value,
    } = this.props;

    if (!className) {
      className = '';
    }

    let finalProps = {};

    if (placeholder) {
      finalProps.placeholder = placeholder;
    }

    if (value) {
      finalProps.value = value;
    }

    if (typeof disabled === 'boolean') {
      finalProps.disabled = disabled;
    }

    if (typeof allowClear === 'boolean') {
      finalProps.allowClear = allowClear;
    }
    else {
      finalProps.allowClear = true;
    }

    if (name) {
      finalProps.name = name;
    }

    if (defaultValue) {
      finalProps.defaultValue = defaultValue;
    }

    return (
          <div className={`date-picker-field ${className}`}>
            <div className="field-label">{fieldLabel}</div>
            <DatePicker
                {...finalProps}
                locale={locale}
                onChange={this.handleChange}
                format="DD.MM.YYYY"/>
          </div>
    );
  }
}

export default DatePickerInput;