import {GET_SECTIONS} from './types';

const initialState = {
  sections: [],
};

export const sectionsStudentReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_SECTIONS:
      //return {...state, sections: state.sections.concat(action.payload)}
      return {...state, sections: action.payload }
    default: return state
  }
};