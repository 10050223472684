import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PersonalDataCard from '../../components/PersonalDataCard/PersonalDataCard';
import CardCols, {CardCol} from '../../components/Card/CardCols';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';

class StudentPersonalPage extends Component {
   render() {
    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <div className="page-header">
            <h4 className="headline-4">
              {profile ?
                  `${profile.personal.last_name} ${profile.personal.first_name} ${profile.personal.middle_name.length > 1 ? profile.personal.middle_name : ''}` :
                  <Skeleton.Input style={{ minWidth: 300 }} active/>
              }
            </h4>
          </div>

          <div className="page-content">
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>
            <RightPanelStudent header="Личные данные">
              <PersonalDataCard profile={profile}/>
            </RightPanelStudent>
          </div>
        </LayoutMain>
    );
  }
}

export default StudentPersonalPage;
