import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {Empty, Skeleton} from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import SmartFilterItem from '../../components/SmartFilterItem/SmartFilterItem';
import LeftPanelManager
  from '../../components/LeftPanelManager/LeftPanelManager';
import RightPanelManager
  from '../../components/RightPanelManager/RightPanelManager';
import GetIcon from '../../components/GetIcon/GetIcon';
import {authService} from '../../services/auth';
import {
  createFilter,
  deleteFilter,
  getFilters,
} from '../../services/apiRequests';

class ManagerFiltersPage extends Component {
  state = {
    filters: [],
    isFiltersLoading: true
  };

  handleDuplicateFilterClick = (filter) => {
    createFilter({
          title: filter.title + ' Копия',
          expressions: filter.expressions,
        }).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.getFiltersEvent();
      }
    });
  };

  getFiltersEvent = ()=>{
    getFilters().then((resp) => {
      this.setState({
        isFiltersLoading: false
      });
      if (resp.status === 200) {
        this.setState({
          filters: resp.data
        });
      }
    })
  }

  componentDidMount() {
   this.getFiltersEvent();
  }

  render() {
    const {filters, isFiltersLoading} = this.state;

    return (
        <LayoutMain>
          <div className="page-content">
            <LeftPanelManager/>

            <RightPanelManager
                header="Умный фильтр"
                headerActions={
                  <Link className="btn-link" to="/manager/filters/create">+ Создать правило фильтрации</Link>
                }>


              {(filters && filters.length > 0) ?
                filters.map((filter, key) => (
                    <Card
                        key={filter.id}
                        hasHover={false}
                        minHeight={false}>
                      <SmartFilterItem
                          header={filter.title}
                          onDuplicateFilterCick={this.handleDuplicateFilterClick}
                          filterId={filter.id}
                          filter={filter}
                          textDate={moment.unix(filter.created_at).format('DD.MM.YYYY hh:mm')}
                      />
                    </Card>
                )) :
                  <Card hasHover={false}
                        minHeight={false}>
                    {isFiltersLoading ?
                        <Skeleton
                            title={false}
                            paragraph={{
                              rows: 1,
                            }}
                            active/> :
                        <Empty description="Фильтров не найдено"/>}
                  </Card>
              }
            </RightPanelManager>
          </div>
        </LayoutMain>
    );
  }
}

export default ManagerFiltersPage;
