import React from 'react';

const CardInnerText = (props) => {
  return (
      <div className={`card-inner-text ${props.className}`}>
        {props.children}
      </div>
  );
};

CardInnerText.defaultProps = {
  className: ''
};

export default CardInnerText;
