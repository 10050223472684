import './modules/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import {compose, createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import App from './containers/App';
import {rootReducer} from './redux/rootReducer';

const store = createStore(rootReducer, compose(
    applyMiddleware(thunk),
    /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
));

ReactDOM.render(
    <Provider store={store}>
      <App/>
    </Provider>, document.querySelector('#root'),
);
