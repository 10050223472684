export const GET_SECTIONS = 'LEFT_PANEL/GET_SECTIONS';

export const USER_LOGOUT = 'APP/USER_LOGOUT';
export const GET_PROFILE = 'APP/GET_PROFILE';
export const GET_PROFILE_PHOTO = 'APP/GET_PROFILE_PHOTO';

export const MOBILE_MENU_OPENER = 'APP/MOBILE_MENU_OPENER';
export const MOBILE_USER_POINTS_OPENER = 'APP/MOBILE_USER_POINTS_OPENER';

export const GET_PROFILE_VIEWED = 'MANAGER/GET_PROFILE_VIEWED';
export const GET_PROFILE_VIEWED_PHOTO = 'MANAGER/GET_PROFILE_VIEWED_PHOTO';
