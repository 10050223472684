import React, {Component, Fragment} from 'react';
import {Empty, Skeleton} from 'antd';

import {idToName, courses, terms, termsRelated} from '../../modules/constants';
import {getProfileRecordBook} from '../../services/apiRequests';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import {CardCol} from '../../components/Card/CardCols';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import SelectInput from '../../components/SelectInput/SelectInput';
const {Option} = SelectInput;
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';
import GetIcon from '../../components/GetIcon/GetIcon';

const coursesItems = [
  {
    label: '1 курс',
    value: '1',
  },
  {
    label: '2 курс',
    value: '2',
  },
  {
    label: '3 курс',
    value: '3',
  },
  {
    label: '4 курс',
    value: '4',
  },
  {
    label: '5 курс',
    value: '5',
  },
  {
    label: '6 курс',
    value: '6',
  },
];

const termsRelatedItems = {
  1: [{
      label: 'Осенний (1)',
      value: '1',
    },
    {
      label: 'Весенний (2)',
      value: '2',
    }],
  2: [{
      label: 'Осенний (3)',
      value: '3',
    },
    {
      label: 'Весенний (4)',
      value: '4',
    }],
  3: [{
      label: 'Осенний (5)',
      value: '5',
    },
    {
      label: 'Весенний (6)',
      value: '6',
    }],
  4: [{
      label: 'Осенний (7)',
      value: '7',
    },
    {
      label: 'Весенний (8)',
      value: '8',
    }],
  5: [{
      label: 'Осенний (9)',
      value: '9',
    },
    {
      label: 'Весенний (10)',
      value: '10',
    }],
  6: [{
      label: 'Осенний (11)',
      value: '11',
    },
    {
      label: 'Весенний (12)',
      value: '12',
    }],
};

class StudentRecordBookPage extends Component {
  state = {
    scoresSum: 0,
    scoresCount: 0,
    openSpoilersStates: [],
    selectedRecordBook: null,
    selectedTerm: null, //termsRelated[courses[0]][0],

    /*Multiselect*/
    selectedCourses: [],
    selectedTerms: [],
  };

  componentDidMount() {}

  handleMultiSelectChange = (values, name) => {
    this.setState({[name]: values});
  }

  handleSingleSelectChange = (value, name) => {
    this.setState({[name]: value});
  }

  handleRecordBookSelectChange = (value, name) => {
    this.setState({
      selectedRecordBook: value,

      selectedCourses: [],
      selectedTerms: [],
    });
  }

  handleCoursesSelectChange = (values, name) => {

    let availableTerms = [];
    /*values.map((item, index) => {
      availableTerms.push(...termsRelatedItems[item.value]);
    });*/

    this.setState({
      selectedCourses: values,
      selectedTerms: availableTerms,
    });
  }

  handleTermsSelectChange = (values, name) => {
    this.setState({selectedTerms: values});
  }

  handleRowSpoilerClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let openSpoilersStates = this.state.openSpoilersStates;
    let spoilerName = e.currentTarget.dataset.name;

    openSpoilersStates[spoilerName] = !openSpoilersStates[spoilerName];

    this.setState({
      openSpoilersStates
    })
  }

  handleExportRecordBook = (e) => {
    e.preventDefault();

    const {profile} = this.props;

    if (profile) {
      getProfileRecordBook(profile.id).then((resp)=>{
        if (resp.status === 200) {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(resp.data);
          const a = document.createElement('a');
          a.href = URL.createObjectURL(
              new Blob([byteArray], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
          );
          a.download = `record-book.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
    }

    return false;
  }

  /*View logic*/
  buildTableCommon = (programs, id = 0) => {
    return <div className="table-like table-with-padding">
      <div className="table-header">
        <div className="table-row">
          <div className="table-data data-wide-3">Дисциплина</div>
          <div className="table-data">Часы</div>
          <div className="table-data">Зач. ед.</div>
          <div className="table-data data-wide-2">Преподаватель</div>
          <div className="table-data">Оценка</div>
          <div className="table-data">Дата</div>
        </div>
      </div>
      <div className="table-body">
        {programs.map((item, key)=>{
          return [
            <div key={key + '-desktop'} className="table-row row-desktop" title={item.attestation_type ? item.attestation_type.full_name : ''}>

              <div className="table-data data-wide-3">{item.full_name}</div>
              <div className="table-data">{item.hours}</div>
              <div className="table-data">{item.credits}</div>
              <div className="table-data data-wide-2">
                {
                  item.instructors.length > 0 ?
                      item.instructors.map((instr, key) => {
                        return <div
                            key={instr.id}
                            className={instr.is_main ?
                                'instructor-is-main' :
                                ''}>
                          {`${instr.last_name} ${instr.first_name.charAt(
                              0)}. ${instr.middle_name.charAt(0)}. `}
                        </div>;
                      }) : '-'
                }
              </div>
              <div className="table-data">{item.score.literal_rus}</div>
              <div className="table-data">{item.date.replace(/-/g, '.')}</div>
            </div>,

            <div key={key + '-mobile'}
                 data-name={id + '-' + item.id + '-mobile'}
                 className={'table-row row-mobile'}
                 title={item.attestation_type ? item.attestation_type.full_name : ''}>
              <div className={'table-row-spoiler' + (this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ? ' opened' : ' closed')}>
                <div className="row-spoiler-name"
                     data-name={id + '-' + item.id + '-mobile'}
                     onClick={this.handleRowSpoilerClick}>
                  <div className="name-text">
                    {item.full_name}
                  </div>
                  <div className="name-icon">
                    {this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ?
                        <GetIcon name="icon-angle-up"/>
                        : <GetIcon name="icon-angle-down"/>}
                  </div>
                </div>
                <div className="row-spoiler-content">
                  {/*<div className="table-data">Дисциплина</div>*/}
                  {/*<div className="table-data">{item.full_name}</div>*/}

                  <div className="table-data data-name-spoiler">Часы</div>
                  <div className="table-data data-text-spoiler">{item.hours}</div>

                  <div className="table-data data-name-spoiler">Зач. ед.</div>
                  <div className="table-data data-text-spoiler">{item.credits}</div>

                  <div className="table-data data-name-spoiler">Преподаватель</div>
                  <div className="table-data data-text-spoiler">
                    {
                      item.instructors.length > 0 ?
                          item.instructors.map((instr, key) => {
                            return <div
                                key={instr.id}
                                className={instr.is_main ?
                                    'instructor-is-main' :
                                    ''}>
                              {`${instr.last_name} ${instr.first_name.charAt(
                                  0)}. ${instr.middle_name.charAt(0)}. `}
                            </div>;
                          }) : '-'
                    }
                  </div>

                  <div className="table-data data-name-spoiler">Оценка</div>
                  <div className="table-data data-text-spoiler">{item.score.literal_rus}</div>

                  <div className="table-data data-name-spoiler">Дата</div>
                  <div className="table-data data-text-spoiler">{item.date.replace(/-/g, '.')}</div>
                </div>

              </div>
            </div>
          ]
        })}
      </div>
    </div>
  }

  buildTableCourseWork = (programs, id = 0) => {
    return <div className="table-like table-with-padding">
      <div className="table-header">
        <div className="table-row">
          <div className="table-data data-wide-2"
               title="Дисциплина">Дисциплина</div>
          <div className="table-data"
               title="Форма контроля">Форма контроля</div>
          <div className="table-data data-wide-2"
               title="Тема курсовой работы (проекта)">Тема</div>
          <div className="table-data"
               title="Оценка по итогам аттестации">Оценка</div>
          <div className="table-data data-wide-2"
               title="Преподаватель">Преподаватель</div>
          <div className="table-data data-wide-1-5"
               title="Дата сдачи">Дата</div>
        </div>
      </div>
      <div className="table-body">
        {programs.map((item, key)=>{
          return [
            <div key={key + '-desktop'}
                 className="table-row row-desktop"
                 title={item.attestation_type ? item.attestation_type.full_name : ''}>

              {/*Discipline*/}
              <div className="table-data data-wide-2">{item.full_name}</div>

              {/*Form of control*/}
              <div className="table-data">{item.attestation_type ? item.attestation_type.short_name : ''}</div>
              {/*Theme of course work*/}
              <div className="table-data data-wide-2">{item.course_work ? item.course_work.name : '-'}</div>
              {/*Score*/}
              <div className="table-data">{item.score.literal_rus}</div>
              {/*Teacher*/}
              <div className="table-data data-wide-2">
                {
                  item.instructors.length > 0 ?
                      item.instructors.map((instr, key) => {
                        return <div
                            key={instr.id}
                            className={instr.is_main ?
                                'instructor-is-main' :
                                ''}>
                          {`${instr.last_name} ${instr.first_name.charAt(
                              0)}. ${instr.middle_name.charAt(0)}. `}
                        </div>;
                      }) : '-'
                }
              </div>
              {/*Date*/}
              <div className="table-data data-wide-1-5">{item.date.replace(/-/g, '.')}</div>
            </div>,

            <div key={key + '-mobile'}
                 data-name={id + '-' + item.id + '-mobile'}
                 className={'table-row row-mobile'}>
              <div className={'table-row-spoiler' + (this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ? ' opened' : ' closed')}>
                <div className="row-spoiler-name"
                     data-name={id + '-' + item.id + '-mobile'}
                     onClick={this.handleRowSpoilerClick}>
                  <div className="name-text">
                    {item.full_name}
                  </div>
                  <div className="name-icon">
                    {this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ?
                        <GetIcon name="icon-angle-up"/>
                        : <GetIcon name="icon-angle-down"/>}
                  </div>
                </div>
                <div className="row-spoiler-content">
                  {/*<div className="table-data">Дисциплина</div>*/}
                  {/*<div className="table-data">{item.full_name}</div>*/}

                  <div className="table-data data-name-spoiler"
                       title="Форма контроля">Форма контроля</div>
                  <div className="table-data data-text-spoiler">
                    {item.attestation_type ? item.attestation_type.short_name : ''}
                  </div>

                  <div className="table-data data-name-spoiler"
                       title="Тема курсовой работы (проекта)">Тема</div>
                  <div className="table-data data-text-spoiler">{item.course_work ? item.course_work.name : '-'}</div>

                  <div className="table-data data-name-spoiler"
                       title="Оценка по итогам аттестации">Оценка</div>
                  <div className="table-data data-text-spoiler">{item.score.literal_rus}</div>

                  <div className="table-data data-name-spoiler"
                       title="Преподаватель">Преподаватель</div>
                  <div className="table-data data-text-spoiler">
                    {
                      item.instructors.length > 0 ?
                          item.instructors.map((instr, key) => {
                            return <div
                                key={instr.id}
                                className={instr.is_main ?
                                    'instructor-is-main' :
                                    ''}>
                              {`${instr.last_name} ${instr.first_name.charAt(
                                  0)}. ${instr.middle_name.charAt(0)}. `}
                            </div>;
                          }) : '-'
                    }
                  </div>

                  <div className="table-data data-name-spoiler"
                       title="Дата сдачи">Дата</div>
                  <div className="table-data data-text-spoiler">{item.date.replace(/-/g, '.')}</div>
                </div>

              </div>
            </div>
          ]
        })}
      </div>
    </div>
  }

  buildTablePractice = (programs, id = 0) => {
    return <div className="table-like table-with-padding font-size-12">
      <div className="table-header">
        <div className="table-row">
          {/*Practice view*/}
          <div className="table-data data-wide-2-5"
               title="Наименование вида практики">Вид</div>
          {/*Practice type*/}
          <div className="table-data data-wide-2-5"
               title="Тип практики">Тип</div>
          {/*Practice place*/}
          <div className="table-data"
               title="Место проведения практики">Место пров-я</div>
          {/*Practice position*/}
          <div className="table-data"
               title="В качестве кого работал (должность)">Долж-ть</div>
          {/*Hours / credits*/}
          <div className="table-data"
               title="Общее количество Часы / Зач. ед.">Часы / з.е.</div>
          {/*Score*/}
          <div className="table-data"
               title="Оценка по итогам аттестации">Оценка</div>
          {/*Date*/}
          <div className="table-data data-wide-1-5"
               title="Дата сдачи">Дата</div>
          {/*Instructors*/}
          <div className="table-data data-wide-2"
               title="Подпись и фамилия лица, проводившего аттестацию (преподаватель)">Преп.</div>
        </div>
      </div>
      <div className="table-body">
        {programs.map((item, key)=>{
          return [
            <div key={key + '-desktop'}
                 className="table-row row-desktop">
              {/*Practice view*/}
              <div className="table-data data-wide-2-5">
                {item.practice.kind && item.practice.kind.length > 0 ?
                    item.practice.kind : '-'}
              </div>
              {/*Practice type*/}
              <div className="table-data data-wide-2-5">
                {item.practice.name && item.practice.name.length > 0 ?
                    item.practice.name :
                    '-'}
              </div>
              {/*Practice place*/}
              <div className="table-data">-</div>
              {/*Practice position*/}
              <div className="table-data">-</div>
              {/*Hours / credits*/}
              <div className="table-data">{item.hours} / {item.credits}</div>
              {/*Score*/}
              <div className="table-data">{item.score.literal_rus}</div>
              {/*Date*/}
              <div className="table-data data-wide-1-5">{item.date.replace(/-/g, '.')}</div>
              {/*Instructors*/}
              <div className="table-data data-wide-2">
                {item.instructors.length > 0 ?
                      item.instructors.map((instr, key) => {
                        return <div
                            key={instr.id}
                            className={instr.is_main ?
                                'instructor-is-main' :
                                ''}>
                          {`${instr.last_name} ${instr.first_name.charAt(
                              0)}. ${instr.middle_name.charAt(0)}. `}
                        </div>;
                      }) : '-'}
              </div>
            </div>,

            <div key={key + '-mobile'}
                 data-name={id + '-' + item.id + '-mobile'}
                 className={'table-row row-mobile'}>
              <div className={'table-row-spoiler' + (this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ? ' opened' : ' closed')}>
                <div className="row-spoiler-name"
                     data-name={id + '-' + item.id + '-mobile'}
                     onClick={this.handleRowSpoilerClick}>
                  <div className="name-text">
                    {item.full_name}
                  </div>
                  <div className="name-icon">
                    {this.state.openSpoilersStates[id + '-' + item.id + '-mobile'] ?
                        <GetIcon name="icon-angle-up"/>
                        : <GetIcon name="icon-angle-down"/>}
                  </div>
                </div>
                <div className="row-spoiler-content">
                  {/*<div className="table-data">Дисциплина</div>*/}
                  {/*<div className="table-data">{item.full_name}</div>*/}

                  <div className="table-data data-name-spoiler"
                       title="Наименование вида практики">Вид</div>
                  <div className="table-data data-text-spoiler">
                    {item.practice.kind && item.practice.kind.length > 0 ?
                        item.practice.kind : '-'}
                  </div>

                  <div className="table-data data-name-spoiler"
                       title="Тип практики">Тип</div>
                  <div className="table-data data-text-spoiler">
                    {item.practice.name && item.practice.name.length > 0 ?
                        item.practice.name :
                        '-'}
                  </div>

                  <div className="table-data data-name-spoiler"
                       title="Место проведения практики">Место проведения</div>
                  <div className="table-data data-text-spoiler">-</div>

                  <div className="table-data data-name-spoiler"
                       title="В качестве кого работал (должность)">Должность</div>
                  <div className="table-data data-text-spoiler">-</div>

                  <div className="table-data data-name-spoiler"
                       title="Общее количество Часы / Зач. ед.">Часы / Зач. ед.</div>
                  <div className="table-data data-text-spoiler">{item.hours} / {item.credits}</div>

                  <div className="table-data data-name-spoiler"
                       title="Оценка по итогам аттестации">Оценка</div>
                  <div className="table-data data-text-spoiler">{item.score.literal_rus}</div>

                  <div className="table-data data-name-spoiler"
                       title="Дата сдачи">Дата</div>
                  <div className="table-data data-text-spoiler">{item.date.replace(/-/g, '.')}</div>

                  <div className="table-data data-name-spoiler"
                       title="Подпись и фамилия лица, проводившего аттестацию (преподаватель)">Преподаватель</div>
                  <div className="table-data data-text-spoiler">
                    {
                      item.instructors.length > 0 ?
                          item.instructors.map((instr, key) => {
                            return <div
                                key={instr.id}
                                className={instr.is_main ?
                                    'instructor-is-main' :
                                    ''}>
                              {`${instr.last_name} ${instr.first_name.charAt(
                                  0)}. ${instr.middle_name.charAt(0)}. `}
                            </div>;
                          }) : '-'
                    }
                  </div>
                </div>

              </div>
            </div>
          ]
        })}
      </div>
    </div>
  }

  render() {
    const {
      selectedTerm,
      selectedRecordBook,

      selectedCourses,
      selectedTerms,
    } = this.state;

    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    let recordBooksItems = [];
    let cardsTermsByCourse = [];

    let averageScoreByTerms = 0;
    let averageSumByTerms = 0;
    let averageCountByTerms = 0;

    let underheadActions;

    let availableTerms = [];

    let newCourseItems = [];

    if (profile && profile.education) {
      let recordBooksList = profile.education.record_books;

      if (recordBooksList && recordBooksList.length > 0) {
        recordBooksItems = recordBooksList.map((item, index)=>(
            {
              value: index,
              label: profile.education.groups[item.group_id].name,
            }
        ));
      }

      let recordBook = selectedRecordBook ? recordBooksList[selectedRecordBook.value] : recordBooksList[0];
      let coursesRecordBook = recordBook['courses'];

      let selectedCoursesArr = [];
      let selectedTermsArr = [];
      if (selectedCourses) {
        selectedCourses.map((item, index) => {
          selectedCoursesArr.push(item.value);
          availableTerms.push(...termsRelatedItems[item.value]);
        });
      }

      if (selectedTerms) {
        selectedTerms.map((item, index) => {
          selectedTermsArr.push(item.value);
        })
      }

      Object.keys(coursesRecordBook).map((keyCourse) => {
            let currentCourse = coursesRecordBook[keyCourse];
            let semesters = currentCourse['terms'];
            let cardsTermsById = [];

            /*Filter only courses which exist from user*/
            newCourseItems.push(coursesItems[keyCourse-1]);

            /*Skip cycle step if current currentCourse not equal  one of selectedCoursesArr */
            if (selectedCoursesArr.length > 0 && selectedCoursesArr.indexOf(keyCourse) === -1) {
              return false;
            }

            Object.keys(semesters).map((keyTerm) => {
              /*Skip cycle step if current term not equal one of selectedTermsArr*/
              if (selectedTermsArr.length > 0 && selectedTermsArr.indexOf(keyTerm) === -1) {
                return false;
              }

              let currentSem = semesters[keyTerm];
              let cardId = currentCourse.id + '-' + currentSem.id;

              if (currentSem.average_score > 0) {
                averageCountByTerms++;
                averageSumByTerms = averageSumByTerms + currentSem.average_score;
              }

              let semesterName =(currentSem.id % 2 ? 'осенний' : 'весенний') + ' (' + currentSem.id + ')';
                  cardsTermsById[keyTerm] =
                      <Card size="auto"
                            key={cardId}
                            notice={currentSem.average_score + ' б.'}
                            isCollapsible={true}
                            header={`${currentCourse.id} курс, ${semesterName} семестр`}
                            separatedHeader={true}
                            hasHover={false}>

                        {currentSem['disciplines'].length > 0 ?
                            <Fragment>
                              <div className="card-inner-header">
                                Программа курса
                              </div>
                              <div className="card-table">
                                {this.buildTableCommon(
                                    currentSem['disciplines'], cardId)}
                              </div>
                            </Fragment> : null}

                        {currentSem['course_works'].length > 0 ?
                            <Fragment>
                              <div className="card-inner-header">
                                Курсовая работа
                              </div>
                              <div className="card-table">
                                {this.buildTableCourseWork(
                                    currentSem['course_works'], cardId)}
                              </div>
                            </Fragment>
                            : null}

                        {currentSem['practices'].length > 0 ?
                            <Fragment>
                              <div className="card-inner-header">
                                Практика
                              </div>
                              <div className="card-table">
                                {this.buildTablePractice(currentSem['practices'],
                                    cardId)}
                              </div>
                            </Fragment>
                            : null}

                        {currentSem['other'].length > 0 ?
                            <Fragment>
                              <div className="card-inner-header">
                                Другое
                              </div>
                              <div className="card-table">
                                {this.buildTableCommon(currentSem['other'],
                                    cardId)}
                              </div>
                            </Fragment>
                            : null}
                      </Card>;
                },
            );

            averageScoreByTerms = averageSumByTerms / averageCountByTerms;
            averageScoreByTerms = averageScoreByTerms ? parseFloat((averageScoreByTerms).toFixed(2) ) : 0;
            cardsTermsByCourse[keyCourse] = cardsTermsById;
          });

      underheadActions = [
        <SelectInput
            key="record-book"
            allowClear={false}
            className="record-book-field record-book-field-1"
            onSelectChange={this.handleRecordBookSelectChange}
            name="selectedRecordBook"
            defaultValue={recordBooksItems[0]}
            fieldLabel="Группа"
            items={recordBooksItems}/>,
        <SelectInput
            isMulti
            key="course"
            onSelectChange={this.handleCoursesSelectChange}
            value={selectedCourses}
            className="record-book-field record-book-field-2"
            name="selectedCourses"
            fieldLabel="Курс"
            placeholder="Выберите курс"
            items={newCourseItems}/>,
        <SelectInput
            isMulti
            key="semester"
            value={selectedTerms}
            disabled={availableTerms.length === 0}
            maxTagCount={3}
            onSelectChange={this.handleTermsSelectChange}
            className={'record-book-field record-book-field-3 ' +
            (availableTerms.length === 0 ? ' disabled' : '')}
            name="selectedTerms"
            fieldLabel="Семестр"
            notFoundContent="Сперва выберите курс"
            placeholder="Выберите семестр"
            items={availableTerms}/>,
        <button key="export"
                className="btn-link record-book-export-btn"
                onClick={this.handleExportRecordBook}>
          <GetIcon name="icon-download"/> Выгрузить данные</button>
      ];
    }

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageHeadline>
            {profile ?
                `${profile.personal.last_name} ${profile.personal.first_name} ${profile.personal.middle_name.length > 1 ? profile.personal.middle_name : ''}` :
                <Skeleton.Input style={{ minWidth: 300 }} active/>
            }
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent
                underheadActions={underheadActions}
                notice={averageScoreByTerms + ' б.'}
                header="Электронная зачетка">
              {cardsTermsByCourse.length ? cardsTermsByCourse :
                  <Empty description="Данных по семестрам не найдено"/>}

              {/*<Card size="auto"
                    header="1 семестр"
                    separatedHeader={true}
                    hasHover={false}>
                <div className="card-inner-header">
                  Программа курса
                </div>

                <div className="card-table">
                  <div className="table-like table-program">
                    <div className="table-header">
                      <div className="table-row">
                        <div className="table-data">Дисциплина</div>
                        <div className="table-data">Часы</div>
                        <div className="table-data">Зач. ед.</div>
                        <div className="table-data">Преподаватель</div>
                        <div className="table-data">Оценка</div>
                        <div className="table-data">Дата</div>
                      </div>
                    </div>
                    <div className="table-body">
                      <div className="table-row">
                        <div className="table-data">Международное право</div>
                        <div className="table-data">124</div>
                        <div className="table-data">3</div>
                        <div className="table-data">Мусыджалиев М.О.</div>
                        <div className="table-data">н/а</div>
                        <div className="table-data">21.10.20</div>
                      </div>
                      <div className="table-row">
                        <div className="table-data">Международное право</div>
                        <div className="table-data">124</div>
                        <div className="table-data">3</div>
                        <div className="table-data">Мусыджалиев М.О.</div>
                        <div className="table-data">н/а</div>
                        <div className="table-data">21.10.20</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-inner-header">
                  Курсовая работа
                </div>

                <div className="card-table">

                </div>

                <div className="card-inner-header">
                  Практика
                </div>

                <div className="card-table">

                </div>
              </Card>*/}
              {/*<Card size="auto"
               header="2 семестр"
               separatedHeader={true}
               hasHover={false}>
               <div className="card-inner-header">
               Электронная зачетка
               </div>

               <div className="card-table">
               </div>


               <div className="card-inner-header">
               Курсовая работа
               </div>

               <div className="card-table">
               </div>

               <div className="card-inner-header">
               Практика
               </div>

               <div className="card-table">
               </div>
               </Card>
               */}
              {/*<Card size="auto"
                    header="3 семестр"
                    disabled={true}
                    minHeight={false}
                    separatedHeader={true}
                    hasHover={false}>
                <div className="card-inner-text">
                  Данные о предметах и преподавателях пока недоступны
                </div>
              </Card>*/}
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}

export default StudentRecordBookPage;
