import {message} from 'antd';

import GetIcon from '../GetIcon/GetIcon';

const defaultDelay = 8;
/*className
 content
 duration
 icon
 key
 style
 onClick
 onClose*/

const defaultConfig = {
  content: 'Error!',
  duration: defaultDelay,
}

export const AlertNotice = {
  open(config = defaultConfig) {
    message.open(config);
  },
  show(textOrConfig = 'Error!', type = 'error', delay = defaultDelay, onClose) {
    if (typeof textOrConfig === 'string') {
      message[type](textOrConfig, delay, onClose);
    } else {
      message[type](textOrConfig)
    }
  },
  error(textOrConfig = 'Error!', delay = defaultDelay, onClose) {
    message['error'](textOrConfig, delay, onClose);
  },
  success(textOrConfig = 'Success!', delay = defaultDelay, onClose) {
    message['success'](textOrConfig, delay, onClose);
  },
  warning(textOrConfig = 'Warning!' , delay = defaultDelay, onClose) {
    message['warning'](textOrConfig, delay, onClose);
  },
  warn(textOrConfig = 'Warning!' , delay = defaultDelay, onClose) {
    message['warning'](textOrConfig, delay, onClose);
  },
  loading(textOrConfig = 'Loading...', delay = defaultDelay, onClose) {
    message['loading'](textOrConfig, delay, onClose);
  },
};

export default AlertNotice;