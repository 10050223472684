import React, {Component} from 'react';
import {
  Switch,
} from 'react-router-dom';
import {connect} from 'react-redux';

import {
  getAuthProfileRatingSection,
  getAuthProfileRatingAll,
} from '../../services/apiRequests';

import RouteWithSubRoutes from '../../components/RouteWithSubRoutes/RouteWithSubRoutes';
import {getQueryParameter} from '../../modules/helpers';
import {authService} from '../../services/auth';

class StudentPageWrapper extends Component {
  constructor(props) {
    super();
    this.state = {
      ratingPersonal: null,
      ratingScience0: null,
      ratingStudy1: null,
      ratingSport2: null,
      ratingCreative3: null,
    };


    let viewProfile = getQueryParameter('viewProfile');
    /*TEMP fallback redirect for old urls like
     /student/record-book/?viewProfile=a990e088b4ef7c62da8d1c0ad03c576ed5679fe78e23
     */
    if (viewProfile) {
      props.history.push('/manager/view-profile/personal?pid=' + viewProfile);
    }
  }

  componentDidMount() {
    getAuthProfileRatingAll().then((resp) => {
      if (resp.status === 200) {
        this.setState({ratingPersonal: resp.data.place});
      }
    });

    getAuthProfileRatingSection(0).then((resp) => {
      if (resp.status === 200) {
        this.setState({ratingScience0: resp.data.place});
      }
    });
    getAuthProfileRatingSection(1).then((resp) => {
      if (resp.status === 200) {
        this.setState({ratingStudy1: resp.data.place});
      }
    });
    getAuthProfileRatingSection(2).then((resp) => {
      if (resp.status === 200) {
        this.setState({ratingSport2: resp.data.place});
      }
    });
    getAuthProfileRatingSection(3).then((resp) => {
      if (resp.status === 200) {
        this.setState({ratingCreative3: resp.data.place});
      }
    });
  }

  render() {
    const {profile, profilePhotoBase64, routes} = this.props;
    const {ratingPersonal, ratingScience0, ratingStudy1, ratingSport2, ratingCreative3} = this.state;

    return (
        <React.Fragment>
        <Switch>
          {routes.map((route, i) => (
              <RouteWithSubRoutes
                  profile={profile}
                  profilePhotoBase64={profilePhotoBase64}
                  viewType="student"
                  ratings={{
                    ratingPersonal,
                    ratingScience0,
                    ratingStudy1,
                    ratingSport2,
                    ratingCreative3
                  }}
                  key={i}
                  {...route} />
          ))}
        </Switch>
        </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    profilePhotoBase64: state.profilePhotoBase64.profilePhotoBase64
  };
}

export default connect(mapStateToProps, null)(StudentPageWrapper);
