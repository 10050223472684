import React from 'react';
import {} from './NotFound404.scss';

const NotFound = props => {
  return (
      <React.Fragment>
        <div className="not-found-wrapper">

        </div>

        <h2 className="not-found-title">Ошибка 404. Страница не найдена</h2>
      </React.Fragment>
  );
};

export default NotFound;
