import React, {Component} from 'react';
import {Empty, Skeleton, Popover} from 'antd';
import {FileTextOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import PageContent from '../../components/PageContent/PageContent';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';
import GetIcon from '../../components/GetIcon/GetIcon';
import {idToName} from '../../modules/constants';

class StudentActivityCommonPage extends Component {
  state = {
    sectionName: '',
    sectionId: 0,
    subsectionId: 0,
  };

  componentDidMount() {
    let subsectionId = this.props.match.params.subsectionId;
    let sectionName = this.props.match.params.sectionName;

    this.setState({
      subsectionId,
      sectionId: idToName.getSectionIdBySlug(sectionName).id,
      sectionName
    });
  }

  render() {
    const {subsectionId, sectionId, sectionName} = this.state;
    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    const accomplishments = profile && profile.accomplishments;

    let finalAccompls = {};
    let subsectionName = '';
    let subsectionPoints = 0;
    let rows = [];

    if (accomplishments && accomplishments[sectionId] &&
        accomplishments[sectionId]['subsections'][subsectionId]) {
      let subsect = accomplishments[sectionId]['subsections'][subsectionId];

      subsectionName = subsect['name'];
      finalAccompls = subsect['accomplishments'];
      subsectionPoints = subsect.points;

      rows = Object.keys(finalAccompls).map(
          (key, index) => {
            let accompl = finalAccompls[key];
            let events = accompl['events'];

            let eventsItems = [];
            if (Object.keys(events).length > 0) {
              eventsItems = Object.keys(events).
                  map((eventKey, indexKey)=>{
                    return <li
                        key={events[eventKey].id}
                        className="event-item">
                      <div className="event-name">
                        <div className="event-info">
                          <Popover placement="bottom"
                                   overlayClassName="event-info-popover"
                                   trigger="click"
                                   content={events[eventKey].date + ' ' +
                                   events[eventKey].name}>
                            <QuestionCircleOutlined/>
                          </Popover>
                        </div>
                        {events[eventKey].date + ' ' + events[eventKey].name}
                      </div>
                      <div className="event-files">
                        {events[eventKey].files.length > 0 ?
                            events[eventKey].files.map((file, index)=>{
                              return <a
                                  key={index + '-file'}
                                  className="file-link"
                                  target="_blank"
                                  href={file.url}>
                                <span className="link-text">Файл </span>
                                <span className="link-icon"><FileTextOutlined/></span>
                              </a>
                            }) : null
                        }
                      </div>
                      <div className="event-points">
                        {events[eventKey].points + ' б.'}
                      </div>
                    </li>
                  });
            }

            return <li key={accompl.id} className="unordered-item">
              <div className="list-content">{accompl.name}
                <ul className="content-list-events">
                  {eventsItems}
                </ul>
              </div>
              <div className="list-actions-right">
                <div className="list-actions-notice">
                  {parseFloat(accompl.points.toFixed(2))} б.
                </div>
                {/* <div className="list-actions-elements">
                 <Link to={`#`} className="btn-circle size-md">
                 <GetIcon name="icon-pencil"/>
                 </Link>
                 </div>*/}
              </div>
            </li>;
          });
    }

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageHeadline>
            {profile ?
                `${profile.personal.first_name} ${profile.personal.last_name}` :
                <Skeleton.Input style={{ minWidth: 300 }} active/>
            }
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent
                goBackAction={true}
                notice={parseFloat(subsectionPoints.toFixed(2)) + ' б.'}
                header={subsectionName}>
              <Card size="auto"
                    hasHover={false}>
                <div className="card-inner-text">
                  {rows.length > 0 ?
                      <ul className="card-unordered-list">
                        {rows}
                      </ul>
                      :
                      <Skeleton/>
                  }
                </div>
              </Card>
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}

export default StudentActivityCommonPage;
