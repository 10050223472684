import {Component} from 'react';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import LoginForm from '../../components/LoginForm/LoginForm';
import {authService} from '../../services/auth';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    if (authService.isAuthenticated()) {
      this.props.history.push('/');
    }
  }


  render() {
    return (
        <LayoutMain hideMobileMenu={true} hideTopMenu={true}>
          <div className="login-page-container">
            <LoginForm
              header="Авторизуйтесь для просмотра этой страницы"/>
          </div>
        </LayoutMain>
    );
  }
}

export default LoginPage;