import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import {Popover} from 'antd';

import GetIcon from '../GetIcon/GetIcon';
import Popconfirm from '../Popconfirm/Popconfirm';
import {SITE_HOST_URL} from '../../modules/constants';

import './SmartFilterItem.scss';

class SmartFilterItem extends Component {
  state = {
    showCopiedNotice: false,
    currentDomain: window ? window.location.host : SITE_HOST_URL,
  };

  handleOnCopyClipboardClick = (e) => {
    e.preventDefault();

    const {currentDomain} = this.state;
    const {filterId} = this.props;

    copy(`${currentDomain}/manager/rating?filterId=${filterId}`);

    this.setState({showCopiedNotice: true});

    setTimeout(()=>{
      this.setState({showCopiedNotice: false});
    }, 2500)
  }

  handleOnConfirmDuplicateClick = () => {
    this.props.onDuplicateFilterCick(this.props.filter);
  }

  componentDidMount() {}

  render() {
    let {header, textDate, hideActions, filterId} = this.props;
    let {showCopiedNotice} = this.state;

    return (
        <div className="smart-filter">
          <div className="smart-filter-name">
            <div className="name-text">
              <Link to={`/manager/rating?filterId=${filterId}`}>
                {header}
              </Link>
            </div>
            <div className="name-date">
              {textDate}
            </div>
          </div>

          {hideActions ? null : <div className="smart-filter-actions">
            <Popconfirm title="Создать копию фильтра?"
                        onConfirm={this.handleOnConfirmDuplicateClick}>
              <div className="action-button button-copy" title="Создать копию фильтра">
                <Link to="/manager/filters" className="btn-circle size-md extra-spaced-mobile">
                  <GetIcon name="icon-copy"/>
                </Link>
              </div>
            </Popconfirm>

            {<div className="action-button button-edit">
              <Link to={`/manager/filters/${filterId}/edit`} className="btn-circle size-md extra-spaced-mobile">
                <GetIcon name="icon-pencil"/>
              </Link>
            </div>}


            <div className="action-button button-link" title="Скопировать ссылку">
              <Popover placement="bottom"
                       overlayClassName="smart-filter-copy-popover"
                       visible={showCopiedNotice}
                       content="Ссылка скопирована в буфер обмена!">
                <Link to={`/manager/rating?filterId=${filterId}`}
                      onClick={this.handleOnCopyClipboardClick}
                      className="btn-circle size-md extra-spaced-mobile highlighted">
                  <GetIcon name="icon-chain"/>
                </Link>
              </Popover>
            </div>
          </div>}
        </div>
    );
  }
}

export default SmartFilterItem;