import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import PersonalDataCard from '../../components/PersonalDataCard/PersonalDataCard';
import CardCols, {CardCol} from '../../components/Card/CardCols';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import LeftPanelManager
  from '../../components/LeftPanelManager/LeftPanelManager';
import RightPanelManager
  from '../../components/RightPanelManager/RightPanelManager';


class ManagerPersonalPage extends Component {
  render() {
    const {profile} = this.props;

    return (
        <LayoutMain>
          <PageContent>
            <LeftPanelManager/>
            <RightPanelManager header="Личные данные">
              <PersonalDataCard profile={profile}/>
            </RightPanelManager>
          </PageContent>
        </LayoutMain>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile
  };
}

export default connect(mapStateToProps, null)(ManagerPersonalPage);