import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import LogoTpuRu from '../../images/common/logo-tpu-ru.png';
import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Modal from '../../components/Modal/Modal';
import LoginForm from '../../components/LoginForm/LoginForm';
import {authService} from '../../services/auth';

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      isModalShowed: false
    };
  }

  handleConnectButtonClick = () => {
    if (authService.isAuthenticated()) {
      this.props.history.push('/my/personal');
    } else {
      this.setState({
        isModalShowed: true,
      });
    }
  }

  handleModalCloseClick = (state) => {
    this.setState({
      isModalShowed: state,
    });
  }

  handleSuccessFormSubmit = (data) => {
    this.setState({
      isModalShowed: false,
    });
    this.props.history.push('/my/personal')
  }

  componentDidMount() {
    this.setState({
      isManagerView: true
    })
  }

  render() {
    let {isModalShowed} = this.state;

    return (
        <LayoutMain transparentHeader={true} hideMobileMenu={true} hideTopMenu={!authService.isAuthenticated()}>
          <div className="start-page full-page">
            <div className="start-page-left-panel">
              <div className="start-page-subhead">
                Все и даже больше
              </div>
              <div className="start-page-slogan">
                <h1>Достижения,
                  награды и заслуги
                  студентов ТПУ</h1>
              </div>
              <button onClick={this.handleConnectButtonClick} className="btn btn-green btn-xl">
                Присоединиться
              </button>

              <div className="start-page-copyright">
                <img src={LogoTpuRu} alt="ТПУ"/>
              </div>

              <div className="start-page-left-panel-blur"/>
            </div>
            <div className="start-page-background-shading"/>
            <div className="start-page-background"/>
          </div>

          <Modal isOpened={isModalShowed}
                 className="login-modal"
                 header="Войти в систему"
                 onClose={this.handleModalCloseClick}>
            <LoginForm
                onSuccess={this.handleSuccessFormSubmit}/>
          </Modal>
        </LayoutMain>
    );
  }
}

export default withRouter(StartPage);
