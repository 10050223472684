import React, {Component, Fragment} from 'react';
import {Empty, Skeleton} from 'antd';

import {
  getMdlCourseWorksTypes, getMdlCourseWorks, getFilter,
} from '../../services/apiRequests';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import Modal from '../../components/Modal/Modal';
import {CardCol} from '../../components/Card/CardCols';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import SelectInput from '../../components/SelectInput/SelectInput';
const {Option} = SelectInput;
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';
import GetIcon from '../../components/GetIcon/GetIcon';
import LoginForm from '../../components/LoginForm/LoginForm';
import {Link} from 'react-router-dom';
import {refreshPage} from '../../modules/helpers';
import moment from 'moment';

class StudentProjectsPage extends Component {
  state = {
    scoresSum: 0,
    scoresCount: 0,
    openSpoilersStates: [],
    isModalShowed: false,
    modalHeader: null,
    modalContent: null,

    projectsList: null,
    projectsTypesList: null,

    selectedDateFrom: null,
    selectedDateTo: null,
    /*Multiselect*/
    selectedProjectTypes: [],
  };

  componentDidMount() {
    getMdlCourseWorksTypes().then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({projectsTypesList: resp.data});
      }
    })

    if (this.props.profile) {
      this.getCourseWorksEvent(this.props.profile.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.profile && this.props.profile) {
      this.getCourseWorksEvent(this.props.profile.id);
    }
  }

  getCourseWorksEvent = (id) => {
    return getMdlCourseWorks(id).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        this.setState({projectsList: resp.data});
      }

      return resp;
    });
  };

  handleModalCloseClick = (state) => {
    this.setState({
      isModalShowed: state,
    });
  }

  handleFromPeriodChange = (date, dateString) => {
    this.setState({selectedDateFrom: date});
  };

  handleToPeriodChange = (date, dateString) => {
    this.setState({selectedDateTo: date});
  };

  handleProjectTypesSelectChange = (values, name) => {
    this.setState({selectedProjectTypes: values});
  }

  handleFeedbackBtnClick = (e) => {
    const clickedId = e.currentTarget.dataset['id'];
    const {projectsList} = this.state;

    this.setState({
      modalHeader: 'Рецензия',
      modalContent: this.buildModalContentFeedback(projectsList[clickedId]),
      isModalShowed: true
    });
  }

  handleFilesBtnClick = (e) => {
    const clickedId = e.currentTarget.dataset['id'];
    const {projectsList} = this.state;

    this.setState({
      modalHeader: 'Файлы',
      modalContent: this.buildModalContentFiles(projectsList[clickedId]),
      isModalShowed: true
    });
  }

  handleRowSpoilerClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let openSpoilersStates = this.state.openSpoilersStates;
    let spoilerName = e.currentTarget.dataset.name;

    openSpoilersStates[spoilerName] = !openSpoilersStates[spoilerName];

    this.setState({
      openSpoilersStates
    })
  }

  buildArrayFromItems = (items) => {
    if (itemsArr && itemsArr.length > 0) {
      return itemsArr.map((item)=>(
          {
            value: item,
            label: item,
          }
      ));
    }

    return [];
  }

  /*View logic*/
  buildOptionItems = (itemsArr) => {
    if (itemsArr && itemsArr.length > 0) {
      return itemsArr.map((item)=>(
          {
            value: item,
            label: item,
          }
      ));
    }

    return [];
  }

  buildModalContentFeedback = (project) => {
    if (project) {
      return <div className="student-projects-feedback">
        <div className="student-projects-feedback">
          <div className="feedback-id">
            ID: {project.feedback.owner_id}
          </div>
          <div className="feedback-project-name">
            {project.name}
          </div>
          <div className="feedback-project-type">
            {project.type}
          </div>
          <div className="feedback-content">
            <div className="feedback-text">
              {project.feedback.text}
            </div>

            {project.feedback.files.length ?
                <React.Fragment>
                  <div className="feedback-files-header">
                    Файлы:
                  </div>
                  <ul className="feedback-projects-files-list">
                    {project.feedback.files.map((file) => {
                      return <li className="files-file-item">
                        <a className="files-file-item-link" href={file.url}><span>{file.name}</span> <GetIcon name="icon-external-link"/></a>
                      </li>;
                    })}
                  </ul>
                </React.Fragment> : null
            }
          </div>
        </div>
      </div>
    }

    return null;
  }

  buildModalContentFiles = (project) => {
    if (project) {
      return <div className="student-projects-files">
        {project.files.length ?
            <ul className="feedback-projects-files-list">
              {project.files.map((file) => {
                return <li className="files-file-item">
                  <a className="files-file-item-link" href={file.url}><span>{file.name}</span>
                    <GetIcon name="icon-external-link"/></a>
                </li>;
              })}
            </ul> : null
        }
      </div>;
    }

    return null;
  }

  buildTableProjects = (programs, id = 0) => {
    return <div className="table-like table-with-padding">
      <div className="table-header">
        <div className="table-row">
          {/*Название работы*/}
          <div className="table-data data-wide-2-5"
               title="Название работы">Название работы</div>
          {/*Тип работы*/}
          <div className="table-data data-wide-2"
               title="Тип работы">Тип работы</div>
          {/*Score*/}
          <div className="table-data data-wide-1-5"
               title="Оценка">Оценка</div>
          {/*Рецензия*/}
          <div className="table-data"
               title="Рецензия">Рецензия</div>
          {/*Файлы*/}
          <div className="table-data"
               title="Файлы">Файлы</div>
          {/*Date*/}
          <div className="table-data data-wide-1-5"
               title="Дата сдачи">Дата</div>
        </div>
      </div>
      <div className="table-body">
        {programs.length ?
            programs.map((item, key)=>{
              return [
                <div key={key + '-desktop'}
                     className="table-row row-desktop">
                  {/*Название работы*/}
                  <div className="table-data data-wide-2-5">
                    {item.name}
                  </div>
                  {/*Тип работы*/}
                  <div className="table-data data-wide-2">
                    {item.type}
                  </div>
                  {/*Score*/}
                  <div className="table-data data-wide-1-5">{item.grade} из {item.max_grade}</div>
                  {/*Рецензия*/}
                  <div className="table-data">
                    <button onClick={this.handleFeedbackBtnClick}
                            data-id={key}
                            className="btn-circle size-md extra-spaced-mobile">
                      <GetIcon name="icon-document"/>
                    </button>
                  </div>
                  {/*Файлы*/}
                  <div className="table-data">
                    <button onClick={this.handleFilesBtnClick}
                            data-id={key}
                            className="btn-circle size-md extra-spaced-mobile">
                      <GetIcon name="icon-clip"/>
                    </button>
                  </div>
                  {/*Date*/}
                  <div className="table-data data-wide-1-5">
                    {moment.unix(item.date).format('DD.MM.YYYY')}
                  </div>
                </div>,

                <div key={key + '-mobile'}
                     data-name={id + '-' + key + '-mobile'}
                     className={'table-row row-mobile'}>
                  <div className={'table-row-spoiler' +
                  (this.state.openSpoilersStates[id + '-' + key + '-mobile'] ?
                      ' opened' :
                      ' closed')}>
                    <div className={'table-row-spoiler'}>
                      <div className="row-spoiler-name"
                           data-name={id + '-' + key + '-mobile'}
                           onClick={this.handleRowSpoilerClick}>
                        <div className="name-text">
                          {item.name}
                        </div>
                        <div className="name-icon">
                          {this.state.openSpoilersStates[id + '-' + key +
                          '-mobile'] ?
                              <GetIcon name="icon-angle-up"/>
                              : <GetIcon name="icon-angle-down"/>}
                        </div>
                      </div>
                      <div className="row-spoiler-content">

                        <div className="table-data data-name-spoiler"
                             title="Тип работы">Тип работы</div>
                        <div className="table-data data-text-spoiler">
                          {item.type}
                        </div>

                        <div className="table-data data-name-spoiler"
                             title="Оценка">Оценка</div>
                        <div className="table-data data-text-spoiler">
                          {item.grade} из {item.max_grade}
                        </div>

                        <div className="table-data data-name-spoiler"
                             title="Рецензия">Рецензия</div>
                        <div className="table-data data-text-spoiler">
                          <button onClick={this.handleFeedbackBtnClick}
                                  data-id={key}
                                  className="btn-circle size-md">
                            <GetIcon name="icon-document"/>
                          </button>
                        </div>

                        <div className="table-data data-name-spoiler"
                             title="Файлы">Файлы</div>
                        <div className="table-data data-text-spoiler">
                          <button onClick={this.handleFilesBtnClick}
                                  data-id={key}
                                  className="btn-circle size-md">
                            <GetIcon name="icon-clip"/>
                          </button>
                        </div>

                        <div className="table-data data-name-spoiler"
                             title="Дата сдачи">Дата</div>
                        <div className="table-data data-text-spoiler">
                          {moment.unix(item.date).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>,
              ]
            })
            :
            [
              <div key="0-desktop"
                   className="table-row row-desktop">
                <div className="table-data data-wide-2-5">
                  <Empty description="Данных не найдено"/>
                </div>
              </div>,
              <div key="0-mobile"
                   className={'table-row row-mobile'}>
                <Empty description="Данных не найдено"/>
              </div>
            ]
        }
      </div>
    </div>
  }

  render() {
    const {
      modalHeader,
      modalContent,
      isModalShowed,

      projectsList,
      projectsTypesList,
      selectedDateFrom,
      selectedDateTo,
      selectedProjectTypes,
    } = this.state;

    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    let projectTypesItems = this.buildOptionItems(projectsTypesList);
    let underheadActions = [
      <DatePickerInput
          key="from"
          value={selectedDateFrom}
          className="underhead-field underhead-field-1"
          fieldLabel="С периода"
          placeholder="Начало"
          onChange={this.handleFromPeriodChange}/>,
      <DatePickerInput
          key="to"
          value={selectedDateTo}
          className="underhead-field underhead-field-2"
          fieldLabel="По период"
          placeholder="Конец"
          onChange={this.handleToPeriodChange}/>,
      <SelectInput
          isMulti
          key="projectTypes"
          value={selectedProjectTypes}
          maxTagCount={3}
          onSelectChange={this.handleProjectTypesSelectChange}
          className={'underhead-field underhead-field-3'}
          name="selectedProjectTypes"
          fieldLabel="Типы работ"
          placeholder="Выберите тип"
          items={projectTypesItems}/>
    ];
    let cardProjects = null;

    if (profile && projectsList) {
      let newProjectsList = projectsList;

      if (selectedDateFrom) {
        newProjectsList = newProjectsList.filter((item)=>{
          return item.date > selectedDateFrom.unix();
        })
      }

      if (selectedDateTo) {
        newProjectsList = newProjectsList.filter((item)=>{
          return item.date < selectedDateTo.unix();
        })
      }

      if (selectedProjectTypes && selectedProjectTypes.length) {
        let selectedProjectTypesArr = selectedProjectTypes.map((item)=> item.value)

        newProjectsList = newProjectsList.filter((item)=>{
          return selectedProjectTypesArr.indexOf(item.type) > -1;
        })
      }

      let cardId = 'projects-card';

      cardProjects = <Card size="auto"
                           key={cardId}
                           isCollapsible={false}
                           hasHover={false}>
        <div className="card-table">
          {this.buildTableProjects(newProjectsList, cardId)}
        </div>
      </Card>;
    }

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageHeadline>
            {profile ?
                `${profile.personal.last_name} ${profile.personal.first_name} ${profile.personal.middle_name.length > 1 ? profile.personal.middle_name : ''}` :
                <Skeleton.Input style={{ minWidth: 300 }} active/>
            }
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent
                underheadActions={underheadActions}
                header="Мои работы и проекты">
              {cardProjects ?? <Skeleton title={false} paragraph={{rows: 4}} style={{ minWidth: 300 }} active/>}
            </RightPanelStudent>
          </PageContent>

          <Modal isOpened={isModalShowed}
                 className="student-projects-modal"
                 header={modalHeader}
                 onClose={this.handleModalCloseClick}>
            {modalContent}
          </Modal>
        </LayoutMain>
    );
  }
}

export default StudentProjectsPage;
