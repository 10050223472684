import React from 'react';
import sprite from '../../images/sprites/icons.svg';

const GetIcon = ({name, modifier = ''}) => {
  return (
      <svg className={`icon ${name} ${modifier}`}>
        <use xlinkHref={sprite + '#' + name}/>
      </svg>
  );
};

export default GetIcon;