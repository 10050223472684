import React, {Component} from 'react';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';

export default class StudentWorkHistoryPage extends Component {
  state = {};

  componentDidMount() {
  }

  render() {
    return (
        <LayoutMain>
          <PageHeadline>
            Карина Иванова
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent>
              {/*some cards*/}
            </LeftPanelStudent>

            <RightPanelStudent
                actions={true}
                notice="32 б."
                header="История трудовой деятельности / опыт работы">

              <Card size="auto"
                    hasHover={false}>
                <CardInnerHeader>
                  1 место работы
                </CardInnerHeader>
                <div className="card-cols card-cols-2">
                  <div className="card-col card-col-1">
                    <div className="card-read-field">
                      <div className="field-label">Город</div>
                      <div className="field-value">Томск</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Место работы</div>
                      <div className="field-value">ООО “Сибэлектромотор”</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Стаж работы</div>
                      <div className="field-value">2013-2018</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Должность</div>
                      <div className="field-value">Инженер</div>
                    </div>
                  </div>
                  <div className="card-col card-col-2">
                    <div className="card-read-field">
                      <div className="field-label">Трудовые обязаности</div>
                      <div className="field-value">
                        <p>Коммуникаций и связи работы в области научно-технической деятельности по проектированию</p>
                        <p>Разрабатывает методические и нормативные документы</p>
                        <p>Проводит технико-экономический анализ</p>
                        <p>Участвует в работах по исследованию</p>
                      </div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Скан подтверждающего документа </div>
                      <div className="field-value">
                        <a className="link-std" href="#">Диплом 2018</a></div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Соцсети</div>
                      <div className="field-value">+7 (999) 999 99 99</div>
                    </div>
                  </div>
                </div>
              </Card>

              <Card size="auto"
                    hasHover={false}>
                <div className="card-inner-header">
                  2 место работы
                </div>
                <div className="card-cols card-cols-2">
                  <div className="card-col card-col-1">
                    <div className="card-read-field">
                      <div className="field-label">Город</div>
                      <div className="field-value">Томск</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Место работы</div>
                      <div className="field-value">ООО “Сибэлектромотор”</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Стаж работы</div>
                      <div className="field-value">2013-2018</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Должность</div>
                      <div className="field-value">Инженер</div>
                    </div>
                  </div>
                  <div className="card-col card-col-2">
                    <div className="card-read-field">
                      <div className="field-label">Трудовые обязаности</div>
                      <div className="field-value">
                        <p>Коммуникаций и связи работы в области научно-технической деятельности по проектированию</p>
                        <p>Разрабатывает методические и нормативные документы</p>
                        <p>Проводит технико-экономический анализ</p>
                        <p>Участвует в работах по исследованию</p>
                      </div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Скан подтверждающего документа </div>
                      <div className="field-value">
                        <a className="link-std" href="#">Диплом 2018</a></div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Соцсети</div>
                      <div className="field-value">+7 (999) 999 99 99</div>
                    </div>
                  </div>
                </div>
              </Card>
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}
