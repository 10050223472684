import React, {Component} from 'react';

import './RightPanelStudent.scss';
import RightPanel from '../RightPanel/RightPanel';

const RightPanelStudent = (props) => {
  return (
      <RightPanel {...props}>
        {props.children}
      </RightPanel>
  );
};

export default RightPanelStudent;