import React, {Component, Fragment} from 'react';
import {NavLink, Link, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';

import './LeftPanelManager.scss';

import {toggleMobileMenuAction, profileLogoutAction} from '../../redux/actions';
import {authService} from '../../services/auth';

import GetIcon from '../GetIcon/GetIcon';
import LeftPanel from '../LeftPanel/LeftPanel';

class LeftPanelManager extends Component {
  state = {
    redirect: false,
    redirectUrl: '/login',
  };


  handleClickLogout = (e) => {
    e.preventDefault();
    authService.logout();
    this.props.profileLogoutAction();

    this.setState({
      redirect: true,
    });
  };

  handleClickMenuToggle = (e) => {
    this.props.toggleMobileMenuAction();
  };

  componentDidMount() {}

  render() {
    const {mobileMenu, profileManager, profileManagerPhotoBase64} = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl}/>;
    }

    return (
        <LeftPanel>
          <div className="card-manager-info card-round-shadow">
            <div className="manager-photo">
              {profileManagerPhotoBase64 ?
                  <img src={profileManagerPhotoBase64} alt="Manager"/> :
                  <GetIcon name="icon-user"/>
              }
            </div>
            <div className="manager-info">
              {profileManager ?
                  <React.Fragment>
                    <div className="manager-name">
                      {`${profileManager.personal.last_name} ${profileManager.personal.first_name} ${profileManager.personal.middle_name.length > 1 ? profileManager.personal.middle_name : ''}` }
                    </div>
                    <div className="manager-education">
                      {profileManager.category.full_name ??
                      profileManager.category.full_name}
                    </div>
                  </React.Fragment>
                  : <Skeleton.Input
                      size="small"
                      style={{marginRight: 20, marginLeft: 20, width: 130}}
                      active/>}
            </div>

          </div>
          <div onClick={this.handleClickMenuToggle}
               className={`card-activities-menu ${mobileMenu.isMenuOpened ?
                   'is-opened' : ''} card-round-shadow mt24 no-min-height`}>
            <ul className="activities-menu-list">
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to="/manager/personal">Личные данные</NavLink></li>
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to="/manager/filters">Умный фильтр</NavLink>
              </li>
              {/*<li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to="/manager/dashboard">Дашборд</NavLink>
              </li>*/}
              <li className="activities-menu-item">
                <NavLink className="item-link"
                         activeClassName="active"
                         to="/manager/rating">Рейтинг</NavLink>
              </li>
            </ul>
            <ul className="activities-menu-list activities-menu-list-mobile">
              <li className="activities-menu-item">
                <a className="item-link" href="/manager">Управление</a>
              </li>
              <li className="activities-menu-item">
                <Link className="item-link"
                      onClick={this.handleClickLogout}
                      to="/logout">Выйти</Link>
              </li>
            </ul>
          </div>
        </LeftPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileMenu: state.mobileMenu,
    profileManager: state.profile.profile,
    profileManagerPhotoBase64: state.profilePhotoBase64.profilePhotoBase64
  };
}

const mapDispatchToProps = {
  toggleMobileMenuAction: toggleMobileMenuAction,
  profileLogoutAction: profileLogoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftPanelManager));
