import React from 'react';

const PageContent = (props) => {
  return (
      <div className="page-content">
        {props.children}
      </div>
  );
};

export default PageContent;