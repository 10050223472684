import React, {Component} from 'react';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';

class StudentWorkPage extends Component {
  render() {
    return (
        <LayoutMain>
          <div className="page-header">
            <h4 className="headline-4">Карина Иванова</h4>
          </div>

          <div className="page-content">
            <LeftPanelStudent/>

            <RightPanelStudent
                notice="1343 б."
                header="Трудовая и профессиональная деятельность">
              <Card size="lg"
                    linkTo="/my/work/work-history"
                    header="История трудовой деятельности / опыт работы">
                <p>2 места работы</p>

                <p>ООО “Сибэлектромотор” 2017 (Инженер-стажер)</p>

                <p>ООО “Томская промышленная компания” 2019 (Инженер)
                </p>
              </Card>

              <Card size="md"
                    notice="87 б."
                    linkTo="/my/work/leadership"
                    header="Участие / руководство в предпринимательских проектах дошедших / не дошедших до стартапа (диплом / сертификат)">
                <p>8 участий</p>
              </Card>

              <Card size="sm"
                    notice="83 б."
                    linkTo="/my/work/internship"
                    header="Прохождение практики">
                <p>2 места</p>
              </Card>

              <Card size="xl"
                    notice="134 б."
                    linkTo="/my/work/startup"
                    header="Участие / победа в Стартап-проектах (диплом / сертификат)">
                <p>15 участий</p>
              </Card>
              <Card size="md"
                    notice="32 б."
                    linkTo="/my/work/employment"
                    header="Работа по найму">
                <p>2 найма</p>
              </Card>
              <Card size="lg"
                    notice="34 б."
                    linkTo="/my/work/entrepreneurial-competitions"
                    header="Участие / победа в предпринимательских конкурсах (по приказу)">
                <p>1 конкурс</p>
              </Card>
              <Card size="auto"
                    notice="55 б."
                    linkTo="/my/work/worldskills-competition"
                    header="Победитель или призер конкурса WorldSkills">
                <p>2 победы</p>
              </Card>
            </RightPanelStudent>
          </div>
        </LayoutMain>
    );
  }
}

export default StudentWorkPage;