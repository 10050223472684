import { Spin } from 'antd';
import { LoadingOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import './Loader.scss';

//const antIcon = <LoadingOutlined style={{ fontSize: 114 }} spin />;
const antIcon = <Loading3QuartersOutlined
    className="loader-spinner-icon" spin/>;


const Loader = (props) => {
  return (
      <Spin wrapperClassName="loader-spinner"
          indicator={antIcon} />
  );
};

export default Loader;