import React, {Component} from 'react';
import {loginAuthSso} from '../../services/apiRequests';
import {authService} from '../../services/auth';

class SsoSuccessPage extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);

    if (authService.isAuthenticated()) {
      this.props.history.push('/');
    }

    if (!window.opener) {
      this.props.history.push('/login');
    }

    this.state = {
      pageContent: 'Авторизация, пожалуйста подождите...',
      postMessage: {type: 'oauthResult', result: true, payload: {}, response: {}},
      query
    };
  }

  componentDidMount() {
    let {query, postMessage} = this.state;

    const urlc = query.get('urlc');

    if (urlc) {
      postMessage.payload.urlc = urlc;

      loginAuthSso({
        'client_id': '43f9c5e3c53bd6666a372882888e5c24',
        'provider': 'tpu_sso_v1',
        'token': urlc,
      }).then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          authService.login(resp.data.access_token);
          authService.detectAndSetUserRole(resp.data);
          postMessage.response = resp.data;

          if (window.opener) {
            window.opener.postMessage(postMessage);
            window.close();
          }
        }
        else {
          this.errorResultOfOauth(resp.data);
        }
      }).catch((err)=>{
        this.errorResultOfOauth(err);
      });
    }
    else {
      this.errorResultOfOauth();
    }
  }

  errorResultOfOauth = (errResp = {})=>{
    let {postMessage} = this.state;

    this.setState({
      pageContent: 'Произошла ошибка авторизации, попробуйте ещё раз',
    });

    if (window.opener) {
      postMessage.result = false;
      postMessage.response = errResp;
      window.opener.postMessage(postMessage);
      window.close();
    }

    this.props.history.push('/login');
  }

  render() {
    return (
        <div style={{maxWidth: '400px', margin: '0 auto'}}>
          <p>{this.state.pageContent}</p>
        </div>
    );
  }
}

export default SsoSuccessPage;