import React, {Component} from 'react';
import {Skeleton} from 'antd';

import {idToName} from '../../modules/constants';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import Card from '../../components/Card/Card';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import PageContent from '../../components/PageContent/PageContent';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';

class StudentStudyPage extends Component {
  state = {
    pageSectionId: 1
  };

  componentDidMount() {}


  render() {
    const {pageSectionId} = this.state;
    const {profile, profilePhotoBase64, ratings, viewType, hideMobileMenu, hideTopMenu} = this.props;

    let partLinkSubsection = '/my';
    if (viewType === 'manager') {
      partLinkSubsection = '/manager/view-profile'
    }
    if (viewType === 'guest') {
      partLinkSubsection = '/student'
    }

    const accomplishments = profile && profile.accomplishments;

    let sectionPoints = 0;
    let cards = [];


    if (accomplishments && accomplishments[pageSectionId]) {
      sectionPoints = accomplishments[pageSectionId].points;

      cards = Object.keys(accomplishments[pageSectionId].subsections).map(
          (key, index) => {
            let subsect = accomplishments[pageSectionId].subsections[key];
            let eventsCount = 0;

            Object.keys(subsect['accomplishments']).map((key, index) => {
              eventsCount = eventsCount + Object.keys(subsect['accomplishments'][key]['events']).length;
            });

            return <Card size={idToName.getCardSizeByIndex(index+1)}
                         linkTo={partLinkSubsection + '/study/' + subsect.id + this.props.location.search}
                         notice={parseFloat(subsect.points.toFixed(0)) + ' б.'}
                         key={subsect.id}
                         header={subsect.name}>
              <p>
                Количество достижений: {eventsCount}
              </p>
            </Card>;
          });
    }

    let education = null;
    if (profile) {
      education = profile.education;
    }

    return (
        <LayoutMain viewType={viewType} hideMobileMenu={hideMobileMenu} hideTopMenu={hideTopMenu}>
          <PageHeadline>
            {profile ?
                `${profile.personal.last_name} ${profile.personal.first_name} ${profile.personal.middle_name.length > 1 ? profile.personal.middle_name : ''}` :
                <Skeleton.Input style={{ minWidth: 300 }} active/>
            }
          </PageHeadline>

          <PageContent>
            <LeftPanelStudent
                profile={profile}
                ratings={ratings}
                profilePhotoBase64={profilePhotoBase64}
                viewType={viewType}/>

            <RightPanelStudent
                notice={parseFloat(sectionPoints.toFixed(2)) + ' б.'}
                header="Учебная деятельность">

              <Card size={idToName.getCardSizeByIndex(0)}
                    linkTo={partLinkSubsection + '/study/education' + this.props.location.search}
                    header="Образование">

                {education ?
                    <p>
                      {education.department.full_name},
                      <br/>
                      {education.specialty.full_name} <br/>
                      <strong>{education.admission_year} ({education.education_level.full_name})</strong>
                    </p> : null}
              </Card>
              {cards}
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}

export default StudentStudyPage;