import React, {Component} from 'react';

import LayoutMain from '../../components/LayoutMain/LayoutMain';
import PageHeadline from '../../components/PageHeadline/PageHeadline';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import CardCols, {CardCol} from '../../components/Card/CardCols';
import CardInnerHeader from '../../components/Card/CardInnerHeader';
import CardInnerText from '../../components/Card/CardInnerText';
import LeftPanelStudent
  from '../../components/LeftPanelStudent/LeftPanelStudent';
import RightPanelStudent
  from '../../components/RightPanelStudent/RightPanelStudent';

export default class StudentSocialSelfGovernmentPage extends Component {
  state = {};

  render() {
    return (
        <LayoutMain>
          <PageHeadline>Карина Иванова</PageHeadline>

          <PageContent>
            <LeftPanelStudent>
              {/*some cards*/}
            </LeftPanelStudent>

            <RightPanelStudent
                actions={true}
                notice="32 б."
                header="Членство в органах студенческого самоуправления">

              <Card size="auto"
                    header="1 студенческое объединение"
                    hasHover={false}>
                <CardCols>
                  <CardCol>
                    <div className="card-read-field">
                      <div className="field-label">ВУЗ</div>
                      <div className="field-value">Томский политехнический университет</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Название студенческого объединения</div>
                      <div className="field-value">Студенческий утренник</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Должность</div>
                      <div className="field-value">Куратор</div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Период членства</div>
                      <div className="field-value">2013-2018</div>
                    </div>
                  </CardCol>
                  <CardCol>
                    <div className="card-read-field">
                      <div className="field-label">Трудовые обязаности</div>
                      <div className="field-value">
                        <p>Коммуникаций и связи работы в области научно-технической деятельности по проектированию</p>
                        <p>Разрабатывает методические и нормативные документы</p>
                        <p>Проводит технико-экономический анализ</p>
                        <p>Участвует в работах по исследованию</p>
                      </div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Скан подтверждающего документа </div>
                      <div className="field-value">
                        <a className="link-std" href="#">Диплом 2018</a></div>
                    </div>
                    <div className="card-read-field">
                      <div className="field-label">Соцсети</div>
                      <div className="field-value">+7 (999) 999 99 99</div>
                    </div>
                  </CardCol>
                </CardCols>
              </Card>
            </RightPanelStudent>
          </PageContent>
        </LayoutMain>
    );
  }
}
