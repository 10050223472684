import React, {Component} from 'react';
import NotFound404 from '../../components/NotFound404/NotFound404';

export default class NotFound404Page extends Component {
  render() {
    return (
        <div>
          <NotFound404/>
        </div>
    );
  }
}
